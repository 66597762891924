import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import SubHeaderAction from '@ifca-root/react-component/src/components/Header/SubHeaderAction';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useChangePasswordMutation } from 'generated/graphql';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';
import { IAction } from 'helpers/model';
import React, { Reducer, useReducer, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

interface ChangePasswordForm {
	currentPassword: string;
	newPassword: string;
	confirmPassword: string;
}
interface ChangePasswordProps {
	showOldPassword: boolean;
	showNewPassword: boolean;
	showConfirmPassword: boolean;
}
export const ChangePassword = props => {
	let history = useHistory();
	const [openAlert, setOpenAlert] = useState(false);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const handleCloseSnackBar = () => {
		setOpen(false);
		history.push('/profile');
	};
	const MESSAGE = 'Password Changed Successfully';

	const changePasswordSchema = yup.object({
		newPassword: yup
			.string()
			.required('New password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/(?=.*[A-Z])/,
				'Password must contain at least one uppercase character',
			)
			.matches(
				/(?=.*[a-z])/,
				'Password must contain at least one lowercase character',
			)
			.matches(/(?=.*\d)/, 'Password must contain at least one number')
			.matches(
				/(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
				'Password must contain at least one symbol',
			),
		confirmPassword: yup
			.string()
			.required()
			.test('passwords-match', 'Passwords must match', function(x) {
				return this.parent.newPassword === x;
			}),
	});

	const [
		changePasswordMutation,
		{ data, loading: mutationLoading, error: mutationError },
	] = useChangePasswordMutation({
		onError: error => {
			error.graphQLErrors.map(({ message }) => {
				console.log('ERROR', message);
				setMessage(message);
				setOpenAlert(true);
			});
		},
		onCompleted: data => {
			reset();
			setOpen(true);

			// history.push('/')
		},
		variables: {
			newPassword: '',
			currentPassword: '',
		},
	});

	const { handleSubmit, register, errors, control, reset, getValues } = useForm<
		ChangePasswordForm
	>({
		defaultValues: {
			currentPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		mode: 'onChange',
		resolver: yupResolver(changePasswordSchema),
	});
	console.log(errors, 'ERRORS');

	const onSubmit = (data: any) => {
		changePasswordMutation({
			variables: {
				currentPassword: encryptMessage(data?.currentPassword),
				newPassword: encryptMessage(data?.newPassword),
			},
		});
		// .then(x=>{
		//   history.push("/Login")
		// })
	};
	const reducer: Reducer<ChangePasswordProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const initialState: ChangePasswordProps = {
		showOldPassword: false,
		showNewPassword: false,
		showConfirmPassword: false,
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<>
			<MainHeader
				mainBtn="close"
				onClick={() => history.goBack()}
				smTitle={user?.companyName}
				title="Change Password"
				routeSegments={[
					{ name: 'Main' },
					{ name: 'Profile' },
					{ name: 'Change Password', current: true },
				]}
			/>
			<SubHeaderAction title="Change Password" />
			<CommonDialog
				fullWidth={true}
				open={openAlert}
				onClose={handleCloseAlert}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Chnage Password</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => <div>{message}</div>,
					footer: {
						actions: [
							{
								displayText: 'Ok',
								props: {
									onClick: () => handleCloseAlert(),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
			<SnackBarMsg
				open={open}
				message={MESSAGE}
				onClose={handleCloseSnackBar}
			/>
			<ContentWrapper>
				<CardContents devFullWidth={true}>
					<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
						<Controller
							as={TextField}
							fullWidth
							helperText={errors?.currentPassword?.message}
							error={errors?.currentPassword ? true : false}
							autoComplete="off"
							name="currentPassword"
							label="Current Password*"
							control={control}
							type={state.showOldPassword ? 'text' : 'password'}
							value={getValues('currentPassword')}
							//   onChange={handleChange('password')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="Password"
											onClick={() =>
												dispatch({
													type: 'showOldPassword',
													payload: !state.showOldPassword,
												})
											}
										>
											{state.showOldPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<Controller
							as={TextField}
							name="newPassword"
							label="New Password *"
							autoComplete="off"
							fullWidth
							control={control}
							helperText={errors?.newPassword?.message}
							error={errors?.newPassword ? true : false}
							ref={register}
							type={state.showNewPassword ? 'text' : 'password'}
							value={getValues('newPassword')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="Password"
											onClick={() =>
												dispatch({
													type: 'showNewPassword',
													payload: !state.showNewPassword,
												})
											}
										>
											{state.showNewPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<Controller
							as={TextField}
							fullWidth
							helperText={errors?.confirmPassword?.message}
							error={errors?.confirmPassword ? true : false}
							autoComplete="off"
							name="confirmPassword"
							label="Confirm New Password*"
							control={control}
							ref={register}
							type={state.showConfirmPassword ? 'text' : 'password'}
							value={getValues('confirmPassword')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="Password"
											onClick={() =>
												dispatch({
													type: 'showConfirmPassword',
													payload: !state.showConfirmPassword,
												})
											}
										>
											{state.showConfirmPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</form>
				</CardContents>
			</ContentWrapper>
		</>
	);
};
