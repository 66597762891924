import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import { AccordionDetails } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import { KeyboardArrowDown } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import ShareDialog from 'components/Dialog/ShareDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import * as _ from 'underscore';
import { addDays } from 'date-fns/esm';
import {
	GetCbExportDataDocument,
	useGetCbExportDataLazyQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
	usePostCbExportMutation,
	useMenuAccountXListQuery
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { IAction } from 'helpers/model';
import { amtStr } from 'helpers/numFormatter';
import * as htmlToImage from 'html-to-image';
import React, { Reducer, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';

interface Props {
  StartDate: Date
  EndDate: Date
	CBDate: Date
	Dialog: boolean
	CBExportData?: any
	CBDetailsData?: any
}

export const CashBookTransactionForm = mode => {
	useEffect(() => {
		console.log('CashBookForm');
	}, []);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatches } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const history = useHistory();

	const [
		getCBExport,
		{ loading: cbExportLoading, data: { getCBExportData } = { getCBExportData: [] } },
	] = useGetCbExportDataLazyQuery({
		fetchPolicy: 'no-cache',
	});
	const [ErrDialog, setErrDialog] = useState(false)

	const [message, setMessage] = useState('')

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};


	// const {
	// 	loading: MenuAccountXListLoading,
	// 	data: { MenuAccountXList } = {
	// 		MenuAccountXList: {
	// 		ID: '',
	// 		outletID: '',
	// 		vendorName: '',
	// 		isActive: true,
	// 	  }
	// 	},
	//   } = useMenuAccountXListQuery({
	// 	variables: {
	// 	  outletID: JSON.parse(localStorage.getItem('outlet'))?.ID,
	// 	},
	// 	fetchPolicy: 'no-cache',
	//   })

	const {
		loading: MenuAccountXListLoading,
		data: { MenuAccountXList } = { MenuAccountXList: [] },
	  } = useMenuAccountXListQuery({
		variables: {
		  outletID: outletID,
		},
		fetchPolicy: 'no-cache',
	  })
	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [cbPost, { loading: cbPostLoading }] = usePostCbExportMutation({
		onError: error => {
			if (error.graphQLErrors[0].message) {
			  setErrDialog(true);
			  setMessage(`${error.graphQLErrors[0].message}`)
			  snackFunc('Posted Fail',false);
			} 
		  },
		  onCompleted: y =>{
			if(y.postCBExport === true){
			  csvRef.current.link.click();
			  snackFunc('Posted Succesfully', true);
			  getCBExport({
				variables: {
				  startDate: state.StartDate,
				  endDate: state.EndDate,
				  outletID: outletID,
				  accountID: user.accountID,
				}
			  })
			  dispatch({
				type: 'reset',
				payload: {},
			  })
			}
		  }
		});

	const [openExitDialog, setOpenExitDialog] = useState(false);
	const [cbExportDate, setCBExportDate] = useState(new Date());

	///////////////// DATE SEARCH HEADER ///////////////
	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  var dateFormat = require('dateformat');
	const initialState: Props = {
    StartDate: addDays(date, -1), //dateFormat(firstDay, 'mm/dd/yyyy'),
    EndDate: addDays(date, -1), //dateFormat(lastDay, 'mm/dd/yyyy'),
		CBDate: addDays(date, -1), //dateFormat(lastDay, 'mm/dd/yyyy'),
		Dialog: false,
		CBExportData: [],
		CBDetailsData: [],
	}
	const PreviousDate = addDays(new Date(), -1)

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState
			default:
				return { ...state, [action.type]: action.payload }
		}
	}
	const [state, dispatch] = useReducer(reducer, initialState)
	const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);

	const [cbExportList, setCBExportList] = useState(null);
	const [openExportDialog, setOpenExportDialog] = useState(false);

	useEffect(() => {
		if (getCBExportData?.length > 0)
			dispatch({
				type: 'CBDetailsData',
				payload: getCBExportData,
			})
	}, [getCBExportData]);
	console.log('state', state)
	const CBDetailsGroupBy = _.groupBy(state.CBDetailsData, x => x.cbType);
	// console.log(CBDetailsGroupBy, 'heregroup');

	const CBDetailsList = array => {
		let keys = Object.keys(array)
		let values = Object.values(array)
		let map = new Map()
		for (var i = 0; i < keys.length; i++) {
			map.set(keys[i], values[i])
		}
		return [...map].map(([CBType, Data]) => ({ CBType, Data }))
	}
	// console.log(CBDetailsList(CBDetailsGroupBy));

	const debitAmount = getCBExportData?.reduce((prev, curr) => {
		return prev + Number(curr.amount);
	}, 0);
	// console.log('debitAmount', debitAmount)
	//Export
	//Export
	//Export

	let csvRef = useRef(null);

	const downloadCBExport = async () => {
		dispatch({
			type: 'Dialog',
			payload: false,
		})
		dispatch({
			type: 'CBExportData',
			payload: MenuAccountXList?.vendorName === 'AccountX' 
			?   getCBExportData.map(x => ({
				DocumentDate: `${dateFormat(x.trxDate, 'dd/mm/yyyy')}`,
				TransactionDate: `${dateFormat(state.CBDate, 'dd/mm/yyyy')}`,
				BankCode: x.bankCode,
				BankAccountNo: x.bankAccountNo,       		
				LedgerType: 'MX',
				DocumentNo: x.billNo,
				RefNo: x?.referenceNo  === '' ? '-' : x?.referenceNo,
				PayeeReceiverName: '',
				Description: x.description,
				DocumentAmt: Number(x.amount).toFixed(2),
			}))
			:   getCBExportData?.map(x => ({
				DocDate: `${dateFormat(x.trxDate, 'dd/mm/yyyy')}`,
				CBDate: `${dateFormat(state.CBDate, 'dd/mm/yyyy')}`,
        		Submenu: 'MX',
				CBType: x.cbType,
				BankCode: x.bankCode,
				AccountCode: x.accountCode,
				Description: x.description,
				ReferenceNo: x.referenceNo,
				Amount: Number(x.amount).toFixed(2),
				RoomNo: '',
				GuestName: '',
				DocNo: x.billNo,
				BookingNo: '',
				Module: 'MX',
			})),
		})
		cbPost({
			variables: {
				cbDate: state.CBDate,
				startDate: state.StartDate,
				endDate: state.EndDate,
				outletID: outletID,
				accountID: user.accountID,
				isTransfer: MenuAccountXList?.vendorName === 'AccountX' ? true: false
			},
			refetchQueries: [
				{
					query: GetCbExportDataDocument,
					variables: {
						startDate: state.StartDate,
						endDate: state.EndDate,
						outletID: outletID,
						accountID: user.accountID,
					},
				},
			],
		})	
	};

	const ErrorDialog = (
		<StandardDialog
	  fullWidth={true}
	  open={ErrDialog}
	  onClose={() => {
		setErrDialog(true);
	  }}
	  sections={{
		header: {
		  title: 'CB Transfer Error',
		},
		body: () => <div>{`${message}`}</div>, 
		footer: {
		  actions: [
			{
			  displayText: 'OK',
			  props: {
				onClick: () => {
				  setErrDialog(false);
				},
				variant: 'contained',
				color: 'primary',
			
			  },
			},
		  ],
		},
	  }}
	/>
	  )
console.log(getCBExportData,'getCBExportData')
	console.log(MenuAccountXList?.vendorName !== 'AccountX', 'accountx')

	useEffect(() => {
		const hasNullAccountCode = getCBExportData.find(x => x?.accountCode === null || x?.accountCode === '' || x?.accountCode === undefined);
		const hasNullBankCode = getCBExportData.find(x => x?.bankCode === null || x?.bankCode === '' || x?.bankCode === undefined);
		const hasNullBankAccountNo = getCBExportData.find(x => x?.bankAccountNo === null || x?.bankAccountNo === '' || x?.bankAccountNo === undefined);
	
		if (hasNullAccountCode ) {
		  snackFunc('Account Code not setup in GL Mapping', false);
		} else if (hasNullBankCode) {
		  snackFunc('Bank Code not setup in GL Mapping', false);
		} else if (hasNullBankAccountNo) {
		  snackFunc('Bank Account No not setup in GL Mapping', false);
		}
	  }, [getCBExportData]);

	const ExportDialog = () => {
		return (
			<CommonDialog
				fullWidth={true}
				open={state?.Dialog}
				onClose={() => dispatch({ type: 'CloseDialog', payload: {} })}
				sections={{
					header: {
						dynamic: (
							<div className="session">
								<div className="title flex-space color-primary-orange">
								{MenuAccountXList?.vendorName === 'AccountX' ? 'CB Transfer' : 'CB Export' }
								</div>
							</div>
						),
					},
					body: () => (
						<KeyboardDatePicker
							autoOk
							onChange={(date: Date | null) => {
								dispatch({ type: 'CBDate', payload: date, })
							}}
							label="CB Date"
							inputVariant="outlined"
							format=" dd MMM yyyy"
							value={state.CBDate}
							// maxDate={PreviousDate}
							className="custom-date-reservation"
							fullWidth
							style={{
								zIndex: 40,
								width: '100%',
								boxShadow: 'revert',
								paddingTop: '0px !important',
							}}
							KeyboardButtonProps={{
								style: {},
								'aria-label': 'change date',
							}}
							InputProps={{
								style: {
									fontSize: '10px',
									backgroundColor: 'white',
									color: 'black',
								},
								readOnly: true,
								disableUnderline: true,
								fullWidth: true,
							}}
						/>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => dispatch({ type: 'Dialog', payload: false }),
									variant: 'contained',
									color: 'primary',
									// startIcon: <ShareIcon />,
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										downloadCBExport();
									},

									variant: 'contained',
									color: 'primary',
									// startIcon: <ShareIcon />,
								},
							},
						],
					},
				}}
			/>
		);
	};

	const CBListing = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '100%',
						  }
						: {
								marginTop: '0px',
								zIndex: 40,
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl' : 'hiddenCB'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<ContentWrapper
						float
						footer
						onlyContentScrollable
						style={{
							marginTop: isDesktop ? '152px' : '86px',
						}}
					>
						<>
							{CBDetailsList(CBDetailsGroupBy)?.map((cb, index) => (
								<Accordion
									className="accordion-card"
									defaultExpanded={hidden ? true : false}
									key={index}
								>
									<AccordionSummary
										expandIcon={<KeyboardArrowDown />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Grid container justify="flex-start">
											<Grid
												item
												xs={6}
												className="xsTitle"
												style={{
													whiteSpace: 'normal',
													padding: '0px 6px',
												}}
											>
												{cb?.CBType}									
											</Grid>

											<Grid
												item
												xs={3}
												className="desc"
												style={{ display: 'flex', justifyContent: 'center' }}
											>
											</Grid>
											<Grid
												item
												xs={3}
												className="desc"
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												{amtStr(
													cb.Data?.reduce?.((acc, curr) => {
														return acc + Number(curr.amount)
													}, 0)
												) || 0}	
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										{cb?.Data?.sort(
													(a, b) =>
														Number(new Date(a.trxDate)) - Number(new Date(b.trxDate))
												)?.map((trx, trxIndex) => (
											<>
												<Grid
													key={trxIndex}
													container
													justify="flex-start"
													className="desc"
													style={{ width: '90%', padding: '4px' }}
												>
													<Grid item xs={3} className="desc">
														{formatDate(trx?.trxDate)}
													</Grid>

													<Grid item xs={5} className="desc">
														{trx?.bankCode}
													</Grid>
													<Grid
														item
														xs={2}
														className="desc "
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														{/* {y?.debit?.toFixed(2)} */}
													</Grid>
													<Grid
														item
														xs={2}
														className="desc "
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														{amtStr(trx?.amount)}
													</Grid>
													<Grid
														item
														xs={3}
														style={{ paddingTop: '6px' }}
													></Grid>
													<Grid
														item
														xs={9}
														className="desc "
														style={{
															paddingTop: '6px',
														}}
													>
														{trx?.cbType}
													</Grid>
												</Grid>
												<Box
													width="10%"
													display="flex"
													justifyContent="center"
													margin="auto"
												/>
												<Divider variant="fullWidth" className="flex" />
											</>
										))}
									</AccordionDetails>
								</Accordion>
							))}
						</>
					</ContentWrapper>
				</div>
			</div>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{(cbExportLoading ||cbPostLoading || MenuAccountXListLoading) && <Loading />}

			<MainHeader
				onClick={() => {
					setOpenExitDialog(true);
				}}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'CB Transaction', current: true },
				]}
				// rightRouteSegments={[{ name: mode === 'edit' ? 'Edit' : 'Add' }]}
				rightRouteSegments={[{ name: 'New' }]}
			/>
			<div
				className={`fix-subheader`}
				style={{
					marginTop: '90px',
					//marginBottom: isDesktop && globalState.drawerOpen ? 115 : 0,
				}}
			>
				<div
					className={`dynamic-subheader multiple`}
					style={{
						paddingBottom: '10px',
						height: '88px',
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 285px)'
								: isDesktop && !globalState.drawerOpen
								? 'calc(100% - 47px) '
								: '100%',
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						spacing={2}
					>
						<Grid item xs={6} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								autoOk
								value={state?.StartDate}
								// maxDate={PreviousDate}
								onChange={(date: Date | null) => {
									dispatch({ type: 'StartDate', payload: date, })
								}}
								className="custom-date-reservation"
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
									boxShadow: 'revert',
								}}
								InputProps={{
									style: {
										fontSize: '10px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
							/>
						</Grid>
						<Grid item xs={6} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								value={state?.EndDate}
								// maxDate={PreviousDate}
								autoOk
								onChange={(date: Date | null) => {
									dispatch({ type: 'EndDate', payload: date })
									dispatch({ type: 'CBDate', payload: date })
									dispatch({ type: 'CBDetailsData', payload: [] })
									getCBExport({
										variables: {
											startDate: state.StartDate,
											endDate: date,
											outletID: outletID,
											accountID: user.accountID,
										}
									})									
								}}
								className="custom-date-reservation"
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								InputProps={{
									style: {
										fontSize: '12px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						spacing={2}
					>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							style={{
								paddingRight: '12px',
								paddingLeft: '12px',
								paddingTop: '23px',
							}}
						>
							<span className="xsTitle" style={{ paddingTop: '5px' }}>
								<span style={{ color: 'black' }}> Σ {amtStr(debitAmount)}</span>
							</span>
						</Grid>
					</Grid>
				</div>
			</div>

			{CBListing()}
			{CBListing(true)}
			<div className="cover-layer" />

			{/* Listing Content */}
			{/* Listing Content */}
			{/* Listing Content */}

			{ErrorDialog}
			<ExportDialog />


			<CSVLink
				filename={`CB_Export_${dateFormat(state.CBDate, 'dd_mm_yyyy')}.csv`}
				data={state.CBExportData}
				ref={csvRef}
			/>
			{ShareDialog({
				shareTitle: `CB Export - (${formatDate(
					selectedStartDate.toISOString(),
				)} - ${formatDate(selectedEndDate.toISOString())})`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}
			<DynamicFooter
				options={[
					 MenuAccountXList?.vendorName === 'AccountX'
					 ?
					// {
					// 	name: 'Share',
					// 	onClick: () => {
					// 		let temp = document.getElementById('hiddenCB');
					// 		htmlToImage
					// 			.toBlob(temp, { style: { background: '#fff' } })
					// 			.then(value => {
					// 				handleShare(value);
					// 			})
					// 			.catch(error => console.error(error, 'Something went wrong'));
					// 	},
					// 	color:
					// 		getCBExportData?.length === 0
					// 			? 'inherit'
					// 			: 'primary',
					// 	disabled:
					// 		getCBExportData?.length === 0,
					// 	startIcon: <ShareIcon />,
					// },
					{
						name: 'CB Transfer',
						onClick: () => {
							dispatch({ type: 'Dialog', payload: true })
						},
					    color: getCBExportData?.length === 0 ||  getCBExportData.find((x) => x?.accountCode === null || x?.accountCode === '' || x?.accountCode === undefined) || getCBExportData.find((x) => x?.bankCode === null || x?.bankCode === '' || x?.bankCode === undefined) || getCBExportData.find((x) => x?.bankAccountNo === null || x?.bankAccountNo === '' || x?.bankAccountNo === undefined)? 'inherit' : 'primary',
						disabled:getCBExportData?.length === 0 ||  getCBExportData.find((x) => x?.accountCode === null || x?.accountCode === '' || x?.accountCode === undefined) || getCBExportData.find((x) => x?.bankCode === null || x?.bankCode === '' || x?.bankCode === undefined ) || getCBExportData.find((x) => x?.bankAccountNo === null || x?.bankAccountNo === '' ||  x?.bankAccountNo === undefined),
						startIcon: <ShareIcon />,				
					}
					
					: 
					{
						name: 'Dowload',
						onClick: () => {
							dispatch({ type: 'Dialog', payload: true })
						},
						color: getCBExportData?.length === 0 ||  getCBExportData.find((x) => x?.accountCode === null || x?.accountCode === '' || x?.accountCode === undefined) || getCBExportData.find((x) => x?.bankCode === null || x?.bankCode === '' || x?.bankCode === undefined) || getCBExportData.find((x) => x?.bankAccountNo === null || x?.bankAccountNo === '' || x?.bankAccountNo === undefined )? 'inherit' : 'primary',
						disabled: getCBExportData?.length === 0 ||  getCBExportData.find((x) => x?.accountCode === null || x?.accountCode === '' || x?.accountCode === undefined) || getCBExportData.find((x) => x?.bankCode === null || x?.bankCode === ''|| x?.bankCode === undefined) || getCBExportData.find((x) => x?.bankAccountNo === null || x?.bankAccountNo === '' || x?.bankAccountNo === undefined),
						startIcon: <ShareIcon />,
					}
				]}
			/>
			{snackBarMessage && (
				<SnackBarMsg open={openSnackBar} message={snackBarMessage} />
			)}

			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/account-journals/cb-transaction`}
			/>
		</>
	);
};
