import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import React from 'react';
import { EInvoiceComponent } from '../Home/EInvoiceComponent';

export const EInvoiceDialog = props => {
	const {
		open,
		setOpen,
		register,
		setBillToA,
		setEmailA,
		setNricNoA,
		setTinA,
		setRegistrationNoA,
		setTaxRegistrationNoA,
		control,
		getCountry,
		getStatebyCountry,
		getCitybyState,
		getPostcodebyCity,
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		autoCompleteRef,
		addressState,
		country,
		setCountry,
		mode,
		isSubmitted,
		setState,
		setCity,
		newState,
		newPostcode,
		setPostcode,
		city,
		order,
	} = props;

	return (
		<>
			<CommonDialogV2
				fullWidth
				open={open}
				onClose={() => setOpen(false)}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title highlight-text flex-space"
													style={{ color: '#ff9800' }}
												>
													Customer Info
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							<EInvoiceComponent
								register={register}
								setBillToA={setBillToA}
								setEmailA={setEmailA}
								setNricNoA={setNricNoA}
								setTinA={setTinA}
								setRegistrationNoA={setRegistrationNoA}
								setTaxRegistrationNoA={setTaxRegistrationNoA}
								control={control}
								getCountry={getCountry}
								getStatebyCountry={getStatebyCountry}
								getCitybyState={getCitybyState}
								getPostcodebyCity={getPostcodebyCity}
								handleCountryOnChange={handleCountryOnChange}
								handleStateOnChange={handleStateOnChange}
								handleCityOnChange={handleCityOnChange}
								handlePostCodeOnChange={handlePostCodeOnChange}
								handleGoogleOnChange={handleGoogleOnChange}
								autoCompleteRef={autoCompleteRef}
								addressState={addressState}
								country={country}
								setCountry={setCountry}
								mode={mode}
								isSubmitted={isSubmitted}
								setState={setState}
								setCity={setCity}
								newState={newState}
								newPostccode={newPostcode}
								setPostcode={setPostcode}
								city={city}
								order={order}
								page={'bill'}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpen(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Next',
								props: {
									onClick: () => {},
									variant: 'contained',
									color: 'primary',
									disabled: false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
