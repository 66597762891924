import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import {
	Divider,
	Grid,
	RadioGroup,
	Radio,
	FormControlLabel,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { BillFooterReceipt } from 'components/Footer/BillFooterReceipt';
import {
	CommonStatus,
	CreditCardType,
	OrderItemDiscountStatus,
	PatronClass,
	PaymentClass,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import { convertDate24Hrs } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { OutletIcon } from '../../../../../../assets';
import '../billSettlement.scss';
import * as bxlCommon from '../BixolonJS/bxlcommon.js';
import * as bxlPOS from '../BixolonJS/bxlpos.js';
import Phone from 'assets/icons/phone.svg';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import { BillReceiptFooter } from './BillReceiptFooter';
import { OrderReceipt } from '../../AddEditOrder/OrderReceipt/OrderItemReceipt';
import { NewOrderReceipt } from '../../NewOrder/PrintedQR';
import { BillReceiptPrintEInvoice } from './BillReceiptEInvoice';

interface billReceiptProps {
	outlet;
	outletLogo;
	signImage?;
	data;
	orderItems;
	footerToken;
	negOrderItem;
	discount;
	hidden?: Boolean;
	mode?: string;
	hotelGuest?: any;
	hotelDebtor?: any;
	officer?: any;
	staff?: any;
	latestPayment?: any;
	chargeInfo1?: any;
	chargeInfo2?: any;
	currencyCode?: string;
	totalOptItmPerOrderItem?: any;
	outletID?: string;
	patronName?: any;
}

export const BillReceiptDisplay = (props: billReceiptProps) => {
	const {
		outlet,
		outletLogo,
		signImage,
		data,
		orderItems,
		footerToken,
		negOrderItem,
		discount,
		hidden,
		mode,
		hotelGuest,
		hotelDebtor,
		officer,
		staff,
		latestPayment,
		chargeInfo1,
		chargeInfo2,
		currencyCode = '',
		totalOptItmPerOrderItem,
		outletID,
		patronName,
	} = props;

	const creditClass = creditClass => {
		let credClass;

		if (creditClass === CreditCardType.AmericanExpressCard) {
			credClass = 'American Express Card';
		} else if (creditClass === CreditCardType.DinersCard) {
			credClass = 'Diners Card';
		} else if (creditClass === CreditCardType.JcbCard) {
			credClass = 'JCB Card';
		} else if (creditClass === CreditCardType.Mastercard) {
			credClass = 'Master Card';
		} else if (creditClass === CreditCardType.UnionpayCard) {
			credClass = 'UNION PAY';
		} else if (creditClass === CreditCardType.VisaCard) {
			credClass = 'Visa Card';
		} else if (creditClass === CreditCardType.DebitCard) {
			credClass = 'Debit Card';
		} else if (creditClass === CreditCardType.Ewallet) {
			credClass = 'E-Wallet';
		}
		return credClass;
	};

	const patronClass =
		data[0]?.patron?.patronClass ?? data[0]?.order?.patron?.patronClass;

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '350px',
							fontFamily: ['Poppins', 'sans-serif'].join(',') + ' !important',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			id={hidden ? 'share-receipt' : null}
		>
			<CardContents>
				<Grid container>
					<Grid item xs={12} style={{ textAlign: 'center' }}>
						{outlet?.logo?.fileURL ? (
							<img
								className="icon-svg"
								style={{
									width: '100px',
									// marginTop: '20px',
									marginBottom: '10px',
									height: '100px',
								}}
								src={outlet?.logo?.fileURL}
								// crossOrigin="anonymous"
							/>
						) : (
							<img
								className="icon-svg"
								style={{
									height: '90px',
									width: '150px',
									// marginTop: '20px',
									marginBottom: '5px',
								}}
								src={OutletIcon}
							/>
						)}
					</Grid>
				</Grid>
				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						marginBottom: '-5px',
						marginTop: '-5px',
						fontSize: '14px',
						fontWeight: 500,
					}}
				>
					<div style={{ color: '#212121' }}>
						{outlet?.address?.address +
							', ' +
							outlet?.address?.postCode +
							', ' +
							outlet?.address?.city +
							', ' +
							outlet?.address?.state +
							'.'}
					</div>
				</div>

				<div
					className="xsTitle content-wrap full"
					style={{
						textAlign: 'center',
						fontSize: '14px',
						marginBottom: '0px',
						marginTop: '5px',
					}}
				>
					<img
						src={Phone}
						style={{
							width: '10px',
							height: '10px',
							paddingRight: '3px',
						}}
					/>
					<span>{outlet?.contactNo}</span>
				</div>

				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						marginBottom: '1px',
						// marginTop: '-5px',
						fontSize: '14px',
						color: '#212121',
						fontWeight: 500,
					}}
				>
					<div>{`(SST Reg No: ${outlet?.taxRegistrationNo})`}</div>
				</div>

				<div
					className="xsTitle content-wrap full"
					style={{
						textAlign: 'center',
						fontSize: '16px',
						marginBottom: '5px',
						marginTop: '5px',
					}}
				>
					<span>{outlet?.name}</span>
				</div>

				{mode != 'bill-settlement' ? (
					<div
						className="content-wrap full"
						style={{ marginTop: '5px', marginBottom: '3px' }}
					>
						<div className={'xsTitle'} style={{ fontSize: '16px' }}>
							{`Bill No`}{' '}
							<span style={{ verticalAlign: 'middle', paddingLeft: '1px' }}>
								:
							</span>{' '}
							&nbsp;
							{data[0]?.billNo}
							<span className="mdDesc right-info" style={{ fontSize: '16px' }}>
								{`${convertDate24Hrs(data[0]?.createdTs)}`}
							</span>
						</div>
					</div>
				) : (
					<div
						className="content-wrap full"
						style={{ marginTop: '5px', marginBottom: '3px' }}
					>
						<div className={'xsTitle'}>
							<span style={{ fontSize: '16px', fontWeight: 500 }}>
								{`${convertDate24Hrs(data[0]?.createdTs)}`}
							</span>
						</div>
					</div>
				)}

				{/* <div className="dialog-dynamic-content"> */}
				{mode == 'void-bill' ? (
					<div className="session">
						<div className="flex session">
							<div
								className="xsTitle flex-space desc color-red"
								style={{ color: '#212121' }}
							>
								Voided Bill
							</div>
						</div>
					</div>
				) : (
					<>
						<div className="content-wrap full" style={{ marginBottom: '3px' }}>
							<div className={'mdDesc'} style={{ fontSize: '16px' }}>
								{patronClass}
								{patronClass !== 'Member' ? (
									<span
										style={
											patronClass === 'Officer'
												? { verticalAlign: 'right', paddingLeft: '44px' }
												: patronClass === 'Staff'
												? { verticalAlign: 'right', paddingLeft: '60px' }
												: patronClass === 'Visitor'
												? { verticalAlign: 'right', paddingLeft: '47px' }
												: { verticalAlign: 'right', paddingLeft: '4px' }
										}
									>
										:
									</span>
								) : null}{' '}
								&nbsp;
								{patronClass !== 'Member'
									? mode == 'bill-settlement'
										? data[0]?.patronName !== null
											? `${data[0]?.patronName}`
											: `-`
										: data[0]?.order?.patronName !== null
										? `${data[0]?.order?.patronName}`
										: `-`
									: null}
							</div>
						</div>

						<div className="content-wrap full" style={{ marginBottom: '3px' }}>
							<div className={'mdDesc'} style={{ fontSize: '16px' }}>
								{`Table`}{' '}
								<span style={{ verticalAlign: 'right', paddingLeft: '48px' }}>
									:
								</span>{' '}
								&nbsp;
								{mode == 'bill-settlement'
									? `${data[0]?.table?.prefix}${data[0]?.table?.tableNumber}`
									: `${data[0]?.order?.table?.prefix}${data[0]?.order?.table?.tableNumber}`}
							</div>
						</div>

						{mode == 'void-bill' ? null : (
							<div>
								{data[0]?.order?.serviceMode === 'DINE_IN' ? (
									<>
										<div
											className="content-wrap full"
											style={{ marginBottom: '3px' }}
										>
											<div className={'mdDesc'} style={{ fontSize: '16px' }}>
												{`Cover`}{' '}
												<span
													style={{
														verticalAlign: 'middle',
														paddingLeft: '45px',
													}}
												>
													:
												</span>{' '}
												&nbsp;
												{data[0]?.order?.pax}
											</div>
										</div>
									</>
								) : null}
							</div>
						)}

						<div className="content-wrap full" style={{ marginBottom: '3px' }}>
							<div className={'mdDesc'} style={{ fontSize: '16px' }}>
								{`Cashier`}{' '}
								<span
									style={{
										verticalAlign: 'middle',
										paddingLeft: '31px',
									}}
								>
									:
								</span>{' '}
								&nbsp;
								{
									getUsersByAccountAndSoftware?.filter(x =>
										mode == 'bill-settlement'
											? x?.ID === data[0]?.modBy || x?.ID === data[0]?.createdBy
											: x?.ID === data[0]?.modBy ||
											  x?.ID === data[0]?.createdBy,
									)[0]?.name
								}
							</div>
						</div>
					</>
				)}
				{/* </div> */}

				{orderItems?.map((orderItem, index) => {
					let totalOITax = 0;
					orderItem?.orderItemTax?.map(x => {
						totalOITax += x?.taxAmount;
					});

					const oid = orderItem?.orderItemDiscount?.filter(
						el => el?.status === OrderItemDiscountStatus.Active,
					)[0]?.baseAmount;

					const odi = orderItem?.orderDiscountItem?.baseAmount;

					const negOI =
						(negOrderItem?.[orderItem?.ID]?.amount ?? 0) +
						(negOrderItem?.[orderItem?.ID]?.taxAmount ?? 0) +
						(negOrderItem?.[orderItem?.ID]?.serviceCharge ?? 0);

					let sumNegOptDisc = 0;

					if (negOrderItem?.[orderItem?.ID]?.orderItemOptions) {
						for (const optionItem of negOrderItem?.[orderItem?.ID]
							?.orderItemOptions) {
							sumNegOptDisc +=
								optionItem?.orderItemOptionItem?.discountBaseAmount +
								optionItem?.orderItemOptionItem?.itemDiscountTaxAmount +
								optionItem?.orderItemOptionItem?.discountItemTaxAmount;
						}
					}

					const disc = oid ? oid : odi;

					const activeOrderItemDiscount = orderItem?.orderItemDiscount?.filter(
						y => y?.status === OrderItemDiscountStatus?.Active,
					)[0];

					let totalOIDTax = 0;
					orderItem?.orderItemDiscountTax?.map(x => {
						totalOIDTax += x?.taxAmount;
					});

					let totalODITax = 0;
					orderItem?.orderDiscountItemTax?.map(x => {
						totalODITax += x?.taxAmount;
					});

					return (
						<div
							className="dialog-dynamic-content"
							style={{ marginTop: '5px' }}
						>
							<div className="session">
								<div className="flex session">
									<div
										className="xsTitle flex-space desc text-noflow"
										style={{
											alignSelf: 'center',
											fontSize: '16px',
											paddingBlockEnd: '4px',
											color: '#212121',
										}}
									>
										{`${orderItem?.openItemName ??
											orderItem?.menuItem?.name} x${orderItem?.quantity -
											(negOrderItem?.[orderItem?.ID]?.quantity ?? 0)}`}
									</div>
									<div
										className="xsTitle rightText"
										style={{
											fontSize: '16px',
											paddingBlockEnd: '4px',
											color: '#212121',
										}}
									>
										{`${currencyCode}${amtStr(
											new BigNumber(orderItem?.amount)
												.plus(totalOITax)
												.minus(
													(negOrderItem?.[orderItem?.ID]?.amount ?? 0) +
														(negOrderItem?.[orderItem?.ID]?.taxAmount ?? 0),
												)
												// .minus(disc ?? 0)
												.toNumber(),
										)}`}
									</div>
								</div>

								{orderItem?.orderItemOption?.length
									? orderItem?.orderItemOption?.map(oiOption => (
											<>
												{oiOption?.orderItemOptionItem?.map(oiOptionItem => {
													let totalTax = 0;
													oiOptionItem?.orderItemOptionItemTax?.map(x => {
														totalTax += x?.taxAmount;
													});

													return (
														<div className="flex session">
															<div
																className="xsTitle flex-space"
																style={{
																	color: '#4F4F4F',
																	fontWeight: 500,
																	alignSelf: 'center',
																	fontSize: '16px',
																	paddingBlockEnd: '4px',
																}}
															>
																{oiOptionItem?.optionItem?.name}
															</div>
															<div
																className="xsTitle rightText"
																style={{
																	color: '#4F4F4F',
																	fontWeight: 500,
																	fontSize: '16px',
																	paddingBlockEnd: '4px',
																}}
															>
																{`${
																	oiOptionItem?.price === 0
																		? ''
																		: currencyCode +
																		  amtStr(
																				Math.abs(
																					(oiOptionItem?.basePrice + totalTax) *
																						((orderItem?.quantity -
																							(negOrderItem?.[
																								oiOption?.orderItemID
																							]?.quantity ?? 0)) /
																							orderItem?.quantity),
																				),
																		  )
																}`}
															</div>
														</div>
													);
												})}
											</>
									  ))
									: orderItem?.orderItemSetMenuOption?.map(oiSetMenuOption => (
											<>
												{oiSetMenuOption?.orderItemSetMenuOptionItem?.map(
													oiSetMenuOptionItem => (
														<div
															className="xsTitle"
															style={{
																color: '#4F4F4F',
																fontWeight: 500,
																paddingBottom: '2px',
																fontSize: '16px',
																paddingBlockEnd: '4px',
															}}
														>
															{
																oiSetMenuOptionItem?.setMenuOptionItem?.menuItem
																	?.name
															}
														</div>
													),
												)}
											</>
									  ))}

								{orderItem?.orderItemDiscount?.filter(
									y => y?.status === OrderItemDiscountStatus?.Active,
								)?.length > 0 && (
									<div className="session">
										<div className="flex session">
											<div
												className="xxTitle flex-space desc"
												style={{
													alignSelf: 'center',
													fontSize: '12px',
													paddingBlockEnd: '4px',
													color: '#212121',
												}}
											>
												{`Discount: ${
													discount?.filter(
														x => x?.ID === activeOrderItemDiscount?.discountID,
													)[0]?.code
												}`}
											</div>
											<div
												className="xsTitle rightText"
												style={{
													fontSize: '12px',
													paddingBlockEnd: '4px',
													color: '#212121',
												}}
											>
												{`(${currencyCode}${amtStr(
													activeOrderItemDiscount?.baseAmount +
														totalOIDTax -
														(negOrderItem?.[orderItem?.ID]
															?.orderItemDiscBaseAmount ?? 0) -
														(negOrderItem?.[orderItem?.ID]
															?.orderItemDiscTaxAmount ?? 0) +
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === orderItem?.ID,
														)[0]?.discountBaseAmount ?? 0) +
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === orderItem?.ID,
														)[0]?.itemDiscountTaxAmount ?? 0) -
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === orderItem?.ID,
														)[0]?.discountItemTaxAmount ?? 0) -
														sumNegOptDisc,

													// calcDiscountAmount(orderItem),
												)})`}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					);
				})}
				<Divider style={{ display: 'flex', width: '100%', color: 'black' }} />
				{mode == 'void-bill' || mode == 'bill-settlement' ? (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							discountCode={discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							rounding={footerToken?.rounding}
							tax={footerToken?.tax}
							total={footerToken?.total}
							mode={mode}
							query={data}
							latestPayment={latestPayment}
							currencyCode={currencyCode}
							outletID={outletID}
							order={data[0]?.order}
							officer={officer}
							staff={staff}
						/>
					</>
				) : mode == 'void-payment' ? (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							discountCode={discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							tax={footerToken?.tax}
							total={footerToken?.total}
							mode="void-payment"
							query={data}
							latestPayment={latestPayment}
							chargeInfo1={chargeInfo1}
							chargeInfo2={chargeInfo2}
							currencyCode={currencyCode}
							outletID={outletID}
							order={data[0]?.order}
							officer={officer}
							staff={staff}
						/>

						{data[0]?.signature !== undefined &&
							data[0]?.signature !== null &&
							Object.keys(data[0]?.signature).length > 0 && (
								<img
									className="icon-svg"
									style={{
										height: '120px',
										width: '120px',
										margin: '0px 10px 10px',
									}}
									src={signImage}
								/>
							)}
					</>
				) : (
					<>
						<BillFooterReceipt
							discount={footerToken?.discount}
							discountCode={discount}
							subtotal={footerToken?.subtotal}
							serviceCharge={footerToken?.serviceCharge}
							tax={footerToken?.tax}
							total={footerToken?.total}
							query={data}
							mode={'bill-printing'}
							latestPayment={latestPayment}
							chargeInfo1={chargeInfo1}
							chargeInfo2={chargeInfo2}
							currencyCode={currencyCode}
							outletID={outletID}
							order={data[0]?.order}
							officer={officer}
							staff={staff}
						/>

						{data[0]?.signature !== undefined &&
							data[0]?.signature !== null &&
							Object.keys(data[0]?.signature).length > 0 && (
								<div style={{ marginTop: '5px' }}>
									<img
										className="icon-svg"
										style={{
											height: '120px',
											width: '120px',
											margin: '0px 10px 10px',
										}}
										src={signImage}
									/>
								</div>
							)}
					</>
				)}
			</CardContents>
		</div>
	);
};

export const printReceipt = async (
	fileURL,
	printerName,
	mode,
	isCash = false,
) => {
	let nonWhiteSpace = printerName.replace(/ /g, '');
	let posID = 1;

	const viewResult = x => {
		console.log(
			'%cPrinter Results',
			'color: #12850b;',
			JSON.stringify({ posID: posID, printerName: nonWhiteSpace }, null, '\t'),
		);
	};

	const printImage = async () => {
		//Set Printer ID
		await bxlPOS.setPosId(posID);

		//Print Attachment
		await bxlPOS.printBitmap(fileURL, 469, 1, true);

		await bxlPOS.cutPaper(1);

		if (mode === 'bill-settlement' && isCash === true) {
			console.log('cash drawer');
			await bxlPOS.openDrawer(0);
		}

		let posData = bxlPOS.getPosData();
		await bxlCommon.requestPrint(nonWhiteSpace, posData, viewResult);
		posID++;
	};

	bxlPOS.checkPrinterStatus();
	return await printImage();
};

export const printReceiptText = async (
	logo,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	// signImage,
	printerName,
	mode,
	isCash = false,
	receiptType,
	getTaxSchemeDetail,
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
	getUsersByAccountAndSoftware,
	taxByClass,
	officer,
	staff,
) => {
	let nonWhiteSpace = printerName.replace(/ /g, '');
	let posID = 1;

	const viewResult = x => {
		console.log(
			'%cPrinter Results',
			'color: #12850b;',
			JSON.stringify({ posID: posID, printerName: nonWhiteSpace }, null, '\t'),
		);
	};

	const printImage = async () => {
		//Set Printer ID
		bxlPOS.setPosId(posID);

		//Print Attachment
		//  bxlPOS.printBitmap(fileURL, 469, 1, false);

		//Print Text
		if (receiptType === 'bill-receipt') {
			BillReceipt(
				logo,
				outlet,
				data,
				page,
				orderItem,
				negOrderItem,
				footerToken,
				discount,
				latestPayment,
				chargeInfo1,
				chargeInfo2,
				currencyCode,
				totalOptItmPerOrderItem,
				patronName,
				getTaxSchemeDetail,
				// signImage,
				getUsersByAccountAndSoftware,
				officer,
				staff,
			);
		} else if (receiptType === 'order-receipt') {
			OrderReceipt(
				outletName,
				orderData,
				tableData,
				orderItemData,
				orderMode,
				voidItemData,
				voidQuantity,
				reasonCode,
				remark,
			);
		} else if (receiptType === 'new-order') {
			NewOrderReceipt(outletName, tableNo, userName, outletID, qrID, accountID);
		} else if (receiptType === 'e-invoice') {
			BillReceiptPrintEInvoice(
				outlet,
				data,
				orderItem,
				negOrderItem,
				discount,
				totalOptItmPerOrderItem,
				footerToken,
				latestPayment,
				chargeInfo1,
				chargeInfo2,
				taxByClass,
			);
		}

		bxlPOS.cutPaper(1);

		if (mode === 'bill-settlement' && isCash === true) {
			console.log('cash drawer');
			bxlPOS.openDrawer(0);
		}

		let posData = bxlPOS.getPosData();
		bxlCommon.requestPrint(nonWhiteSpace, posData, viewResult);
		posID++;
	};

	bxlPOS.checkPrinterStatus();
	return printImage();
};

export const openDrawer = async printerName => {
	let nonWhiteSpace = printerName.replace(/ /g, '');
	let posID = 1;

	const viewResult = x => {
		console.log(
			'%cPrinter Results',
			'color: #12850b;',
			JSON.stringify({ posID: posID, printerName: nonWhiteSpace }, null, '\t'),
		);
	};

	const printImage = async () => {
		//Set Printer ID
		await bxlPOS.setPosId(posID);

		//Print Attachment
		// await bxlPOS.printBitmap(fileURL, 469, 1, false);

		await bxlPOS.cutPaper(0);

		await bxlPOS.openDrawer(0);

		let posData = bxlPOS.getPosData();
		await bxlCommon.requestPrint(nonWhiteSpace, posData, viewResult);
		posID++;
	};

	bxlPOS.checkPrinterStatus();
	return await printImage();
};

export const PrinterDialog = props => {
	const {
		openPrinterDialog,
		setOpenPrinterDialog,
		printer,
		setPrinter,
		bixolonPrinter,
		localPrinter,
		mode,
	} = props;

	const printers = [
		{
			name: 'Bixolon SRP-330II/SRP-330III',
			value: 'bixolon',
		},
		{
			name: 'Local printers',
			value: 'printer',
		},
	];

	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={openPrinterDialog}
				onClose={() => {
					setOpenPrinterDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="dialog-dynamic-content">
								<div className="session">
									<div className="title">{'Printers'}</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<RadioGroup
								onChange={e => {
									setPrinter(e?.target?.value);
								}}
							>
								{printers?.map((el, index) => {
									return (
										<>
											<FormControlLabel
												key={index}
												value={el?.value}
												control={<Radio />}
												label={el?.name}
											/>
										</>
									);
								})}
							</RadioGroup>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenPrinterDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Print',
								props: {
									onClick: () => {
										if (printer === 'bixolon') {
											bixolonPrinter();
											setOpenPrinterDialog(false);
										} else if (printer === 'printer') {
											setOpenPrinterDialog(false);
											localPrinter();
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const alignment = (leftText: any, rightText: any, bold: any) => {
	let LINE_CHARS = 42;

	if (leftText?.length > LINE_CHARS) {
		let firstLine = leftText.slice(0, 42);
		let secondLine = leftText.slice(42);

		let padding = LINE_CHARS - secondLine?.length - rightText?.length;
		let paddingChar = ' ';

		for (let i = 0; i < padding; i++) {
			paddingChar = paddingChar.concat(' ');
		}

		bxlPOS.printText(`${firstLine}\n`, 0, 0, bold, false, false, 0, 0);
		bxlPOS.printText(
			secondLine + paddingChar + rightText,
			0,
			0,
			bold,
			false,
			false,
			0,
			0,
		);
	} else if (leftText?.length + rightText?.length + 1 > LINE_CHARS) {
		let firstLine = leftText.slice(0, 42 - (rightText?.length + 1));
		let secondLine = leftText.slice(42 - (rightText?.length + 1));

		let padding = LINE_CHARS - secondLine?.length - rightText?.length;
		let paddingChar = ' ';

		for (let i = 0; i < padding; i++) {
			paddingChar = paddingChar.concat(' ');
		}

		bxlPOS.printText(`${firstLine}\n`, 0, 0, bold, false, false, 0, 0);
		bxlPOS.printText(
			secondLine + paddingChar + rightText,
			0,
			0,
			bold,
			false,
			false,
			0,
			0,
		);
	} else {
		let padding = LINE_CHARS - leftText?.length - rightText?.length;
		let paddingChar = ' ';

		for (let i = 0; i < padding; i++) {
			paddingChar = paddingChar.concat(' ');
		}

		bxlPOS.printText(
			leftText + paddingChar + rightText,
			0,
			0,
			bold,
			false,
			false,
			0,
			0,
		);
	}
};

export const BillReceipt = (
	logo,
	outlet,
	data,
	page,
	orderItem,
	negOrderItem,
	footerToken,
	discount,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	currencyCode,
	totalOptItmPerOrderItem,
	patronName,
	getTaxSchemeDetail,
	getUsersByAccountAndSoftware,
	officer,
	staff,
	// signImage,
) => {
	//logo
	// if (outlet?.logo?.fileURL) {
	// 	bxlPOS.printBitmap(outlet?.logo?.fileURL, 200, 1, false);
	// } else {
	// 	bxlPOS.printBitmap(OutletIcon, 200, 1, false);
	// }

	bxlPOS.printBitmap(logo, 200, 1, false);

	// address
	bxlPOS.printText(
		`${outlet?.address?.address},\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);
	bxlPOS.printText(
		`${outlet?.address?.postCode}, ${outlet?.address?.city}, ${outlet?.address?.state}.\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// phone
	bxlPOS.printText(`${outlet?.contactNo}\n`, 0, 0, false, false, false, 0, 1);

	// sst
	bxlPOS.printText(
		`(SST Reg No: ${outlet?.taxRegistrationNo})\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// outlet name
	alignment(` `, `\n`, false);
	bxlPOS.printText(`${outlet?.name}\n\n`, 0, 0, true, false, false, 0, 1);

	// bill no & date
	if (page !== 'bill-settlement') {
		alignment(
			`Bill No: ${data?.billNo}`,
			`${convertDate24Hrs(data?.createdTs)}\n`,
			true,
		);
	} else {
		alignment(` `, `${convertDate24Hrs(data?.createdTs)}\n`, true);
	}

	// patron
	const patronClass = data?.patron?.patronClass
		? data?.patron?.patronClass
		: data?.order?.patron?.patronClass;

	if (page === 'void-bill') {
		alignment(`Voided Bill`, `\n`, true);
	} else {
		if (patronClass === 'Staff') {
			alignment(`Staff       : ${patronName}`, `\n`, false);
		} else if (patronClass === 'Officer') {
			alignment(`Officer     : ${patronName}`, `\n`, false);
		} else if (patronClass === 'Visitor') {
			alignment(`Visitor     : ${patronName ?? '-'}`, `\n`, false);
		} else if (patronClass === 'Hotel Guest') {
			alignment(`Hotel Guest : ${patronName}`, `\n`, false);
		} else if (patronClass === 'Member') {
			alignment(`Member`, `\n`, false);
		}
	}

	//table
	if (page !== 'void-bill') {
		alignment(
			`Table       : ${
				page === 'bill-settlement'
					? data?.table?.prefix + data?.table?.tableNumber
					: data?.order?.table?.prefix + data?.order?.table?.tableNumber
			}`,
			`\n`,
			false,
		);
	}

	// cover
	if (page !== 'void-bill' || data?.order?.serviceMode === 'DINE_IN') {
		alignment(
			`Cover       : ${
				page === 'bill-settlement' ? data?.pax : data?.order?.pax
			}`,
			`\n`,
			false,
		);
	}

	if (page !== 'void-bill') {
		alignment(
			`Cashier     : ${
				getUsersByAccountAndSoftware?.filter(
					x => x?.ID === data?.modBy || x?.ID === data?.createdBy,
				)[0]?.name
			}`,
			`\n`,
			false,
		);
		alignment(` `, `\n`, false);
	}

	// order item (oi)
	{
		orderItem?.map(oi => {
			// oi name & quantity

			let totalOITax = 0;
			oi?.orderItemTax?.map(x => {
				totalOITax += x?.taxAmount;
			});

			if (oi?.isTakeaway === true) {
				alignment(
					`${oi?.openItemName ?? oi?.menuItem?.name} (T) x${oi?.quantity -
						(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
					`${currencyCode}${amtStr(
						new BigNumber(oi?.amount)
							.plus(totalOITax)
							.minus(
								(negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
							)
							.toNumber(),
					)}\n`,
					false,
				);
			} else if (oi?.isTakeaway === false) {
				alignment(
					`${oi?.openItemName ?? oi?.menuItem?.name} x${oi?.quantity -
						(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
					`${currencyCode}${amtStr(
						new BigNumber(oi?.amount)
							.plus(totalOITax)
							.minus(
								(negOrderItem?.[oi?.ID]?.amount ?? 0) +
									(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
							)
							.toNumber(),
					)}\n`,
					false,
				);
			}

			if (oi?.orderItemOption?.length > 0) {
				// order item option (oio)
				oi?.orderItemOption?.map(oiOption => {
					oiOption?.orderItemOptionItem?.map(oiOptionItem => {
						// oio name and amount

						let totalTax = 0;
						oiOptionItem?.orderItemOptionItemTax?.map(x => {
							totalTax += x?.taxAmount;
						});

						alignment(
							`${oiOptionItem?.optionItem?.name}`,
							`${
								oiOptionItem?.price === 0
									? ''
									: currencyCode +
									  amtStr(
											Math.abs(
												(oiOptionItem?.basePrice + totalTax) *
													((oi?.quantity -
														(negOrderItem?.[oiOption?.orderItemID]?.quantity ??
															0)) /
														oi?.quantity),
											),
									  )
							}\n`,
							false,
						);
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				// order item set menu option (oismo)
				oi?.orderItemSetMenuOption?.map(oiSetMenuOption => {
					oiSetMenuOption?.orderItemSetMenuOptionItem?.map(
						// oismo name
						async oiSetMenuOptionItem => {
							alignment(
								`${oiSetMenuOptionItem?.setMenuOptionItem?.menuItem?.name}`,
								`\n`,
								false,
							);
						},
					);
				});
			}

			// order item discount (oid)
			const activeOrderItemDiscount = oi?.orderItemDiscount?.filter(
				y => y?.status === OrderItemDiscountStatus?.Active,
			)[0];

			let totalOIDTax = 0;
			oi?.orderItemDiscountTax?.map(x => {
				totalOIDTax += x?.taxAmount;
			});

			let sumNegOptDisc = 0;

			if (activeOrderItemDiscount) {
				alignment(
					`Discount: ${
						discount?.filter(
							x => x?.ID === activeOrderItemDiscount?.discountID,
						)[0]?.code
					}`,
					`(${currencyCode}${amtStr(
						activeOrderItemDiscount?.baseAmount +
							totalOIDTax -
							(negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ?? 0) -
							(negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.discountBaseAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.itemDiscountTaxAmount ?? 0) -
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.discountItemTaxAmount ?? 0) -
							sumNegOptDisc,

						// calcDiscountAmount(orderItem),
					)})\n`,
					false,
				);
			}
		});
	}

	if (page === 'void-bill' || page === 'bill-settlement') {
		BillReceiptFooter(
			footerToken?.total,
			footerToken?.discount,
			discount,
			footerToken?.subtotal,
			footerToken?.serviceCharge,
			footerToken?.tax,
			page,
			data,
			latestPayment,
			undefined,
			undefined,
			currencyCode,
			page === 'bill-settlement' ? footerToken?.rounding : 0,
			data?.order?.taxSchemeInfo || data?.taxSchemeInfo,
			getTaxSchemeDetail,
			// footerToken?.rounding,
			officer,
			staff,
		);
	} else if (page === 'void-payment') {
		BillReceiptFooter(
			footerToken?.total,
			footerToken?.discount,
			discount,
			footerToken?.subtotal,
			footerToken?.serviceCharge,
			footerToken?.tax,
			'void-payment',
			data,
			latestPayment,
			chargeInfo1,
			chargeInfo2,
			currencyCode,
			0,
			data?.order?.taxSchemeInfo,
			getTaxSchemeDetail,
			officer,
			staff,
		);

		if (
			data[0]?.signature !== undefined &&
			data[0]?.signature !== null &&
			Object.keys(data[0]?.signature).length > 0
		) {
			// await bxlPOS.printBitmap(signImage, 300, 1, false);
		}
	} else {
		BillReceiptFooter(
			footerToken?.total,
			footerToken?.discount,
			discount,
			footerToken?.subtotal,
			footerToken?.serviceCharge,
			footerToken?.tax,
			'bill-printing',
			data,
			latestPayment,
			chargeInfo1,
			chargeInfo2,
			currencyCode,
			0,
			data?.order?.taxSchemeInfo,
			getTaxSchemeDetail,
			officer,
			staff,
		);

		if (
			data[0]?.signature !== undefined &&
			data[0]?.signature !== null &&
			Object.keys(data[0]?.signature).length > 0
		) {
			// await bxlPOS.printBitmap(signImage, 300, 1, false);
		}
	}
};
