import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { Check, KeyboardArrowRight, MonetizationOn } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useEInvoiceInformationLazyQuery,
	useGetAssignedOutletByUserQuery,
	useGetBillOffsetQuery,
	useGetBillWithDateLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { dateFormat, formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { OutletIcon } from '../../../assets';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};
interface QRProps {
	code: string;
}

export const DigitalBillList = (props: any) => {
	useEffect(() => {
		console.log('DigitalBillList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { outletID }: any = useParams();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const digitalListSearch = JSON.parse(localStorage.getItem(`searchFilter`));

	const [rerender, setRender] = useState(false);

	const latestOutlet = localStorage.getItem('latestOutlet');

	const [selectedOutlet, setSelectedOutlet] = useState(null);
	const [eInvoiceDialog, setEInvoiceDialog] = useState(false);
	const [eInvoiceDialogObj, setEInvoiceDialogObjg]: any = useState();

	// const {
	// 	called: ouletCalled,
	// 	loading: getAssignedOutletByUserLoading,
	// 	error: outletError,
	// 	data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
	// } = useGetOutletQuery({
	// 	fetchPolicy: 'network-only',
	// });

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		setSelectedOutlet(
			getAssignedOutletByUser?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getAssignedOutletByUser]);

	// const {
	// 	data: { getBill } = { getBill: [] },
	// 	called: billCalled,
	// 	loading: billLoading,
	// } = useGetBillQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { outletID: selectedOutlet?.ID, orderByDesc: 'createdTs' },
	// });

	// const {
	// 	fetchMore,
	// 	refetch,
	// 	loading: loadingBill,
	// 	data: { getBillInfinite } = { getBillInfinite: [] },
	// } = useGetBillOffsetQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: {
	// 		input: {
	// 			outletID,
	// 		},
	// 		offSet: 0,
	// 		limit: 20,
	// 	},
	// 	onCompleted: () => {
	// 		loadMenuXInHouseList({
	// 			variables: {
	// 				hotelID: getAssignedOutletByUser?.filter(
	// 					outlet => outlet?.ID === outletID,
	// 				)[0]?.outletChannel?.hotelID,
	// 				outletID: outletID,
	// 			},
	// 		});
	// 		loadOfficer({
	// 			variables: {
	// 				isActive: true,
	// 			},
	// 		});
	// 		loadStaff({
	// 			variables: {
	// 				isActive: true,
	// 			},
	// 		});
	// 	},
	// });

	// useEffect(() => {
	// 	if (getBillInfinite && getBillInfinite?.length > 0) {
	// 		setOriginalListing(getBillInfinite);
	// 		setRender(true);
	// 	}
	// }, [getBillInfinite]);

	const totalPayment = payments => {
		const paymentAmounts = payments.map(payment => payment?.amount);
		const total = paymentAmounts.reduce(
			(accumulator: any, currentValue: any) => {
				return accumulator + currentValue;
			},
			0,
		);
		return total;
	};

	const [openSwitch, setOpenSwitch] = useState(null);

	const { control, watch } = useForm();

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);

	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const {
		filteredList: filteredOutletList,
		handleSearch: handleOutletSearch,
		setOriginalListing: setOriginalOutletListing,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalOutletListing(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	// useEffect(() => {
	// 	if (
	// 		!loadingBill &&
	// 		rerender &&
	// 		!!digitalListSearch?.value &&
	// 		getBillInfinite?.length > 0
	// 	) {
	// 		handleSearch(`'"${digitalListSearch?.value ?? ''}"`, ['billNo']);
	// 	}
	// }, [getBillInfinite, rerender, loadingBill]);

	const [
		loadBillWithDate,
		{
			fetchMore,
			refetch,
			data: { getBillWithDate } = { getBillWithDate: [] },
			called: billWithDateCalled,
			loading: billWithDateLoading,
		},
	] = useGetBillWithDateLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getAssignedOutletByUser?.filter(
						outlet => outlet?.ID === outletID,
					)[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		},
	});

	const [
		loadEInvoiceInformation,
		{
			loading: EInvoiceInformationLoading,
			data: { EInvoiceInformation } = { EInvoiceInformation: [] },
		},
	] = useEInvoiceInformationLazyQuery({
		fetchPolicy: 'cache-and-network',
	});

	useEffect(() => {
		if (selectedStartDate && selectedEndDate) {
			loadBillWithDate({
				variables: {
					outletID: outletID,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
					billNo:
						digitalListSearch?.value === ''
							? undefined
							: digitalListSearch?.value,
					offSet: 0,
					limit: 20,
				},
			});
		}
	}, [outletID, selectedStartDate, selectedEndDate, digitalListSearch?.value]);

	useEffect(() => {
		if (getBillWithDate && getBillWithDate?.length > 0) {
			setOriginalListing(getBillWithDate);
			setRender(true);
		}
	}, [getBillWithDate]);

	useEffect(() => {
		if (
			!billWithDateLoading &&
			rerender &&
			!!digitalListSearch?.value &&
			getBillWithDate?.length > 0
		) {
			handleSearch(`'"${digitalListSearch?.value ?? ''}"`, ['billNo']);
		}
	}, [getBillWithDate, rerender, billWithDateLoading]);

	// customer type info line / customer type info line / customer type info line //
	// customer type info line / customer type info line / customer type info line //
	// customer type info line / customer type info line / customer type info line //

	// const customerTypeInfoLine = (patronClass, data) => {
	// 	const patronInfo = data?.order?.patronName;
	// 	if (patronClass === 'Hotel Guest') {
	// 		return `Room No. ${patronInfo}`;
	// 	} else if (patronClass === 'Officer' || patronClass === 'Staff') {
	// 		return `${patronInfo?.replace('| ', '')}`;
	// 	} else if (patronClass === 'Visitor') {
	// 		return `Visitor | ${patronInfo}`;
	// 	} else if (patronClass === 'Member') {
	// 		return `Member`;
	// 	} else {
	// 		return ``;
	// 	}
	// };

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const customerTypeInfoLine = (patronClass, order) => {
		if (patronClass === 'Visitor') {
			return order?.patronName ?? 'Visitor';
		} else if (patronClass === 'Hotel Guest') {
			const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === order?.patronAccountID,
			)[0];
			return selectedGuest
				? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
						selectedGuest?.GuestName
				  }`
				: selectedGuest;
		} else if (patronClass === 'Staff') {
			return getStaff?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Officer') {
			return getOfficer?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Member') {
			return 'Member';
		}
	};

	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	console.log('date', getBillWithDate, filteredList);

	const eInvoiceInfoDialog = (
		<CommonDialog
			fullWidth={true}
			open={eInvoiceDialog}
			onClose={() => setEInvoiceDialog(false)}
			sections={{
				header: {
					children: (
						<ListItem className="remove-padding">
							<ListItemText
								primary={
									<>
										<span className="xsTitle color-primary-orange flex-space">
											e-Invoice Information
										</span>
									</>
								}
							/>
						</ListItem>
					),
				},
				body: () => (
					<CardContents>
						<>
							{EInvoiceInformation?.map(el => {
								return (
									<>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '10px',
											}}
										>
											<Grid xs={12} item className="xsTitle">
												{el?.docType ?? 'NA'} &nbsp; &nbsp;
												{`(RM ${amtStr(el?.docAmount)})`}
											</Grid>
										</Grid>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '10px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												UIN{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.UIN ?? 'NA'}
											</Grid>
										</Grid>
										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '5px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												Status{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.eStatus}
											</Grid>
										</Grid>

										<Grid
											container
											xs={12}
											style={{
												fontSize: '14px',
												fontWeight: 'normal',
												paddingTop: '5px',
											}}
										>
											<Grid xs={1} item className="xsTitle">
												{' '}
												Date{' '}
											</Grid>
											<Grid xs={1} item className="xsTitle">
												{' '}
												&nbsp;:{' '}
											</Grid>
											<Grid xs={10} item className="mdDesc">
												{el?.dateReceive ? dateFormat(el?.dateReceive) : 'NA'}
											</Grid>
										</Grid>
									</>
								);
							})}
						</>
					</CardContents>
				),
				footer: {
					actions: [
						{
							displayText: 'Close',
							props: {
								onClick: () => {
									setEInvoiceDialog(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);

	return (
		<>
			{billWithDateLoading && <Loading />}
			{getOfficerLoading && <Loading />}
			{getStaffLoading && <Loading />}
			{billWithDateLoading && <Loading />}
			{getAssignedOutletByUserLoading && <Loading />}
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={
					getAssignedOutletByUser === undefined ||
					getAssignedOutletByUser?.length === 0
						? ``
						: `${selectedOutlet?.name}`
				}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Digital Bill', current: true },
				]}
			/>
			<div
				className={`fix-subheader`}
				style={{
					width:
						isDesktop && globalState.drawerOpen
							? 'calc(100% - 288px) '
							: isDesktop && !globalState.drawerOpen
							? 'calc(100% - 48px)'
							: '100%',
				}}
			>
				<div
					className={`dynamic-subheader fix-subheader-content multiple`}
					style={{ width: '100%', paddingBottom: '50px' }}
				>
					{/* <CardActions> */}
					<Grid container direction="row">
						<Grid item xs={5} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								// format="EEE, dd MMM yyyy"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								value={selectedStartDate}
								autoOk
								onChange={handleStartDateChange}
								InputProps={{
									style: {
										fontSize: '1rem',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								className="custom-date-bi"
							/>
						</Grid>
						<Grid item xs={2} container justify="center">
							<div
								style={{
									marginTop: '8px',
									// paddingLeft: isDesktop && globalState.drawerOpen ? 310 : 0,
								}}
							>
								{'>'}
							</div>
						</Grid>
						<Grid item xs={5} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								// format="EEE, dd MMM yyyy"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								value={selectedEndDate}
								onChange={handleEndDateChange}
								autoOk
								InputProps={{
									style: {
										fontSize: '1rem',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								className="custom-date-bi"
								fullWidth={true}
							/>
						</Grid>
					</Grid>
					{/* </CardActions> */}
				</div>
			</div>
			<SearchHeader
				title="Digital Bill Listing "
				value={`${filteredList?.filter(a => {
					var date = new Date(a.businessDate);
					return date >= selectedStartDate && date <= selectedEndDate;
				})?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['billNo']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							value: e.target.value,
						}),
					);
				}}
				fixed
				isDefaultValue={!!digitalListSearch?.value ?? false}
				defaultValue={digitalListSearch?.value ?? ''}
				search
				datePickerSubInfo
				onCloseAction={() => {
					handleSearch('', []);
					localStorage.removeItem('searchFilter');
				}}
			/>

			<ContentWrapper datePickerSearchBar float>
				<InfiniteScroll
					style={{ overflow: 'inherit' }}
					dataLength={getBillWithDate?.length}
					next={() => {
						const currentLength = getBillWithDate?.length;
						fetchMore({
							variables: {
								outletID: outletID,
								startDate: selectedStartDate,
								endDate: selectedEndDate,
								offSet: currentLength,
								billNo:
									digitalListSearch?.value === ''
										? undefined
										: digitalListSearch?.value,
								limit: 10,
							},
							updateQuery: (prev: any, { fetchMoreResult }: any) => {
								if (!fetchMoreResult) return prev;

								return Object.assign({}, prev, {
									getBillWithDate: [
										...prev?.getBillWithDate,
										...fetchMoreResult?.getBillWithDate,
									],
								});
							},
						});
					}}
					hasMore={true}
					className=""
					loader={
						billWithDateLoading && (
							<div style={{ textAlign: 'center' }}>
								<CircularProgress />
							</div>
						)
					}
					endMessage={
						<p style={{ textAlign: 'center' }}>
							<b>-</b>
						</p>
					}
				>
					<List className="core-list">
						{!billWithDateLoading &&
						!getAssignedOutletByUserLoading &&
						(getBillWithDate === undefined || getBillWithDate?.length === 0) ? (
							<EmptyList
								title="No bill found"
								subtitle="Please order an item and make a payment to continue"
							/>
						) : (
							getBillWithDate
								// ?.filter(a => {
								// 	var date = new Date(a.createdTs);
								// 	var dateEnd = new Date(selectedEndDate);
								// 	dateEnd.setDate(dateEnd.getDate() + 1);
								// 	return date >= selectedStartDate && date <= selectedEndDate;
								// })
								?.map((el, index) => (
									<ListItem
										onClick={e => {
											history.push(
												`/menu/outlet-app/${selectedOutlet?.ID}/digital-bill/${el?.ID}`,
											);
										}}
										// onClick={e => {
										// 	history.push(
										// 		`/menu/outlet-app/${selectedOutlet?.ID}/digital-bill/${el?.ID}`,
										// 	);
										// }}
									>
										<ListItemText
											primary={
												<>
													{el?.einvoice_irbm_uniqueID ? (
														<>
															<span className="extra-row">
																<Grid
																	className="xsTitle"
																	style={
																		el?.status === 'VOID'
																			? { textDecoration: 'line-through' }
																			: null
																	}
																>{`${el?.einvoice_irbm_uniqueID}`}</Grid>
															</span>
														</>
													) : (
														<></>
													)}
													<span className="flex-space">
														<Grid container>
															<Grid
																className="xsTitle"
																style={
																	el?.status === 'VOID'
																		? { textDecoration: 'line-through' }
																		: null
																}
															>
																{/* <span className="xsTitle"> */}
																{`${formatDate(el?.businessDate)} `}
																<TextSeparator></TextSeparator>
																{`${el?.billNo}`}
																{/* </span> */}
																{
																	<>
																		{el?.is_einvoice ? (
																			el?.einvoice_irbm_uniqueID ? (
																				<>
																					{' '}
																					<IconButton
																						style={{ padding: '0px' }}
																						onClick={e => {
																							dispatch({
																								type: 'eInvoiceDialogObj',
																								payload: el,
																							});
																							dispatch({
																								type: 'eInvoiceDialog',
																								payload: true,
																							});
																							loadEInvoiceInformation({
																								variables: {
																									outletID: outletID,
																									ID: el?.ID,
																									type: 'Bill',
																								},
																							});
																						}}
																					>
																						{el?.einvoice_status ===
																						'CONSOLIDATED' ? (
																							<FilterNoneIcon
																								style={{
																									fontSize: '16px',
																									color: '#ff9800',
																								}}
																							/>
																						) : (
																							<CropSquareIcon
																								style={{
																									fontSize: '18px',
																									color: '#ff9800',
																								}}
																							/>
																						)}
																					</IconButton>
																				</>
																			) : (
																				<>
																					{' '}
																					<IconButton
																						style={{ padding: '0px' }}
																						onClick={e => {
																							setEInvoiceDialogObjg(el);
																							setEInvoiceDialog(true);
																							loadEInvoiceInformation({
																								variables: {
																									outletID: outletID,
																									ID: el?.ID,
																									type: 'Bill',
																								},
																							});
																						}}
																					>
																						{el?.einvoice_status ===
																						'CONSOLIDATED' ? (
																							<FilterNoneIcon
																								style={{
																									fontSize: '16px',
																									color: '#ff9800',
																								}}
																							/>
																						) : (
																							<CropSquareIcon
																								style={{
																									fontSize: '18px',
																									color: '#ff9800',
																								}}
																							/>
																						)}
																					</IconButton>
																				</>
																			)
																		) : (
																			''
																		)}
																	</>
																}
															</Grid>
															{/* <span className="mdDesc flex-space">{` | ${el?.billNo}`}</span> */}
														</Grid>
													</span>
													<span className="xsTitle">
														<span style={{ marginBottom: '-30px' }}>
															<MonetizationOn
																style={{
																	color: 'orange',
																	width: '15px',
																	height: '15px',
																	marginBottom: '-3px',
																	paddingRight: '3px',
																}}
															/>
														</span>
														<span style={{ color: 'orange' }}>
															{` ${amtStr(totalPayment(el?.payments))}`}
														</span>
													</span>
													{/* <span
														className="xsTitle"
														style={{ color: '#ff9800' }}
													> */}
													{/* </span> */}
												</>
											}
											secondary={
												<>
													<span className="desc">
														{/* {`${el?.order?.patron?.patronClass} | ${el?.order?.patronName}`} */}
														{customerTypeInfoLine(
															el?.order?.patron?.patronClass,
															el?.order,
														)}
													</span>
												</>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												key={0}
												onClick={e => {
													history.push(
														`/menu/outlet-app/${selectedOutlet?.ID}/digital-bill/${el?.ID}`,
													);
												}}
											>
												<KeyboardArrowRight />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))
						)}
					</List>
				</InfiniteScroll>
			</ContentWrapper>
			{eInvoiceInfoDialog}
			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<TextField
									// className="m-b-20"
									id="standard-basic"
									label="Search here..."
									fullWidth
									onChange={e => {
										handleOutletSearch(e?.target?.value, ['name']);
									}}
								/>
							) : null}
							<List>
								{filteredOutletList?.map((outlet, index) => (
									<>
										<ListItem
											style={{
												backgroundColor:
													outlet?.ID === outletID ? '#E5EDFF' : null,
											}}
										>
											<div style={{ paddingRight: '6px' }}>
												{outlet?.logo?.fileURL ? (
													<img
														src={outlet?.logo?.fileURL}
														style={{
															display: 'flex',
															marginTop: '2px',
															marginBottom: '2px',
															marginRight: '4px',
															width: '40px',
															height: '40px',
															justifyItems: 'center',
															borderRadius: '30px',
														}}
														loading="lazy"
													/>
												) : (
													<img
														src={OutletIcon}
														style={{
															display: 'flex',
															marginTop: '7px',
															marginBottom: '8px',
															marginRight: '4px',
															width: '40px',
															height: '25px',
															justifyItems: 'center',
															borderRadius: '30px',
														}}
														loading="lazy"
													/>
												)}
											</div>
											<ListItemText
												primary={
													<span className="xsTitle">{outlet?.name}</span>
												}
												onClick={() => {
													history.push(
														`/menu/outlet-app/${outlet?.ID}/digital-bill`,
													);
													setOpenSwitch(false);
													setSelectedOutlet(outlet);
													localStorage.setItem('latestOutlet', outlet?.ID);
												}}
											/>
											{outlet?.ID === outletID ? (
												<ListItemSecondaryAction>
													<IconButton edge="end" aria-label="delete">
														<Check color="primary" />
													</IconButton>
												</ListItemSecondaryAction>
											) : null}
										</ListItem>
										<Divider
											// variant="fullWidth"
											style={{ display: 'flex', width: '100%' }}
										/>
									</>
								))}
							</List>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
