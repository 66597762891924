import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
	Button,
	Grid,
	IconButton,
	Switch,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox, Delete } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { IAction } from 'helpers/model';
import {
	GetTaxListingDocument,
	useGetTaxDetailsLazyQuery,
	useGetTaxDetailsQuery,
	useGetTaxListingLazyQuery,
	useGetTaxListingQuery,
	useGetTaxTypeListingQuery,
	useIsTaxSameCodeLazyQuery,
	useTaxInsertMutation,
	useTaxUpdateMutation,
} from 'generated/graphql';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import { yupResolver } from '@hookform/resolvers';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { amtFloat, amtNumStr } from 'helpers/numFormatter';
import { addDays } from 'date-fns';
const dateFormat = require('dateformat');

export enum SchemeType {
	GST = 'GST',
	ServiceCharge = 'ServiceCharge',
	SST = 'SST',
}

interface TaxInputProps {
	code: string;
	description: string;
	classType: string;
	taxSchemeType: string;
	schemeTypeId: string;
	isClaimable: boolean;
	// createdBy: string;
	// modBy: string;
	taxDetail: TaxDetailProps[];
}

interface TaxDetailProps {
	effectiveDate: Date;
	rate: any;
	id: string;
}

export const TaxSetupForm = (props: any) => {
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { taxId, taxsubId } = useParams<any>();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [selectedOption, setSelectedOption] = useState(null);
	const [isTouch, setIsTouch] = useState(false);
	const [taxTypeListing, setTaxTypeListing] = useState(null);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);
	let history = useHistory();
	let location = useLocation();
	const state = location?.state as any;
	const { mode } = props;
	const [ConfirmationDialog, setConfirmationDialog] = useState(false);
	const [codeErr, setCodeErr] = useState(false);

	const taxData: any = location?.state;
	const {
		loading: getTaxDetailsLoading,
		data: { getTaxDetails } = { GetTaxDetails: null },
	} = useGetTaxDetailsQuery({
		variables: {
			taxID: taxsubId,
			accountID: user?.accountID,
		},
		fetchPolicy: 'no-cache',
	});
	const {
		data: { getTaxTypeListing } = { getTaxTypeListing: [] },
		loading: taxTypeLoading,
		error: taxTypeError,
	} = useGetTaxTypeListingQuery({
		fetchPolicy: 'network-only',
		variables: { softwareCode: 'HotelX' },
	});

	useEffect(() => {
		if (getTaxTypeListing?.length > 0) {
			const tax = getTaxTypeListing
				?.filter(
					x => x?.code === 'SC' || x?.code === '02',
					// ||
					// x.name === 'VAT' ||
					// x.name === 'GST',
				)
				?.map(y => {
					if (y?.name === 'Service Charge') {
						return {
							...y,
							name: 'Service Charge',
						};
					} else {
						return y;
					}
				});

			setTaxTypeListing(tax);
		}
		console.log(getTaxTypeListing?.length, 'tax');
	}, [getTaxTypeListing]);
	console.log(getTaxDetails, 'tax');

	const taxByID = getTaxTypeListing?.filter(x => x?.id === taxId)[0];
	const modeTax = taxByID?.name === SchemeType.SST ? 'sst' : 'servicecharge';

	const currentDate = addDays(new Date(), 1);
	const isToday = date => {
		const today = new Date();
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		);
	};

	const [
		createTaxInsert,
		{ loading: createTaxInsertLoading },
	] = useTaxInsertMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
			setCodeErr(true);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/common-setting/tax-policy`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const [
		updateTaxInsert,
		{ loading: updateTaxInsertLoading },
	] = useTaxUpdateMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/common-setting/tax-policy`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const yupSchema = yup.object().shape({
		code: yup
			.string()
			.required('Tax Code is required')
			.matches(
				/^[a-zA-Z0-9][a-zA-Z0-9_.]{0,19}$/, // Update the range to 19 characters
				'Invalid Tax Code, Tax Code Should Not Be Above 20 Characters',
			),
	});

	const {
		register,
		handleSubmit,
		errors,
		control,
		setValue,
		getValues,
		watch,
		clearErrors,
	} = useForm<TaxInputProps>({
		defaultValues: {
			code: getTaxDetails?.code,
		},
		mode: 'onSubmit',
		// resolver: yupResolver(yupSchema),
	});

	const initialState: TaxInputProps = {
		code: '',
		description: '',
		taxSchemeType: '',
		classType: 'OUTPUT',
		isClaimable: false,
		schemeTypeId: '',
		// createdBy: '',
		// modBy: '',
		taxDetail: [
			{
				effectiveDate: new Date(),
				rate: 0.0,
				id: '',
			},
		],
	};

	const ADD_ROW = 'ADD_ROW';
	interface IAction<Type, Payload = any> {
		type: Type;
		payload?: Payload;
	}
	const addRowAction = (): IAction<string> => ({
		type: ADD_ROW,
	});

	const reducer: Reducer<TaxInputProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'CloseDialog':
				return {
					...state,
					Dialog: false,
				};
			case 'reset':
				return initialState;
			case ADD_ROW:
				const newRow = {
					effectiveDate: currentDate,
					rate: 0.0,
					id: '', // You can generate a unique ID here if needed
				};
				return {
					...state,
					taxDetail: [...state.taxDetail, newRow],
				};
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [rstate, dispatch] = useReducer(reducer, initialState);

	const [
		loadIsTaxSameCode,
		{
			loading: isTaxSameCodeLoading,
			data: { isTaxSameCode } = { isTaxSameCode: null },
		},
	] = useIsTaxSameCodeLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (rstate.code === '') {
			loadIsTaxSameCode({
				variables: {
					accountID: user?.accountID,
					taxCode: '',
				},
			});
		}
	}, [rstate.code]);

	const options = ['INPUT', 'OUTPUT'];
	const [values, setValues] = React.useState<string | null>(options[1]);
	const [inputValue, setInputValue] = React.useState('OUTPUT');
	const [taxTypeValues, setTaxTypeValue] = React.useState<string | null>(
		getTaxTypeListing[1],
	);
	const [taxTypeInput, setTaxTypeInput] = React.useState(null);
	const [deletedItemIds, setDeletedItemIds] = useState<string[]>([]);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'taxDetail',
	});

	useEffect(() => {
		if (mode === 'Edit') {
			const initialTaxDetail = getTaxDetails?.taxDetail || [];
			const taxView = initialTaxDetail.map((detail: any) => {
				return {
					id: detail.id,
					effectiveDate: new Date(detail.effectiveDate),
					rate: amtNumStr(detail.rate),
				};
			});
			taxView.sort(
				(a, b) => a.effectiveDate.getTime() - b.effectiveDate.getTime(),
			);
			const itemsToBeDeleted = rstate.taxDetail.filter(newItem => {
				return !taxView.some(
					oldItem =>
						new Date(newItem.effectiveDate).getTime() ===
							new Date(oldItem.effectiveDate).getTime() &&
						amtNumStr(newItem.rate) === oldItem.rate &&
						newItem.id === oldItem.id,
				);
			});
			const deletedIds = itemsToBeDeleted.map(item => item.id);

			setDeletedItemIds(deletedIds);
			dispatch({ type: 'code', payload: getTaxDetails?.code });
			dispatch({ type: 'description', payload: getTaxDetails?.description });
			dispatch({ type: 'taxSchemeType', payload: getTaxDetails?.schemeTypeId });
			dispatch({ type: 'taxDetail', payload: taxView });
		}
	}, [mode, getTaxDetails]);

	const deleteRow = (index: number) => {
		const newFormat = [...rstate.taxDetail];
		const deletedRow = newFormat[index];

		// Check if the row has an 'id' (indicating it's an existing record)
		if (deletedRow.id) {
			// Mark the row for deletion
			setDeletedItemIds([...deletedItemIds, deletedRow.id]);
		}

		// Remove the row from the format array
		newFormat.splice(index, 1);
		dispatch({ type: 'taxDetail', payload: newFormat });
	};

	useEffect(() => {
		if (mode === 'Add') {
			if (fields.length < 2) {
				append({
					effectiveDate: currentDate,
					rate: 0.0,
				});
			}
		}
	}, [mode, fields, append]);

	const onSubmit = (data: any) => {
		let taxDetailInput;
		if (rstate?.taxDetail?.length > 0) {
			taxDetailInput = rstate?.taxDetail?.map(v => {
				return {
					effectiveDate: v?.effectiveDate,
					rate: amtFloat(v?.rate),
					id: v?.id,
				};
			});
		}

		if (mode === 'Add') {
			createTaxInsert({
				variables: {
					accountID: user?.accountID,
					taxInsertInput: {
						code: rstate?.code,
						description: rstate?.description,
						classType: 'OUTPUT',
						schemeTypeId: selectedOption?.id,
						isClaimable: false,
						// createdBy: user?.ID,
						// modBy: user?.ID,
						taxDetail: taxDetailInput,
					},
				},
				refetchQueries: [
					{
						query: GetTaxListingDocument,
						variables: { accountID: user?.accountID, taxTypeID: taxId },
					},
				],
			});
		} else if (mode === 'Edit') {
			updateTaxInsert({
				variables: {
					accountID: user?.accountID,
					taxEditInput: {
						code: rstate?.code === '' ? getTaxDetails?.code : rstate?.code,
						description:
							rstate?.description === ''
								? getTaxDetails?.description
								: rstate?.description,
						classType: 'OUTPUT',
						schemeTypeId:
							isTouch === false
								? getTaxTypeListing?.find(x => x?.id === rstate?.taxSchemeType)
										?.id
								: selectedOption?.id,
						isClaimable: false,
						// createdBy: user?.ID,
						modBy: user?.ID,
						taxDetail: taxDetailInput,
						taxId: getTaxDetails?.id,
						deletedId: deletedItemIds,
					},
				},
				refetchQueries: [
					{
						query: GetTaxListingDocument,
						variables: { accountID: user?.accountID, taxTypeID: taxId },
					},
				],
			});
		}
		// : null;
		clearErrors();
	};

	return createTaxInsertLoading || taxTypeLoading || getTaxDetailsLoading ? (
		<Loading />
	) : (
		<>
			<MainHeader
				mainBtn="close"
				onClick={() => {
					history.push(`/menu/outlet-app/common-setting/tax-policy`);
				}}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: `` },
					{ name: '' },
					{ name: `Tax Policy Form`, current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'Add' ? 'New' : 'Edit', current: true },
				]}
			/>
			<ContentWrapper footer>
				<CardContents>
					<TextField
						label="Tax Code"
						fullWidth
						required
						value={rstate?.code || ''}
						margin="normal"
						name="code"
						onChange={e => {
							dispatch({ type: 'code', payload: e.target.value });
						}}
						onBlur={e => {
							loadIsTaxSameCode({
								variables: {
									accountID: user?.accountID,
									taxCode: e.target.value,
								},
							});
						}}
						helperText={
							rstate?.code?.length > 20
								? 'Invalid Tax Code, Tax Code Should Not Be Above 20 Characters'
								: isTaxSameCode === true
								? 'This code already exists'
								: errors?.code?.message
								? true
								: false
						}
						error={
							rstate?.code?.length > 20
								? true
								: isTaxSameCode === true
								? true
								: errors?.code?.message
								? true
								: false
						}
					/>

					<TextField
						label="Description"
						fullWidth
						required
						autoComplete="off"
						value={rstate?.description || ''}
						margin="normal"
						name="Description"
						onChange={e => {
							dispatch({ type: 'description', payload: e.target.value });
						}}
					/>

					<Autocomplete
						options={taxTypeListing}
						fullWidth
						getOptionLabel={option => option?.name}
						value={
							isTouch === false
								? taxTypeListing?.find(
										x => x?.id === getTaxDetails?.schemeTypeId,
								  )
								: selectedOption
						}
						onChange={(event, GetTaxTypeListing) => {
							setSelectedOption(GetTaxTypeListing);
							setIsTouch(true);
						}}
						renderInput={params => (
							<TextField
								{...params}
								label="Tax Scheme Type"
								margin="normal"
								name="TaxScheme"
							/>
						)}
					/>

					<Autocomplete
						value={values}
						fullWidth
						disabled
						onChange={(event: any, newValue: string | null) => {
							setValues(newValue);
						}}
						inputValue={inputValue}
						onInputChange={(event, newInputValue) => {
							setInputValue(newInputValue);
						}}
						id="type"
						options={options}
						renderInput={params => (
							<TextField {...params} label="Type" variant="standard" />
						)}
					/>

					<div
						className="content-wrap full"
						style={{ justifyContent: 'space-between', display: 'flex' }}
					>
						<span className="flex-space">Claimable</span>
						<Switch
							disabled
							name="isClaimable"
							value={rstate?.isClaimable ?? true}
							checked={rstate?.isClaimable ?? true}
							onChange={e => {
								dispatch({ type: 'isClaimable', payload: e.target.value });
							}}
							color="primary"
						/>
					</div>
				</CardContents>
				{mode === 'Edit' ? (
					<CardContents
						section={{
							header: {
								title: 'Tax Rate',
								icon: rstate?.taxDetail?.find(
									x =>
										dateFormat(x?.effectiveDate, 'dd-mm-yyyy') >
										dateFormat(new Date(), 'dd-mm-yyy'),
								) ? (
									<AddBox style={{ color: 'gray' }} />
								) : (
									<IconButton onClick={() => dispatch(addRowAction())}>
										<AddBox color="primary" />{' '}
									</IconButton>
								),
								disabled: rstate?.taxDetail?.find(
									x =>
										dateFormat(x?.effectiveDate, 'dd-mm-yyyy') >
										dateFormat(new Date(), 'dd-mm-yyy'),
								),
							},
						}}
					>
						<Grid container spacing={2}>
							{rstate?.taxDetail?.map((x, index) => {
								const formatField = `taxDetail[${index}]`;
								const isFirstRow = index === 0;
								return (
									<Grid
										container
										item
										xs={12}
										key={x.id}
										style={{
											borderBottom: '1px solid #ccc',
											paddingBottom: '10px',
										}}
									>
										<Grid item xs={11} container>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													value={rstate?.taxDetail[index]?.effectiveDate}
													margin="normal"
													label="Effective Date"
													className="left"
													name={`${formatField}.EffectiveDate`}
													required
													disablePast
													shouldDisableDate={isToday}
													fullWidth
													disabled={
														isFirstRow ||
														dateFormat(
															rstate?.taxDetail[index]?.effectiveDate,
															'dd-mm-yyyy',
														) <= dateFormat(new Date(), 'dd-mm-yyy')
													}
													onChange={date => {
														const newValues = rstate.taxDetail;
														let selected = newValues[index];

														if (selected) {
															selected.effectiveDate =
																date === undefined ? currentDate : date;
														} else {
															newValues.push({
																id: '' || x.id,
																effectiveDate: date,
																rate: newValues[index]?.rate || '',
															});
														}
														dispatch({ type: 'taxDetail', payload: newValues });
													}}
													format="dd/MM/yyyy"
												/>
											</MuiPickersUtilsProvider>
											<NumberFormat
												thousandSeparator
												customInput={TextField}
												fullWidth
												required
												className="right"
												defaultValue={0.0}
												disabled={isFirstRow}
												style={{ marginTop: '15px' }}
												value={rstate?.taxDetail[index]?.rate}
												onValueChange={e => {
													const newValues = [...rstate.taxDetail];
													let selected = newValues[index];

													if (selected) {
														selected.rate =
															e.floatValue === undefined ? 0 : e.floatValue;
													} else {
														newValues.push({
															id: '' || x.id,
															rate: e.floatValue,
															effectiveDate:
																newValues[index]?.effectiveDate || currentDate,
														});
													}
													dispatch({ type: 'taxDetail', payload: newValues });
												}}
												decimalScale={2}
												label="Tax Rate (%)"
											/>
										</Grid>
										<Grid
											item
											xs={1}
											style={{ marginTop: '5px', paddingLeft: '5px' }}
											justifyContent="flex-end"
										>
											{mode === 'Edit' ? (
												<IconButton
													className="delete-icon"
													onClick={() => deleteRow(index)}
													disabled={
														mode === 'Edit' &&
														dateFormat(x?.effectiveDate, 'dd-mm-yyyy') <=
															dateFormat(new Date(), 'dd-mm-yyyy') &&
														x?.id !== ''
													}
													style={{
														transform: 'scale(0.7)',
														marginTop: '5px',
													}}
													question-uid={`${formatField}.uid`}
												>
													<Delete
														style={{
															backgroundColor:
																mode === 'Edit' &&
																dateFormat(x?.effectiveDate, 'dd-mm-yyyy') <=
																	dateFormat(new Date(), 'dd-mm-yyyy') &&
																x?.id !== ''
																	? 'grey'
																	: '#FF1B40',
															borderRadius: '4px',
															width: '25px',
															height: '25px',
															color: 'white',
															fontSize: '10px',
															paddingBottom: '2px',
														}}
														fontSize="large"
													/>
												</IconButton>
											) : null}
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</CardContents>
				) : (
					<CardContents
						section={{
							header: {
								title: 'Tax Rate',
								onClickAction: () => {
									append({
										sequence: fields.length,
										type: null,
										effectiveDate: dateFormat(currentDate, 'dd-mm-yyyy'),
										rate: 0.0,
										id: '',
									});
								},
								icon:
									mode === 'Add' ? null : (
										<AddIcon
											htmlColor="white"
											fontSize="default"
											style={{
												width: '1.0rem',
												height: '1.0rem',
												background: theme.palette.primary.main,
												borderRadius: '3px',
												color: 'rgba(224,234,254,100)',
												margin: '10px 10px',
											}}
										/>
									),
							},
						}}
					>
						<Grid container spacing={2}>
							{fields?.map((x, index) => {
								const formatField = `taxDetail[${index}]`;
								const isFirstRow = index === 0;
								const isSecondRow = index === 1;
								return (
									<Grid
										container
										item
										xs={12}
										key={x.id}
										style={{
											borderBottom: '1px solid #ccc',
											paddingBottom: '10px',
										}}
									>
										<Grid item xs={12} container>
											<MuiPickersUtilsProvider utils={DateFnsUtils}>
												<KeyboardDatePicker
													value={rstate?.taxDetail[index]?.effectiveDate}
													margin="normal"
													label="Effective Date"
													className="left"
													name={`${formatField}.EffectiveDate`}
													disablePast
													shouldDisableDate={isToday}
													required
													fullWidth
													disabled={isFirstRow}
													onChange={date => {
														const newValues = rstate.taxDetail;
														let selected = newValues[index];

														if (selected) {
															selected.effectiveDate =
																date === undefined ? currentDate : date;
														} else {
															newValues.push({
																id: '' || x.id,
																effectiveDate: date,
																rate: newValues[index]?.rate || '',
															});
														}
														dispatch({ type: 'taxDetail', payload: newValues });
													}}
													format="dd/MM/yyyy"
												/>
											</MuiPickersUtilsProvider>
											<NumberFormat
												thousandSeparator
												customInput={TextField}
												fullWidth
												required
												className="right"
												defaultValue={0.0}
												disabled={isFirstRow}
												style={{ marginTop: '15px' }}
												value={rstate?.taxDetail[index]?.rate}
												onValueChange={e => {
													const newValues = [...rstate.taxDetail];
													let selected = newValues[index];

													if (selected) {
														selected.rate =
															e.floatValue === undefined ? 0 : e.floatValue;
													} else {
														newValues.push({
															id: '' || x.id,
															rate: e.floatValue,
															effectiveDate:
																newValues[index]?.effectiveDate || currentDate,
														});
													}
													dispatch({ type: 'taxDetail', payload: newValues });
												}}
												decimalScale={2}
												label="Tax Rate (%)"
											/>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					</CardContents>
				)}
			</ContentWrapper>

			<Footer
				options={[
					{
						name: 'Save',
						onClick: () => handleSubmit(onSubmit)(),
						color:
							(mode === 'Add' &&
								(rstate?.code === '' ||
									rstate?.description === '' ||
									selectedOption === null)) ||
							rstate?.code?.length > 20 ||
							isTaxSameCode === true
								? 'inherit'
								: 'primary',
						disabled:
							(mode === 'Add' &&
								(rstate?.code === '' ||
									rstate?.description === '' ||
									selectedOption === null)) ||
							rstate?.code?.length > 20 ||
							isTaxSameCode === true,
					},
				]}
			/>
			{/* <SnackBarMsg open={openSnackBar} message={snackBarMessage} /> */}
		</>
	);
};
