import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Grid,
	IconButton,
	List,
	Menu,
	MenuItem,
	Switch,
	TextField,
} from '@material-ui/core';
import { AddBox, MoreVert } from '@material-ui/icons';
import { addDays } from 'date-fns';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { TaxSchemeFormDialog } from './TaxSchemeFormDialog';
import {
	useGetTaxSchemeListingQuery,
	useIsTaxSameNameLazyQuery,
} from 'generated/graphql';

const dateFormat = require('dateformat');

interface Props {
	state: any;
	dispatch: any;
	control: any;
	getTaxListingDropdown: any;
	loadIsTaxSameName: any;
	isTaxSameName: any;
}

export const TaxSchemeFormDetail = (props: Props) => {
	const {
		state,
		dispatch,
		control,
		getTaxListingDropdown,
		loadIsTaxSameName,
		isTaxSameName,
	} = props;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'taxArray',
	});

	const nextDate = addDays(new Date(), 1);

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [fieldLength, setFieldLength] = useState(0);
	const [isSequenceTouched, setIsSequenceTouched] = useState(false);

	useEffect(() => {
		if (menu?.obj !== null) {
			if (fields.length < fieldLength) {
				append({
					taxCode: '',
					computeMethod: '',
				});
			}
		}
	}, [fields, append, fieldLength]);

	const itemEdit = () => {
		const itemLength = menu?.obj?.taxInfo?.length;
		setFieldLength(itemLength);

		const newTaxArr = menu?.obj?.taxInfo?.map(el => {
			const item = {
				...el,
			};
			return item;
		});

		dispatch({ type: 'taxArray', payload: newTaxArr });
		dispatch({ type: 'effectiveDate', payload: menu?.obj?.effectiveDate });
		dispatch({ type: 'addDialog', payload: true });
		dispatch({ type: 'type', payload: 'Edit' });
	};

	const itemDelete = () => {
		const newTaxArr = state?.taxListing
			?.map((el, index) => {
				if (index !== menu?.index) {
					return el;
				}
			})
			?.filter(x => x !== undefined);

		dispatch({ type: 'taxListing', payload: newTaxArr });
		dispatch({ type: 'isTouched', payload: true });
		dispatch({ type: 'effectiveDate', payload: nextDate });
		dispatch({
			type: 'taxArray',
			payload: [{ taxCode: '', computeMethod: '' }],
		});
		fields?.forEach((x, index) => remove(index));
		setFieldLength(0);
	};

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	useEffect(() => {
		if (state.name === '') {
			loadIsTaxSameName({
				variables: {
					accountID: user?.accountID,
					taxSchemeName: '',
				},
			});
		}
	}, [state.name]);

	return (
		<>
			<CardContents>
				<TextField
					label="Name"
					fullWidth
					required
					value={state?.name || ''}
					margin="normal"
					name="name"
					onChange={e => {
						dispatch({ type: 'name', payload: e.target.value });
						dispatch({ type: 'isTouched', payload: true });
					}}
					onBlur={e => {
						loadIsTaxSameName({
							variables: {
								accountID: user?.accountID,
								taxSchemeName: e.target.value,
							},
						});
					}}
					helperText={
						isTaxSameName === true ? 'This name already exists' : false
					}
					error={isTaxSameName === true ? true : false}
				/>

				<TextField
					label="Description"
					fullWidth
					value={state?.description || ''}
					margin="normal"
					name="description"
					onChange={e => {
						dispatch({ type: 'description', payload: e.target.value });
						dispatch({ type: 'isTouched', payload: true });
					}}
				/>

				<div
					className="content-wrap full"
					style={{ justifyContent: 'space-between', display: 'flex' }}
				>
					<span className="flex-space">Inclusive</span>
					<Switch
						name="isInclusive"
						value={state.isInclusive ?? true}
						checked={state.isInclusive ?? true}
						onChange={e => {
							dispatch({ type: 'isInclusive', payload: e.target.checked });
							dispatch({ type: 'isTouched', payload: true });
						}}
						color="primary"
					/>
				</div>
			</CardContents>

			<CardContents
				section={{
					header: {
						title: 'Tax Scheme Detail',
						onClickAction: () => {
							fields?.forEach((x, index) => remove(index));
							dispatch({ type: 'type', payload: 'Add' });
							dispatch({
								type: 'taxArray',
								payload: [{ taxCode: '', computeMethod: '' }],
							});
							dispatch({ type: 'addDialog', payload: true });
						},
						icon:
							state?.taxListing?.filter(
								x =>
									dateFormat(x.effectiveDate, 'dd mmm yyyy') >=
									dateFormat(new Date(), 'dd mmm yyyy'),
							)?.length !== 0 ? (
								<AddBox style={{ color: 'gray' }} />
							) : (
								<AddBox color="primary" />
							),
						disabled:
							state?.taxListing?.filter(
								x =>
									dateFormat(x.effectiveDate, 'dd mmm yyyy') >=
									dateFormat(nextDate, 'dd mmm yyyy'),
							)?.length !== 0,
					},
				}}
			>
				{state?.taxListing?.length <= 0 ? (
					<div
						className="flex-space"
						style={{ justifyContent: 'center', alignItems: 'center' }}
					>
						<EmptyList
							title="No Record found"
							subtitle="Click the add button to create a new record."
						/>
					</div>
				) : (
					<>
						<div className="flex-space">
							<List className="core-list">
								{state.taxListing?.map((el, i) => (
									<Grid
										xs={12}
										style={{
											borderBottom: '1px solid #ccc',
											paddingBottom: '10px',
											color:
												dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
												dateFormat(new Date(), 'dd mmm yyyy')
													? 'black'
													: 'gray',
										}}
									>
										<Grid container item xs={12}>
											<div
												style={{
													paddingLeft: '10px',
													paddingTop: '10px',
													// fontSize: '13px',
												}}
												className="xsTitle flex-space"
											>
												{dateFormat(el.effectiveDate, 'dd mmm yyyy')}
											</div>

											{dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
											dateFormat(nextDate, 'dd mmm yyyy') ? (
												<div
													style={{ paddingRight: '10px', paddingTop: '5px' }}
												>
													<IconButton
														edge="end"
														onClick={e => {
															handleClick(e, el?.id, i, el);
														}}
													>
														<MoreVert />
													</IconButton>
												</div>
											) : null}
										</Grid>
										{el?.taxInfo?.map((el2, index) => (
											<>
												<Grid
													container
													item
													xs={12}
													style={{
														fontSize: '12px',
													}}
												>
													<div
														style={{
															paddingLeft: '18px',
															paddingTop: '10px',
															color:
																dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																dateFormat(nextDate, 'dd mmm yyyy')
																	? 'black'
																	: 'gray',
														}}
														className="desc"
													>
														{index + 1}.
													</div>
													{/* <div
															style={{
																paddingLeft: '2px',
																paddingTop: '10px',
																color:
																	dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																	dateFormat(nextDate, 'dd mmm yyyy')
																		? 'black'
																		: 'gray',
															}}
															className="desc"
														>
															{el2?.taxCode?.tax_type}
														</div> */}
													<div
														style={{
															paddingLeft: '10px',
															paddingTop: '10px',
															color:
																dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																dateFormat(nextDate, 'dd mmm yyyy')
																	? 'black'
																	: 'gray',
														}}
														className=" desc flex-space"
													>
														{/* {`${el2?.taxCode?.rate}%`} */}
														{`${el2?.taxCode?.code}`}
													</div>
													<div
														style={{
															paddingRight: '18px',
															paddingTop: '10px',
															color:
																dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																dateFormat(nextDate, 'dd mmm yyyy')
																	? 'black'
																	: 'gray',
														}}
														className=" desc"
													>
														{el2?.taxCode?.tax_type}
													</div>
												</Grid>
												<Grid
													container
													item
													xs={12}
													style={{
														fontSize: '12px',
														color:
															dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
															dateFormat(nextDate, 'dd mmm yyyy')
																? 'black'
																: 'gray',
													}}
												>
													<div
														style={{
															paddingLeft: '18px',
															color:
																dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																dateFormat(nextDate, 'dd mmm yyyy')
																	? 'black'
																	: 'gray',
														}}
														className="desc flex-space"
													>
														{el2?.computeMethod}
													</div>
													<div
														style={{
															paddingRight: '18px',
															color:
																dateFormat(el.effectiveDate, 'dd mmm yyyy') >=
																dateFormat(nextDate, 'dd mmm yyyy')
																	? 'black'
																	: 'gray',
														}}
														className=" desc"
													>
														{el2?.taxCode?.class_type}
													</div>
												</Grid>
											</>
										))}
									</Grid>
								))}

								<Menu
									id="menu-list"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
									onClick={handleClose}
								>
									<MenuItem
										onClick={() => {
											itemEdit();
										}}
									>
										Edit
									</MenuItem>

									<MenuItem
										onClick={() => {
											itemDelete();
										}}
									>
										Delete
									</MenuItem>
								</Menu>
							</List>
						</div>
					</>
				)}
			</CardContents>

			<TaxSchemeFormDialog
				state={state}
				dispatch={dispatch}
				fields={fields}
				append={append}
				remove={remove}
				menu={menu}
				fieldLength={fieldLength}
				setFieldLength={setFieldLength}
				isSequenceTouched={isSequenceTouched}
				setIsSequenceTouched={setIsSequenceTouched}
				getTaxListingDropdown={getTaxListingDropdown}
			/>
		</>
	);
};
