import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	Tooltip,
} from '@material-ui/core';
import {
	Check,
	KeyboardArrowRight,
	MoreVert,
	Person,
	Tune,
} from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AuditUser from 'assets/icons/audit-user.svg';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import { formatDate } from 'helpers/formatDate';
import { amtStr } from 'helpers/numFormatter';
import { FilterDialog } from './FilterDialog';
import {
	EInvoiceConsolidationTrxType,
	PosPermission,
	useConsolidatedInvoiceListingQuery,
	useEInvoiceInformationLazyQuery,
	useGetAssignedOutletByUserQuery,
	useGetOutletQuery,
	useGetUsersByAccountQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import {
	eInvoiceCompleted,
	eInvoicePending,
	eInvoiceReject,
	OutletIcon,
} from 'assets';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';

export const ConsolidatedEInvoiceListing = (props: any) => {
	const dateFormat = require('dateformat');
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { mode } = props;

	const consolidatedInvoiceListingSearch = JSON.parse(
		localStorage.getItem('searchFilter'),
	);

	const [filterSearch, setFilterSearch] = useState<string>(
		localStorage.getItem('filterValue') || '',
	);
	window.onbeforeunload = () => {
		localStorage.removeItem('filterValue');
	};

	const { outletID } = useParams<any>();

	const { globalState, dispatch: globalDispatch }: any = useContext(
		AppContext as any,
	);
	const { handlePermDisabled } = usePermissionChecker();

	const handleDrawer = () => {
		globalDispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		fetchMore,
		refetch,
		loading: ConsolidatedInvoiceListingLoading,
		data: { ConsolidatedInvoiceListing } = { ConsolidatedInvoiceListing: [] },
	} = useConsolidatedInvoiceListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
			companyID: getOutlet[0]?.companyID,
			startDate:
				null || JSON.parse(localStorage.getItem('filterArgs'))?.startDate,
			endDate: null || JSON.parse(localStorage.getItem('filterArgs'))?.endDate,
			docNo: '' || JSON.parse(localStorage.getItem('filterArgs'))?.docNo,
			docType: '' || JSON.parse(localStorage.getItem('filterArgs'))?.docType,
			offSet: 0,
			limit: 20,
		},
	});

	const [
		loadEInvoiceInformation,
		{
			loading: EInvoiceInformationLoading,
			data: { EInvoiceInformation } = { EInvoiceInformation: [] },
		},
	] = useEInvoiceInformationLazyQuery({
		fetchPolicy: 'cache-and-network',
	});

	const history = useHistory();
	const [change, setSwitch] = useState(false);
	const [rerender, setRender] = useState(false);
	const [eInvoiceDialog, setEInvoiceDialog] = useState(false);

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const [openSwitch, setOpenSwitch] = useState(null);

	const {
		filteredList: filteredList2,
		handleSearch: handleSearch2,
		setOriginalListing: setOriginalListing2,
	} = useFuseSearch();

	useEffect(() => {
		if (
			ConsolidatedInvoiceListing &&
			ConsolidatedInvoiceListing?.length > 0
			// rerender === false
		) {
			setOriginalListing2(ConsolidatedInvoiceListing);
			setRender(true);
		}
	}, [ConsolidatedInvoiceListing]);

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing1(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	const initialValue = () => {
		const savedFilterArgs = JSON.parse(localStorage.getItem('FilterArgs'));
		return {
			startDate:
				JSON.parse(localStorage.getItem('filterArgs'))?.startDate ?? null,
			endDate: JSON.parse(localStorage.getItem('filterArgs'))?.endDate ?? null,
			docNo: JSON.parse(localStorage.getItem('filterArgs'))?.docNo ?? '',
			docType: JSON.parse(localStorage.getItem('filterArgs'))?.docType ?? '',
		};
	};

	const [filterValue, setFilterValue] = useState(initialValue());
	const [openFilterDialog, setOpenFilterDialog] = useState(false);

	const triggerFilterDialogOpen = () => {
		setOpenFilterDialog(true);

		if (JSON.parse(localStorage.getItem('filterArgs'))) {
			setFilterValue({
				startDate:
					null || JSON.parse(localStorage.getItem('filterArgs'))?.startDate,
				endDate:
					null || JSON.parse(localStorage.getItem('filterArgs'))?.endDate,
				docNo: '' || JSON.parse(localStorage.getItem('filterArgs'))?.docNo,
				docType: '' || JSON.parse(localStorage.getItem('filterArgs'))?.docType,
			});
		}
	};

	const triggerFilterDialogClose = () => {
		setOpenFilterDialog(false);
	};

	useEffect(() => {
		if (
			rerender &&
			!ConsolidatedInvoiceListingLoading &&
			!!consolidatedInvoiceListingSearch?.value &&
			ConsolidatedInvoiceListing?.length > 0
		) {
			handleSearch2(`'"${consolidatedInvoiceListingSearch?.value ?? ''}"`, [
				'docNo',
				'description',
			]);
		}
	}, [ConsolidatedInvoiceListingLoading, ConsolidatedInvoiceListing, rerender]);

	const {
		data: { getUsersByAccount } = {
			getUsersByAccount: [],
		},
	} = useGetUsersByAccountQuery({
		variables: {
			accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
			//   outletID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
		},
		fetchPolicy: 'network-only',
	});

	const createdByName = ID => {
		return getUsersByAccount?.find(x => x.ID === ID)?.name || '';
	};

	const eInvoiceTypeEnum = data => {
		let eInvClass;
		if (data === 'INVOICE') {
			eInvClass = 'Invoice';
		} else if (data === 'CREDIT_NOTE') {
			eInvClass = 'Credit Note';
		}
		return eInvClass;
	};

	const eInvoiceInfoDialog = (
		<CommonDialog
			fullWidth={true}
			open={eInvoiceDialog}
			onClose={() => setEInvoiceDialog(false)}
			sections={{
				header: {
					children: (
						<ListItem className="remove-padding">
							<ListItemText
								primary={
									<>
										<span className="xsTitle color-primary-orange flex-space">
											e-Invoice Information
										</span>
									</>
								}
							/>
						</ListItem>
					),
				},
				body: () => (
					<CardContents>
						<>
							<Grid
								container
								xs={12}
								style={{
									fontSize: '14px',
									fontWeight: 'normal',
									paddingTop: '10px',
								}}
							>
								<Grid xs={2} item className="xsTitle">
									{' '}
									UIN{' '}
								</Grid>
								<Grid xs={1} item className="xsTitle">
									{' '}
									&nbsp;:{' '}
								</Grid>
								<Grid xs={9} item className="mdDesc">
									{EInvoiceInformation[0]?.UIN ?? 'NA'}
								</Grid>
							</Grid>

							<Grid
								container
								xs={12}
								style={{
									fontSize: '14px',
									fontWeight: 'normal',
									paddingTop: '5px',
								}}
							>
								<Grid xs={2} item className="xsTitle">
									{' '}
									Status{' '}
								</Grid>
								<Grid xs={1} item className="xsTitle">
									{' '}
									&nbsp;:{' '}
								</Grid>
								<Grid xs={9} item className="mdDesc">
									{EInvoiceInformation[0]?.eStatus}
								</Grid>
							</Grid>

							<Grid
								container
								xs={12}
								style={{
									fontSize: '14px',
									fontWeight: 'normal',
									paddingTop: '5px',
								}}
							>
								<Grid xs={2} item className="xsTitle">
									{' '}
									Date{' '}
								</Grid>
								<Grid xs={1} item className="xsTitle">
									{' '}
									&nbsp;:{' '}
								</Grid>
								<Grid xs={9} item className="mdDesc">
									{EInvoiceInformation[0]?.DateReceive
										? dateFormat(
												EInvoiceInformation[0]?.DateReceive,
												'dd/mm/yyyy hh:MM:ss',
										  )
										: 'NA'}
								</Grid>
							</Grid>
						</>
					</CardContents>
				),
				footer: {
					actions: [
						{
							displayText: 'Close',
							props: {
								onClick: () => {
									setEInvoiceDialog(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				title={
					getAssignedOutletByUser?.filter(outlet => outlet?.ID === outletID)[0]
						?.name
				}
				// title={user?.companyName}
				routeSegments={[{ name: 'Consolidated e-Invoice', current: true }]}
			/>

			<SearchHeader
				title="Consolidated e-Invoice"
				value={`${ConsolidatedInvoiceListing?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch2(`'"${e?.target?.value}"`, ['docNo', 'description']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({ value: e.target.value }),
					);
				}}
				fixed
				search
				onCloseAction={() => {
					handleSearch2('', []);
					localStorage.removeItem('filterArgs');
					localStorage.removeItem('searchFilter');
				}}
				option={{
					icon: <Tune />,
					onClick: () => {
						triggerFilterDialogOpen();
					},
				}}
			/>

			<ContentWrapper search float>
				<InfiniteScroll
					style={{ overflow: 'inherit' }}
					dataLength={ConsolidatedInvoiceListing?.length}
					next={() => {
						const currentLength = ConsolidatedInvoiceListing?.length;
						fetchMore({
							variables: {
								outletID: outletID,
								accountID: JSON.parse(localStorage.getItem('loggedInUser'))
									?.accountID,
								companyID: getOutlet[0]?.companyID,
								startDate: filterValue?.startDate,
								endDate: filterValue?.endDate,
								docNo: filterValue?.docNo,
								docType: filterValue?.docType,
								offSet: currentLength,
								limit: 10,
							},
							updateQuery: (prev: any, { fetchMoreResult }: any) => {
								if (!fetchMoreResult) return prev;

								return Object.assign({}, prev, {
									ConsolidatedInvoiceListing: [
										...prev?.ConsolidatedInvoiceListing,
										...fetchMoreResult?.ConsolidatedInvoiceListing,
									],
								});
							},
						});
					}}
					hasMore={true}
					className=""
					loader={
						ConsolidatedInvoiceListingLoading && (
							<div style={{ textAlign: 'center' }}>
								<CircularProgress />
							</div>
						)
					}
					endMessage={
						<p style={{ textAlign: 'center' }}>
							<b>-</b>
						</p>
					}
				>
					<List className="core-list rm-padding-infinitescroll">
						{!getAssignedOutletByUserLoading &&
						!ConsolidatedInvoiceListingLoading &&
						(ConsolidatedInvoiceListing === undefined ||
							ConsolidatedInvoiceListing?.length === 0) ? (
							<EmptyList
								title="No Record found"
								subtitle="Please add Consolidated to continue"
							/>
						) : (
							filteredList2?.map((i, index) => (
								<ListItem key={index}>
									<ListItemText
										primary={
											<>
												<Grid container item xs={12}>
													<span className="xsTitle flex-space">
														{formatDate(i.docDate)}&nbsp;&nbsp;{i.docNo}
													</span>
												</Grid>
												<Grid container item xs={12}>
													<span
														className="desc flex-space"
														style={{ fontWeight: 'bold', color: 'black' }}
													>
														{eInvoiceTypeEnum(i.einvoice_trx_type)}
													</span>
													<span
														style={{ fontWeight: 'bold', color: 'black' }}
														className="desc"
													>
														{amtStr(i?.docAmt)}
													</span>
												</Grid>
											</>
										}
										secondary={
											<>
												<span
													className="desc flex-space"
													style={{ fontWeight: 'bold', color: 'black' }}
												>
													<Tooltip
														title={
															<span className="icon-text desc">
																<Person /> {createdByName(i?.createdBy)}
																<br />
																{dateFormat(
																	i?.createdTs,
																	'dd mmm yyyy h:MM TT',
																)}
															</span>
														}
														placement="right-end"
													>
														<span
															className="desc"
															style={{ fontWeight: 'bold', color: 'black' }}
														>
															<img
																src={AuditUser}
																alt="ifca"
																width={12}
																loading="lazy"
																style={{ paddingRight: '4px' }}
															/>
															{i?.description}
														</span>
													</Tooltip>
												</span>

												<IconButton
													style={{ padding: '0px' }}
													onClick={e => {
														setEInvoiceDialog(true);
														loadEInvoiceInformation({
															variables: {
																outletID: outletID,
																ID: i?.ID,
																type: 'Consolidated',
															},
														});
													}}
												>
													{i?.einvoice_status === 'NEW' ||
													i?.einvoice_status === 'RECORDED' ||
													i?.einvoice_status === 'PENDING_VALIDATION' ? (
														<img
															src={eInvoicePending}
															alt="svg"
															style={{ width: '25px' }}
														/>
													) : i?.einvoice_status === 'VALIDATED' ||
													  i?.einvoice_status === 'REJECT_REQUEST' ||
													  i?.einvoice_status === 'CONSOLIDATED' ? (
														<img
															src={eInvoiceCompleted}
															alt="svg"
															style={{ width: '25px' }}
														/>
													) : (
														<img
															src={eInvoiceReject}
															alt="svg"
															style={{ width: '25px' }}
														/>
													)}
												</IconButton>
											</>
										}
									/>
									<ListItemSecondaryAction style={{ top: '38px' }}>
										{/* <IconButton
											edge="end"
											onClick={() => {
												localStorage.setItem('filterValue', filterSearch);
												history.push(
													`/menu/outlet-app/${outletID}/consolidate-e-inovoice/view/${i.ID}`,
													{
														Detail: i,
													},
												);
											}}
										>
											<KeyboardArrowRight /> */}
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, i.ID, index, i)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
						)}
					</List>
				</InfiniteScroll>
			</ContentWrapper>

			{eInvoiceInfoDialog}

			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem
					onClick={() => {
						localStorage.setItem('filterValue', filterSearch);
						history.push(
							`/menu/outlet-app/${outletID}/consolidate-e-inovoice/view/${menu.ID}`,
							{
								Detail: menu,
							},
						);
					}}
				>
					<span className="">View</span>
				</MenuItem>
			</Menu>

			<FloatButton
				onClick={() =>
					history.push(
						`/menu/outlet-app/${outletID}/consolidate-e-inovoice/add`,
					)
				}
			/>

			<FilterDialog
				setFilterValue={setFilterValue}
				filterValue={filterValue}
				refetch={refetch}
				open={openFilterDialog}
				close={triggerFilterDialogClose}
			/>

			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<TextField
									id="standard-basic"
									label="Search here..."
									fullWidth
									onChange={e => {
										handleSearch1(`'"${e?.target?.value}"`, ['name']);
									}}
								/>
							) : null}
							<ContentWrapper style={{ marginTop: '0px' }}>
								<List>
									{filteredList1?.map((outlet, index) => (
										<>
											<ListItem
												className={
													outlet?.ID === outletID ? 'bg-blue-light' : null
												}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/consolidate-e-inovoice`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider style={{ display: 'flex', width: '100%' }} />
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
