import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import React, { useEffect, useState } from 'react';

export const EInvoiceComponent = props => {
	const {
		register,
		setBillToA,
		setEmailA,
		setNricNoA,
		setTinA,
		setRegistrationNoA,
		setTaxRegistrationNoA,
		control,
		getCountry,
		getStatebyCountry,
		getCitybyState,
		getPostcodebyCity,
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		autoCompleteRef,
		addressState,
		country,
		setCountry,
		mode,
		isSubmitted,
		setState,
		setCity,
		newState,
		newPostcode,
		setPostcode,
		city,
		order,
		selectedCustomer,
		page,
	} = props;

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [nric, setNric] = useState('');

	useEffect(() => {
		setName(
			page === 'editOrderInfo'
				? selectedCustomer?.name
					? selectedCustomer?.name
					: order?.customerInfo?.billTo
				: page === 'newOrder'
				? selectedCustomer?.name
				: order?.customerInfo?.billTo,
		);

		setEmail(
			page === 'editOrderInfo'
				? selectedCustomer?.email
					? selectedCustomer?.email
					: order?.customerInfo?.email
				: page === 'newOrder'
				? selectedCustomer?.email
				: order?.customerInfo?.email,
		);

		setNric(
			page === 'editOrderInfo'
				? selectedCustomer?.nricNo
					? selectedCustomer?.nricNo
					: order?.customerInfo?.nricNo
				: page === 'newOrder'
				? selectedCustomer?.nricNo
				: order?.customerInfo?.nricNo,
		);
	}, [page, selectedCustomer, order]);

	return (
		<>
			<Controller
				render={({ onChange, value }) => (
					<TextField
						name=""
						label={'Bill To / Account Name'}
						autoComplete="off"
						multiline={true}
						fullWidth
						margin="dense"
						ref={register}
						defaultValue={name}
						onChange={e => setBillToA(e.target.value)}
						InputLabelProps={
							page === 'newOrder'
								? selectedCustomer?.name !== undefined
									? { shrink: true }
									: {}
								: {}
						}
					/>
				)}
				name=""
				control={control}
			/>

			<AddressAutoCompleteComponent
				getCountry={getCountry}
				getStatebyCountry={getStatebyCountry}
				getCitybyState={getCitybyState}
				getPostcodebyCity={getPostcodebyCity}
				handleCountryOnChange={handleCountryOnChange}
				handleStateOnChange={handleStateOnChange}
				handleCityOnChange={handleCityOnChange}
				handlePostCodeOnChange={handlePostCodeOnChange}
				handleGoogleOnChange={handleGoogleOnChange}
				autoCompleteRef={autoCompleteRef}
				state={addressState}
				country={country}
				setCountry={setCountry}
				formType={mode}
				isSubmitted={isSubmitted}
				isMandatory={false}
				setState={setState}
				setCity={setCity}
				newState={newState}
				newPostcode={newPostcode}
				setPostcode={setPostcode}
				city={city}
			/>

			<Controller
				render={({ onChange, value }) => (
					<TextField
						name=""
						label={'Email Address'}
						autoComplete="off"
						multiline={true}
						fullWidth
						margin="dense"
						ref={register}
						defaultValue={email}
						onChange={e => setEmailA(e.target.value)}
						InputLabelProps={
							page === 'newOrder'
								? selectedCustomer?.name !== undefined
									? { shrink: true }
									: {}
								: {}
						}
					/>
				)}
				name=""
				control={control}
			/>

			<Controller
				as={
					<NumberFormat
						name=""
						label="Identity Card No / Passport No"
						customInput={TextField}
						autoComplete="off"
						allowNegative={false}
						multiline={true}
						fullWidth
						style={{ margin: '0px' }}
						onValueChange={(value: any) => {
							setNricNoA(value.value);
						}}
						defaultValue={nric}
						format={'######-##-####'}
						mask={'_'}
						InputLabelProps={
							page === 'newOrder'
								? selectedCustomer?.nricNo !== undefined
									? { shrink: true }
									: {}
								: {}
						}
					/>
				}
				name=""
				ref={register}
				control={control}
			/>

			<Controller
				render={({ onChange, value }) => (
					<TextField
						name=""
						label={'Tin'}
						autoComplete="off"
						multiline={true}
						fullWidth
						margin="dense"
						ref={register}
						defaultValue={
							!addressState?.country?.name
								? ''
								: addressState?.country?.name === 'Malaysia'
								? 'EI00000000010'
								: 'EI00000000020'
						}
						onChange={e => setTinA(e.target.value)}
						InputLabelProps={
							page === 'newOrder'
								? addressState?.country?.name !== undefined
									? { shrink: true }
									: {}
								: {}
						}
					/>
				)}
				name=""
				control={control}
			/>

			<Controller
				render={({ onChange, value }) => (
					<TextField
						name=""
						label={'Registration No.'}
						autoComplete="off"
						multiline={true}
						fullWidth
						margin="dense"
						ref={register}
						defaultValue={order?.customerInfo?.registrationNo ?? 'NA'}
						onChange={e => setRegistrationNoA(e.target.value)}
					/>
				)}
				name=""
				control={control}
			/>

			<Controller
				render={({ onChange, value }) => (
					<TextField
						name=""
						label={'SST Tax Registration No.'}
						autoComplete="off"
						multiline={true}
						fullWidth
						margin="dense"
						ref={register}
						defaultValue={order?.customerInfo?.taxRegistrationNo ?? 'NA'}
						onChange={e => setTaxRegistrationNoA(e.target.value)}
					/>
				)}
				name=""
				control={control}
			/>
		</>
	);
};
