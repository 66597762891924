import MultipleItemsCarousel from '@ifca-root/react-component/src/components/Carousel/MultipleItemsCarousel';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/VoiceTextField';
import {
	Checkbox,
	FormControl,
	Grid,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import './order.scss';

const customLabel = (data: any, checked: boolean) => {
	return (
		<>
			{checked ? (
				<div className="xsTitle custom-label-checked">{data}</div>
			) : (
				<div className="xsTitle custom-label">{data}</div>
			)}
		</>
	);
};

const customRadio = (
	//data
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	//extra
	control: any,
	optionErr: any,
	register: any,
	selectedMenu: any,
	watch: any,
) => {
	return (
		<>
			<span>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={checkedRadioCondiWithDefaultVal(
										{
											optionIndex: optionIndex,
											option: option,
											optionItem: optionItem,
										},
										watch,
										selectedMenu,
										option,
										optionItem,
										`option[${optionIndex}].${option?.ID}[0]`,
									)}
									// checked={checkedRadioCondition(
									// 	watch,
									// 	optionIndex,
									// 	option,
									// 	optionItem.optionItemID,
									// 	optionItemIndex,
									// )}
									value={JSON.stringify({
										itemID: optionItem?.optionItemID,
										price: optionItem?.price,
									})}
									name={
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name
									}
									// defaultChecked={
									// 	selectedMenu?.orderItemOption[
									// 		optionIndex
									// 	]?.orderItemOptionItem?.filter(
									// 		(e) => e?.optionItemID === optionItem?.optionItemID,
									// 	).length > 0
									// 		? true
									// 		: false
									// }
									defaultChecked={
										selectedMenu?.orderItemOption
											?.filter(id => id?.optionID === option?.ID)[0]
											?.orderItemOptionItem?.filter(
												e => e?.optionItemID === optionItem?.optionItemID,
											).length > 0
											? true
											: false
									}
									checkedIcon={customLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										true,
									)}
									icon={customLabel(
										data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
											?.name,
										false,
									)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${optionIndex}].${option?.ID}[0]`}
						control={control}
						ref={register}
						// helperText={errors?.calcType?.message}
						// error={errors?.calcType ? true : false}
					/>
				))}
			</span>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}[0]`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};
const checkedRadioCondition = (
	watch: any,
	optionIndex: number,
	option: any,
	optionItemID: string,
) => {
	let optionItemIDandPrice: any = watch(
		`option[${optionIndex}].${option?.ID}[${0}]`,
	);
	if (optionItemIDandPrice && JSON.parse(optionItemIDandPrice)?.itemID) {
		return optionItemID === JSON.parse(optionItemIDandPrice)?.itemID;
	} else return false;
};

const checkedRadioCondiWithDefaultVal = (
	currCheck,
	watch,
	currData,
	option,
	optionItem,
	fieldName,
) => {
	if (watch(fieldName)) {
		return checkedRadioCondition(
			watch,
			currCheck.optionIndex,
			currCheck.option,
			currCheck.optionItem.optionItemID,
		);
	} else {
		return (
			currData?.orderItemOption
				?.filter(id => id?.optionID === option?.ID)[0]
				?.orderItemOptionItem?.filter(
					itemid => itemid?.optionItemID === optionItem?.optionItemID,
				).length > 0
		);
	}
};

const customCheckBox = (
	//data
	data: any,
	option: any,
	optionIndex: number,
	menuItemOptionItem: any,
	///extra
	optionErr: any,
	register: any,
	selectedMenu: any,
	setValue: any,
	unregister: any,
	watch: any,
	clearErrors: any,
) => {
	return (
		<>
			<div>
				{menuItemOptionItem?.map((optionItem: any, optionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={customLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								true,
							)}
							icon={customLabel(
								data?.filter(id => id?.ID === optionItem?.optionItemID)[0]
									?.name,
								false,
							)}
							onChange={e => {
								register(
									`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									let a = setValue(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
										optionItem?.price,
									);
								} else if (!e.target.checked) {
									unregister(
										`option[${optionIndex}].${option?.ID}[${e.target.value}]`,
									);
								}
							}}
							defaultChecked={
								selectedMenu?.orderItemOption
									?.filter(id => id?.optionID === option?.ID)[0]
									?.orderItemOptionItem?.filter(
										e => e?.optionItemID === optionItem?.optionItemID,
									).length > 0
									? true
									: false
							}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={optionItem?.optionItemID}
							name={`option[${optionIndex}].${option?.ID}[${optionItemIndex}]`}
						/>
					</FormControl>
				))}
			</div>
			<div>
				<TextField
					InputProps={{ disableUnderline: true }}
					disabled
					style={{ marginTop: '-35px' }}
					helperText={
						optionErr &&
						watch(`option[${optionIndex}].${option?.ID}`) === undefined
							? 'Must select at least 1 option'
							: null
					}
					error={optionErr}
				/>
			</div>
		</>
	);
};

const customSetMenuCheckBox = (
	//data
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	//extra
	register: any,
	selectedMenu: any,
	setValue: any,
	unregister: any,
) => {
	return (
		<div>
			{setMenuOptionItem?.map(
				(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
					<FormControl component="fieldset">
						<Checkbox
							checkedIcon={customLabel(setMenuOptionItem?.menuItem?.name, true)}
							icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
							onChange={e => {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
								);
								if (e.target.checked) {
									setValue(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
										{
											quantity: setMenuOptionItem?.quantity || 1,
											menuItemID: setMenuOptionItem?.menuItem?.ID,
										},
									);
								} else {
									unregister(
										`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${e.target.value}]`,
									);
								}
							}}
							defaultChecked={
								selectedMenu?.orderItemSetMenuOption
									?.filter(
										orderItemSetMenuOption =>
											orderItemSetMenuOption?.setMenuOptionID ===
											setMenuOption?.ID,
									)[0]
									?.orderItemSetMenuOptionItem?.filter(
										orderItemSetMenuOptionItem =>
											orderItemSetMenuOptionItem?.setMenuOptionItemID ===
											setMenuOptionItem?.ID,
									).length > 0
									? true
									: false
							}
							color="primary"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
							ref={register}
							value={setMenuOptionItem?.ID}
							name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItemIndex}]`}
						/>
					</FormControl>
				),
			)}
		</div>
	);
};

const customSetMenuRadio = (
	//data
	setMenuOptionItem: any,
	setMenuOption: any,
	setMenuOptionIndex: number,
	//extra
	control: any,
	register: any,
	watch: any,
) => {
	return (
		<span>
			{setMenuOptionItem?.map(
				(setMenuOptionItem: any, setMenuOptionItemIndex: number) => (
					<Controller
						as={
							<RadioGroup>
								<Radio
									checked={checkedRadioCondition(
										watch,
										setMenuOptionIndex,
										setMenuOption,
										setMenuOptionItem.ID,
									)}
									value={JSON.stringify({
										itemID: setMenuOptionItem?.ID,
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									})}
									name={setMenuOptionItem?.menuItem?.name}
									checkedIcon={customLabel(
										setMenuOptionItem?.menuItem?.name,
										true,
									)}
									icon={customLabel(setMenuOptionItem?.menuItem?.name, false)}
									ref={register}
								/>
							</RadioGroup>
						}
						name={`option[${setMenuOptionIndex}].${setMenuOption?.ID}[0]`}
						control={control}
						ref={register}
						// helperText={errors?.calcType?.message}
						// error={errors?.calcType ? true : false}
					/>
				),
			)}
		</span>
	);
};
// ???????????/

export const MenuDialogSummary = (props: any) => {
	const {
		checkMenuOptionError,
		changeOrderQuantity,
		control,
		editDialog,
		handleSubmit,
		optionErr,
		quantity,
		register,
		selectedMenu,
		selectedOrder,
		setEditDialog,
		setOptionErr,
		setQuantity,
		setTakeAwayStatus,
		setValue,
		updateItem,
		unregister,
		watch,
		weightName,
		audioInput,
		setAudioInput,
		record,
		setRecord,
		setText,
		handleSingleRemark,
		clearErrors,
		errors,
		mode,
		isSubmitting,
		setIsSubmitting,
		isDesktop,
		getRoundedQty,
		currencyCode = '',
		orderItemWithOptionsLoading,
	} = props;
	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={editDialog}
				onClose={() => {
					setEditDialog(false);
					setQuantity(selectedMenu?.quantity);
					setTakeAwayStatus(false);
					setAudioInput('');
					setIsSubmitting(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{mode === 'takeAway'
												? `Takeaway ${selectedOrder?.docNo}`
												: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{selectedMenu?.menuItem?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(selectedMenu?.unitPrice)}`}
									</div>
								</div>
							</div>
						),
						// children: (
						// 	<>
						// 		<ListItem
						// 			style={{ backgroundColor: '#c7d6ff' }}
						// 			className="remove-padding"
						// 		>
						// 			<ListItemText
						// 				primary={
						// 					<>
						// 						<div
						// 							className="smTitle highlight-text"
						// 							style={{ color: '#ff9800' }}
						// 						>
						// 							<span style={{ marginRight: '15px' }}>
						// 								{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
						// 							</span>
						// 						</div>
						// 					</>
						// 				}
						// 				secondary={
						// 					<>
						// 						<span className="highlight-text flex-space">
						// 							{selectedMenu?.menuItem?.name}
						// 						</span>
						// 						<span className="highlight-text">
						// 							{`$${amtStr(selectedMenu?.unitPrice)}`}
						// 						</span>
						// 					</>
						// 				}
						// 			/>
						// 		</ListItem>
						// 		<hr className="line-subheader" />
						// 	</>
						// ),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								<div className="img-container">
									<img
										src={selectedMenu?.menuItem?.attachments?.fileURL}
										alt=""
									/>
								</div>
							</MultipleItemsCarousel>
							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{selectedMenu?.menuItem?.description}
								</span>
								<hr className="line-description" />
								<div>
									{/* OPEN ITEM */}
									{/* OPEN ITEM */}

									{selectedMenu?.menuItem?.isOpenItem && (
										<div className="" style={{ marginBottom: '10px' }}>
											<span>
												<Controller
													as={TextField}
													fullWidth
													label="Menu Item"
													variant="standard"
													size="small"
													autoComplete="off"
													multiline={true}
													name="openItem"
													defaultValue={selectedMenu?.openItemName}
													control={control}
													ref={register}
													helperText={errors?.openItem?.message}
													error={errors?.openItem ? true : false}
												/>
											</span>
										</div>
									)}

									{/* OPEN PRICE */}
									{/* OPEN PRICE */}

									{(selectedMenu?.menuItem?.isOpenItem ||
										selectedMenu?.menuItem?.isOpenPrice) && (
										<div className="" style={{ marginBottom: '20px' }}>
											<span>
												<Controller
													as={
														<NumberFormat
															customInput={TextField}
															fullWidth
															label="Price"
															autoComplete="off"
															isNumericString
															decimalScale={2}
															fixedDecimalScale
															style={{
																marginBottom: '0px',
																marginTop: '0px',
															}}
															margin="dense"
															// ref={register}
															// control={control}
															allowNegative
															defaultValue={selectedMenu?.unitPrice}
														/>
													}
													name="openPrice"
													control={control}
													ref={register}
													helperText={errors?.openPrice?.message}
													error={errors?.openPrice ? true : false}
												/>
											</span>
										</div>
									)}

									{/* WIEGHT */}
									{/* WIEGHT */}
									{/* WIEGHT */}

									<Grid container style={{ marginBottom: '15px' }}>
										{!selectedMenu?.menuItem?.isByWeight ||
										(!selectedMenu?.menuItem?.isByWeight &&
											selectedMenu?.menuItem?.isOpenItem) ? (
											// <QUANTITY>
											// <QUANTITY>
											<Grid item xs={7}>
												<span>
													<IndeterminateCheckBoxIcon
														className="order-minus"
														onClick={() => changeOrderQuantity('reduce')}
													/>
													{/* <span className="order-quantity"> {quantity} </span> */}
													<Controller
														render={({ onChange, onBlur, value }) => (
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																thousandSeparator
																fullWidth={false}
																variant="standard"
																margin="dense"
																className="quantity-input"
																InputProps={{
																	disableUnderline: true,
																}}
																onValueChange={(value: any) => {
																	setQuantity(getRoundedQty(value?.floatValue));
																	setValue(
																		'qty',
																		getRoundedQty(value?.floatValue),
																	);
																}}
																style={{
																	width: isDesktop ? '25%' : '45%',
																	borderRadius: '8px',
																	border: 'solid',
																	borderColor: 'orange',
																}}
																// variant="outlined"
																defaultValue={quantity}
																value={watch('qty')}
															/>
														)}
														name="qty"
														control={control}
														ref={register}
													/>
													<AddBox
														className="order-add"
														onClick={() => changeOrderQuantity('add')}
													/>
												</span>
											</Grid>
										) : (
											// <WEIGHT>
											// <WEIGHT>
											<>
												<Grid
													item
													xs={7}
													className="mdDesc"
													style={{ alignSelf: 'center' }}
												>
													<span
														style={{ float: 'left', marginTop: '12px' }}
													>{`Weight `}</span>
													<Controller
														as={
															<NumberFormat
																customInput={TextField}
																autoComplete="off"
																isNumericString
																fullWidth={false}
																decimalScale={1}
																fixedDecimalScale
																margin="dense"
																// ref={register}
																// control={control}
																defaultValue={1}
																className="weight-input"
																allowNegatives
																isAllowed={value => {
																	const { floatValue } = value;
																	return floatValue % 0.5 === 0;
																}}
															/>
														}
														name="getWeight"
														size="small"
														fullWidth={false}
														// label="Weight"
														style={{ marginLeft: '8px', width: '50%' }}
														variant="outlined"
														control={control}
														ref={register}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	{` ${weightName?.code}`}
																</InputAdornment>
															),
														}}
													/>
												</Grid>
											</>
										)}
										{mode !== 'takeAway' && (
											<Grid
												item
												xs={5}
												style={{
													float: 'right',
													alignSelf: 'center',
													textAlign: 'end',
												}}
											>
												<Checkbox
													value={selectedMenu?.ID}
													defaultChecked={selectedMenu?.isTakeaway}
													onChange={e =>
														e.target.checked
															? setTakeAwayStatus(true)
															: setTakeAwayStatus(false)
													}
													color="primary"
													style={{ padding: '5px' }}
													inputProps={{
														'aria-label': 'secondary checkbox',
													}}
													ref={register}
												/>

												<span className="mdDesc">Take Away</span>
											</Grid>
										)}
									</Grid>

									{/* {mode !== 'takeAway' && (
										<span
											style={{
												float: 'right',
												position: 'relative',
												paddingTop: '10px',
											}}
										>
											<Checkbox
												//value={selectedMenu?.ID}
												defaultChecked={selectedMenu?.isTakeaway}
												onChange={e => {
													e.target.checked
														? setTakeAwayStatus(true)
														: setTakeAwayStatus(false);
												}}
												color="primary"
												style={{ padding: '5px' }}
												inputProps={{
													'aria-label': 'secondary checkbox',
												}}
												ref={register}
											/>

											<span className="mdDesc">{'Take Away'}</span>
										</span>
									)} */}

									{selectedMenu?.menuItem?.menuItemOptions?.map((ele, i) => (
										<>
											<div className="option-radio">
												<div className="mdDesc" key={ele?.ID}>
													{ele?.maxSelections === 1
														? `${ele?.option?.name}* (Require 1 option)`
														: ele?.maxSelections === 1
														? `${ele?.option?.name}* (Choose at most ${ele?.maxSelections} option)`
														: `${ele?.option?.name}`}
												</div>
												{ele?.maxSelections >= 1
													? customRadio(
															ele?.option.optionItem,
															ele?.option,
															i,
															ele?.menuItemOptionItem,
															//extra
															control,
															optionErr,
															register,
															selectedMenu,
															watch,
													  )
													: customCheckBox(
															ele?.option.optionItem,
															ele?.option,
															i,
															ele?.menuItemOptionItem,
															//extra
															optionErr,
															register,
															selectedMenu,
															setValue,
															unregister,
															watch,
															clearErrors,
													  )}
											</div>
											{/* <div>
                                                <TextField
                                                    InputProps={{ disableUnderline: true }}
                                                    disabled
                                                    style={{ marginTop: '-35px' }}
                                                    helperText={
                                                        optionErr ? 'Must Select atleast 1 option' : null
                                                    }
                                                    error={optionErr}
                                                />
                                            </div> */}
										</>
									))}
									<div className="mdDesc" style={{ padding: '10px 0px' }}>
										{'Special Request'}
									</div>
									<>
										{
											<VoiceTextField
												name="specialRequest"
												mounted={true}
												label="We will try our best to fulfill your request..."
												fullWidth
												// margin="normal"
												variant="outlined"
												size="small"
												autoComplete="off"
												multiline={true}
												required
												record={record}
												setRecord={setRecord}
												setValue={setValue}
												audioInput={audioInput}
												setAudioInput={setAudioInput}
												openDialog={editDialog}
												customOnchange
												register={register}
												control={control}
												clearErrors={clearErrors}
												handleCustomOnChange={e => {
													setAudioInput(e?.target?.value);
												}}
											/>
										}
									</>
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setEditDialog(false);
										setQuantity(selectedMenu?.quantity);
										setTakeAwayStatus(false);
										setOptionErr(false);
										setAudioInput('');
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: e => {
										setIsSubmitting(true);
										if (selectedMenu?.menuItem?.menuItemOptions?.length > 0) {
											if (!checkMenuOptionError()?.includes(false)) {
												!isSubmitting && handleSubmit(updateItem)();
											} else {
												setOptionErr(true);
											}
										} else {
											!isSubmitting && handleSubmit(updateItem)();
										}
										// handleSubmit(updateItem)();
										e.stopPropagation();
                    setAudioInput('');
									},
									color:
										orderItemWithOptionsLoading === true
											? 'inherit'
											: 'primary',
									disabled: orderItemWithOptionsLoading === true ? true : false,
									variant: 'contained',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const SetMenuDialogSummary = (props: any) => {
	const {
		changeOrderQuantity,
		control,
		editSetMenuDialog,
		handleSubmit,
		quantity,
		register,
		selectedMenu,
		selectedOrder,
		setEditSetMenuDialog,
		setOptionErr,
		setQuantity,
		setTakeAwayStatus,
		setValue,
		unregister,
		updateSetMenuItem,
		watch,
		clearErrors,
		errors,
		mode,
		checkSetMenuOptionError,
		isSubmitting,
		setIsSubmitting,
		isDesktop,
		getRoundedQty,
		currencyCode = '',
		orderItemWithSetMenuOptionsLoading,
	} = props;
	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={editSetMenuDialog}
				onClose={() => {
					setEditSetMenuDialog(false);
					setQuantity(selectedMenu?.quantity);
					setTakeAwayStatus(false);
					setIsSubmitting(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{mode === 'takeAway'
												? `Takeaway ${selectedOrder?.docNo}`
												: `${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="title flex-space infoline">
										{selectedMenu?.menuItem?.name}
									</div>
									<div className="title rightText infoline">
										{`${currencyCode}${amtStr(selectedMenu?.unitPrice)}`}
									</div>
								</div>
							</div>
						),
						// children: (
						// 	<>
						// 		<ListItem
						// 			style={{ backgroundColor: '#c7d6ff' }}
						// 			className="remove-padding"
						// 		>
						// 			<ListItemText
						// 				primary={
						// 					<>
						// 						<div
						// 							className="smTitle highlight-text"
						// 							style={{ color: '#ff9800' }}
						// 						>
						// 							<span
						// 								style={{ marginRight: '15px' }}
						// 							>{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber} ${selectedOrder?.docNo}`}</span>
						// 						</div>
						// 					</>
						// 				}
						// 				secondary={
						// 					<>
						// 						<span className="highlight-text flex-space">
						// 							{selectedMenu?.menuItem?.name}
						// 						</span>
						// 						<span className="highlight-text">
						// 							{`$${amtStr(selectedMenu?.unitPrice)}`}
						// 						</span>
						// 					</>
						// 				}
						// 			/>
						// 		</ListItem>
						// 		<hr className="line-subheader" />
						// 	</>
						// ),
					},
					body: () => (
						<>
							<MultipleItemsCarousel>
								<div className="img-container">
									<img
										src={selectedMenu?.menuItem?.attachments?.fileURL}
										alt=""
									/>
								</div>
							</MultipleItemsCarousel>
							<div className="orig-padding" style={{ marginTop: '10px' }}>
								<span className="mdDesc text-noflow">
									{selectedMenu?.menuItem?.description}
								</span>
								<hr className="line-description" />

								<div>
									{/* QUANTITY */}
									{/* QUANTITY */}

									{(!selectedMenu?.menuItem?.isByWeight ||
										(!selectedMenu?.menuItem?.isByWeight &&
											selectedMenu?.menuItem?.isOpenItem)) && (
										<span>
											{/* {set menu} */}
											<IndeterminateCheckBoxIcon
												className="order-minus"
												onClick={() => changeOrderQuantity('reduce')}
											/>
											{/* <span className="order-quantity"> {quantity} </span> */}

											<Controller
												render={({ onChange, onBlur, value }) => (
													<NumberFormat
														customInput={TextField}
														autoComplete="off"
														isNumericString
														thousandSeparator
														fullWidth={false}
														margin="dense"
														className="quantity-input"
														variant="standard"
														InputProps={{
															disableUnderline: true,
														}}
														onValueChange={(value: any) => {
															setQuantity(getRoundedQty(value?.floatValue));
															setValue('qty', getRoundedQty(value?.floatValue));
														}}
														style={{
															width: isDesktop ? '25%' : '45%',
															borderRadius: '8px',
															border: 'solid',
															borderColor: 'orange',
														}}
														// variant="outlined"
														defaultValue={quantity}
														value={watch('qty')}
													/>
												)}
												name="qty"
												control={control}
												ref={register}
											/>
											<AddBox
												className="order-add"
												onClick={() => changeOrderQuantity('add')}
											/>
										</span>
									)}
									{mode !== 'takeAway' && (
										<span
											style={{
												float: 'right',
												position: 'relative',
												paddingTop: '10px',
											}}
										>
											<Checkbox
												value={selectedMenu?.menuItem?.ID}
												onChange={e =>
													e.target.checked
														? setTakeAwayStatus(true)
														: setTakeAwayStatus(false)
												}
												color="primary"
												style={{ padding: '5px' }}
												inputProps={{
													'aria-label': 'secondary checkbox',
												}}
												ref={register}
											/>

											<span className="mdDesc">Take Away</span>
										</span>
									)}

									<div style={{ padding: '10px 0px' }}>
										<div
											style={{ paddingBottom: '5px' }}
											className="desc"
										>{`Included in ${selectedMenu?.menuItem?.name}`}</div>
										{selectedMenu?.menuItem?.setMenuOption
											?.filter(option => option?.maxSelections === 0)[0]
											?.setMenuOptionItem?.map((optionItem, i) => (
												<div
													style={{ paddingBottom: '5px' }}
													className="xsTitle"
												>{`${optionItem?.menuItem?.name} x${optionItem?.quantity}`}</div>
											))}
									</div>
									{selectedMenu?.menuItem?.setMenuOption
										?.filter(
											setMenuOption => setMenuOption?.maxSelections !== 0,
										)
										?.map((setMenuOption, i) => (
											<div className="option-radio">
												<div className="mdDesc" key={setMenuOption?.ID}>
													{setMenuOption?.maxSelections === 1
														? `${setMenuOption?.name}* (Require 1 option)`
														: setMenuOption?.maxSelections === 1
														? `${setMenuOption?.name}* (Choose at most ${setMenuOption?.maxSelections} option)`
														: `${setMenuOption?.name}`}
												</div>
												{setMenuOption?.maxSelections === 1
													? customSetMenuRadio(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															//extra
															control,
															register,
															watch,
													  )
													: setMenuOption?.maxSelections > 1
													? customSetMenuCheckBox(
															setMenuOption?.setMenuOptionItem,
															setMenuOption,
															i + 1,
															//extra
															register,
															selectedMenu,
															setValue,
															unregister,
													  )
													: null}
											</div>
										))}
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setEditSetMenuDialog(false);
										setQuantity(selectedMenu?.quantity);
										setTakeAwayStatus(false);
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: e => {
										setIsSubmitting(true);
										if (selectedMenu?.mnenuItem?.setMenuOption?.length > 0) {
											if (
												!checkSetMenuOptionError()?.includes(false)
												// selectedMenu?.isByWeight &&
												// watch(`option`)?.length ===
												// 	selectedMenu?.menuItem?.setMenuOption?.length
											) {
												!isSubmitting && handleSubmit(updateSetMenuItem)();
											} else {
												setOptionErr(true);
											}
										} else {
											!isSubmitting && handleSubmit(updateSetMenuItem)();
										}
										// if (quantity !== 0) {
										// handleSubmit(updateSetMenuItem)();
										// }
										e.stopPropagation();
									},
									// color: quantity !== 0 ? 'primary' : 'inherit',
									variant: 'contained',
									type: 'submit',
									color:
										orderItemWithSetMenuOptionsLoading === true
											? 'inherit'
											: 'primary',
									disabled:
										orderItemWithSetMenuOptionsLoading === true ? true : false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
