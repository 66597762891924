import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRoundingPolicyDocument,
	MethodType,
	PosPermission,
	TransactionType,
	useGetClassificationListingQuery,
	useGetEInvoicePolicyQuery,
	useGetEInvoiceSubQuery,
	useGetRoundingPolicyQuery,
	useSubmitEInvoicePolicyMutation,
	useSubmitRoundingPolicyMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { useFilterOption } from 'helpers/hooks/useFilterOption';

export interface EInvoicePolicyProps {
	eInvoiceClassificationCodeID: string;
	classificationCode: string;
	description: string;
}

export const EInvoicePolicyForm = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const outletID = localStorage.getItem('latestOutlet');

	const { handlePermDisabled } = usePermissionChecker();
	const [openExitDialog, setOpenExitDialog] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [isTouch, setIsTouch] = useState(false);
	const [display, setDisplay] = useState(null);
	const [msicDescA, setMsicDescA] = useState(null);

	const {
		data: { getEInvoiceSub } = { getEInvoiceSub: [] },
		loading: getEInvoiceSubLoading,
	} = useGetEInvoiceSubQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const {
		data: { getEInvoicePolicy } = { getEInvoicePolicy: [] },
		loading: getEInvoicePolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetEInvoicePolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const {
		loading: getClassificationLLoading,
		data: { getClassificationListing } = { getClassificationListing: [] },
	} = useGetClassificationListingQuery({
		fetchPolicy: 'network-only',
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [submitEInvoicePolicy] = useSubmitEInvoicePolicyMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			history.push({
				pathname: `/menu/outlet-app/general-setting`,
				state: { success: true, msgMode: 'create' },
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const rememberme = JSON.parse(localStorage.getItem('rememberme'));

	const roundingPolicy: any = getEInvoicePolicy;

	const { control, register, handleSubmit, errors, watch, setValue } = useForm<
		EInvoicePolicyProps
	>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	const onSubmit = (data: any) => {
		submitEInvoicePolicy({
			variables: {
				input: {
					eInvoiceClassificationCodeID: selectedOption?.id,
					classificationCode: selectedOption?.classificationCode,
					description: selectedOption?.description,
				},
			},
			refetchQueries: [
				{
					query: GetRoundingPolicyDocument,
					variables: { accountID: user?.accountID },
				},
			],
		});
	};

	useEffect(() => {
		setDisplay(
			getClassificationListing.find(
				x => x?.classificationCode === getEInvoicePolicy[0]?.classificationCode,
			),
		);
	}, [getEInvoicePolicy, getClassificationListing]);

	useEffect(() => {
		setMsicDescA(selectedOption?.description ?? display?.description);
		setValue(
			'description',
			selectedOption?.description ?? display?.description,
		);
	}, [selectedOption, display, getEInvoicePolicy]);

	const { filterOptions } = useFilterOption();

	console?.log(isTouch, display, selectedOption, 'selectedOption');

	return (
		<>
			{getClassificationLLoading && <Loading />}
			{getEInvoicePolicyLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Setting' },
					{ name: 'e-Invoice Policy', current: true },
				]}
				rightRouteSegments={[{ name: 'Edit' }]}
			/>
			<ContentWrapper>
				{!getEInvoicePolicyLoading && (
					<CardContents devFullWidth={true}>
						<div className="content-container">
							{getEInvoiceSub?.length > 0 && (
								<>
									<Autocomplete
										options={getClassificationListing}
										fullWidth
										getOptionLabel={option => option?.classificationCode || ''}
										filterOptions={(options, state) =>
											filterOptions(options, state, [
												'classificationCode',
												'description',
											])
										}
										value={
											isTouch === false ? getEInvoicePolicy[0] : selectedOption
										}
										onChange={(event, value) => {
											setIsTouch(true);
											setSelectedOption(value);
										}}
										renderInput={params => (
											<TextField
												{...params}
												label="e-Invoice Classification"
												variant="standard"
												name="classificationCode"
												helperText={
													!selectedOption?.id && isTouch === true
														? 'e-Invoice Classification is required'
														: null
												}
												error={
													!selectedOption?.id && isTouch === true ? true : false
												}
											/>
										)}
										renderOption={option => (
											<div>
												<div className="fw-normal" style={{ fontSize: '13px' }}>
													<b>{option.classificationCode}</b>
												</div>
												<div className="fw-normal" style={{ fontSize: '13px' }}>
													{option.description ? option.description : '-'}{' '}
												</div>
											</div>
										)}
									/>

									{/* 
									<Controller
										as={TextField}
										name="description"
										label="Classification Description"
										defaultValue={display?.description}
										autoComplete="off"
										multiline={true}
										fullWidth
										value={msicDescA}
										disabled
										ref={register}
										InputLabelProps={
											msicDescA || display ? { shrink: true } : {}
										}
										control={control}
										margin="dense"
									></Controller> */}
								</>
							)}
						</div>
					</CardContents>
				)}
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						disabled: handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.GeneralSettingRoundingPolicyUpdate,
						}),
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/general-setting`}
			/>
		</>
	);
};
