import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import BigNumber from 'bignumber.js';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetTableDocument,
	GetTableSubmenuTableDocument,
	OrderItemStatus,
	OrderStatus,
	PosPermission,
	useCloseTableStatusMutation,
	useGetBillSettlementOrderItemLazyQuery,
	useGetHomeTableSummaryLazyQuery,
	useGetOrderQuery,
	useGetOutletQuery,
	useGetPatronQuery,
	useGetRoundingPolicyQuery,
	useGetTableSubmenuTableQuery,
	useRefreshTableSubscription,
	useTransferItemByQuantityMutation,
	useTransferTableMutation,
	useUpdateOrderMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { amtNumStr, amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import { WSclient } from 'POSClient';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	AddEditOrderIcon,
	BillItemDiscountIcon,
	BillSettlementIcon,
	CloseTableIcon,
	ItemTransferIcon,
	OpenNewBillIcon,
	PersonIcon,
	TransferTableIcon,
} from '../../../../assets';
import { CloseTableDialog } from './CloseTable/CloseTableDialog';
import { EditOrderInfoDialog } from './EditOrderInfo/EditOrderInfoDialog';
import { ItemTransferDialog } from './ItemTransfer/ItemTransferDialog';
import { TransferTableDialog } from './TransferTable/TransferTableDialog';

interface TableFieldProps {
	tableItem: any;
}

interface FooterProps {
	subtotal: number;
	serviceCharge: number;
	tax: number;
	total: number;
}
interface FooterAction {
	type: string;
	info?: any;
	maxQuantity?: number;
}

export const TableSubmenu = (props: any) => {
	useEffect(() => {
		console.log('TableSubmenu');
	}, []);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const { mode } = props;
	const history = useHistory();
	const location = useLocation();
	const { pathname } = useLocation();

	const { [`outletID`]: outletID, tableID, orderID }: any = useParams<
		Record<string, any>
	>();

	const [editOrderInfo, setEditOrderInfo] = useState(false);
	const [closeTable, setCloseTable] = useState(false);
	const [itemTransfer, setItemTransfer] = useState(false);
	const [orderItemIDs, setOrderItemIDs] = useState([]);
	const [transferTable, setTransferTable] = useState(false);
	const [closeTableDialog, setOpenForClOSEDialog] = useState(false);
	const [selectedTable, setSelectedTable] = useState(null);
	const [changeTableID, setTableID] = useState({
		tableNumber: '',
		prefix: '',
	});

	const [billItem, setBillItem] = useState<Set<any>>(new Set());
	const [selectedOrderItems, setSelectedOrderItems] = useState({});
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [negOrderItem, setNegOrderItem] = useState({});

	const { handlePermHidden } = usePermissionChecker();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const editData = location?.state as any;

	//TRANSFER ITEM USE FORM
	//TRANSFER ITEM USE FORM
	//TRANSFER ITEM USE FORM

	const {
		called: orderCalled,
		loading: orderLoading,
		error: orderError,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: { outletID, tableID: tableID, status: OrderStatus.Open },
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: () => {
			loadHomeTableSummary({
				variables: {
					tableID: tableID,
				},
			});
		},
	});

	const {
		data: { getTable } = { getTable: [] },
		loading: getTableLoading,
		called: getTableCalled,
		fetchMore,
	} = useGetTableSubmenuTableQuery({
		fetchPolicy: 'network-only',
		variables: {
			// ID: tableID,
			outletID: outletID,
			orderByAsc: 'sequence',
			isActive: true,
		},
		onCompleted: ({ getTable }) => {
			setSelectedTable(getTable?.filter(tab => tab?.ID === tableID)[0]);
		},
	});

	//subscription //subscription //subscription
	const {
		loading: refreshTableLoading,
		data: { refreshTable } = { refreshTable: {} as any },
	} = useRefreshTableSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	useEffect(() => {
		if (refreshTable?.ID) {
			fetchMore({
				query: GetTableSubmenuTableDocument,
				variables: {
					outletID: outletID,
					orderByAsc: 'sequence',
					isActive: true,
				},
			});
		}
	}, [refreshTable?.ID]);

	const {
		data: { getPatron } = { getPatron: [] },
		loading: getPatronLoading,
	} = useGetPatronQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, isActive: true },
	});

	const [
		loadHomeTableSummary,
		{
			called: homeTableSummaryCalled,
			loading: homeTableSummaryLoading,
			data: homeTableSummaryData,
		},
	] = useGetHomeTableSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	// useEffect(() => {
	// 	if (!homeTableSummaryCalled) {
	// 		loadHomeTableSummary({
	// 			variables: {
	// 				tableID: tableID,
	// 			},
	// 		});
	// 	}
	// }, [homeTableSummaryCalled]);

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	function generateRoundingAmount(
		totalFooter: number,
		roundingPolicyAmount: number,
	): number {
		const tempCents = totalFooter - Math.floor(totalFooter); // 16.24 - 16.00 = 0.24

		const cents = Math.round((tempCents + Number.EPSILON) * 100) / 100; // 0.24

		const roundingMulti = Math.round(
			(cents * 100) / (roundingPolicyAmount! * 100), // (0.24 * 100) / (0.05 * 100) = 24 / 5 = Math.round(4.8) = 5
		);

		const roundedAmount =
			getRoundingPolicy[0]?.inUse === true
				? Math.floor(totalFooter) + roundingPolicyAmount! * roundingMulti
				: totalFooter; // 16.00 + 0.05 * 5 = 16.

		const roundingAmount = (totalFooter - roundedAmount) * -1; // 16.24 - 16.25 = 0.01

		return Number(roundingAmount.toFixed(2));
	}

	const [updateOrder, { loading: updateOrderLoading }] = useUpdateOrderMutation(
		{
			onError: error => {
				console.log('Suberror', error);
				setOpenSnackBar(true);
				setSnackBarMsg(error.message);
				if (error.message.includes('This table')) {
					history.push(`/menu/outlet-app/${outletID}/table/grid`);
				}
			},
			onCompleted: data => {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.updateRecord());
			},
		},
	);

	const [
		orderTransferItemByQuantity,
		{ data: orderTransferItemByQuantityData, loading: transferItemLoading },
	] = useTransferItemByQuantityMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			}
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.transferRecord());
		},
	});

	const [
		changeTable,
		{ data: transferTableData, loading: transferTableLoading },
	] = useTransferTableMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			history.push(`/menu/outlet-app/${outletID}/table/grid`);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.transferRecord());
		},
	});

	const [
		closeTableStatus,
		{ data: createOrder, loading: closeTableLoading },
	] = useCloseTableStatusMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg('Failed to close table');
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg('Closed table successfully');
			history.push({
				pathname: `/menu/outlet-app/${outletID}/table/grid`,
				state: { success: true, msgMode: 'create' },
			});
		},
	});

	////////CREATE BILL WHEN ORDER HAS OC DISCOUNT FOR CLOSE TABLE///////////

	const [itemArray, setItemArray] = useState([]);
	const initialState: FooterProps = {
		subtotal: 0,
		serviceCharge: 0,
		tax: 0,
		total: 0,
	};
	const reducer: Reducer<FooterProps, FooterAction> = (state, action) => {
		const subtotalAmount = action?.info?.totalAmount / action?.maxQuantity;

		const serviceChargeAmount =
			action?.info?.totalServiceCharge / action?.maxQuantity;

		const taxAmount = action?.info?.totalTax / action?.maxQuantity;

		const totalAmount = action?.info?.totalAmount / action?.maxQuantity;

		switch (action.type) {
			case 'remove':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(totalAmount)
						.minus(serviceChargeAmount)
						.minus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'add':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(subtotalAmount)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(serviceChargeAmount)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(totalAmount)
						.plus(serviceChargeAmount)
						.plus(taxAmount)
						.decimalPlaces(2)
						.toNumber(),
				};
			case 'all':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),

					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			//Handling for when a single item is unchecked

			case 'addItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.plus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.plus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.plus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.plus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			case 'removeItem':
				return {
					...state,
					subtotal: new BigNumber(state?.subtotal)
						.minus(action?.info?.subtotal)
						.decimalPlaces(2)
						.toNumber(),
					serviceCharge: new BigNumber(state?.serviceCharge)
						.minus(action?.info?.serviceCharge)
						.decimalPlaces(2)
						.toNumber(),

					tax: new BigNumber(state?.tax)
						.minus(action?.info?.tax)
						.decimalPlaces(2)
						.toNumber(),

					total: new BigNumber(state?.total)
						.minus(action?.info?.total)
						.decimalPlaces(2)
						.toNumber(),
				};

			default:
				return initialState;
		}
	};
	const [footerState, dispatch] = useReducer(reducer, initialState);

	const [
		loadOrderItem,
		{
			data: { getBillSettlementOrderItem } = { getBillSettlementOrderItem: [] },
			loading: getBillSettlementOrderItemLoading,
		},
	] = useGetBillSettlementOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getBillSettlementOrderItem }) => {
			let unpaidOrderItem = getBillSettlementOrderItem?.filter(
				item => item?.billID === null,
			);

			let temp = {};
			unpaidOrderItem?.map(el => {
				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
						};
					}
				}
			});

			setNegOrderItem({ ...temp });

			handleCheckboxAll(
				true,
				unpaidOrderItem,
				// unpaidOrderItem?.filter(v => {
				// 	return v?.quantity - (temp[v?.ID] ?? 0) > 0;
				// }),
				temp,
			);

			setItemArray(
				unpaidOrderItem?.filter(
					list2 => list2.quantity - (temp?.[list2?.ID]?.quantity ?? 0) > 0,
				),
			);
		},
	});

	const getOrderID = getOrder?.filter(
		x => x?.table?.orderInfo?.totalAmount === 0,
	);

	const billInputArr = () => {
		let array: any[] = [];
		getOrderID?.map(x => {
			array.push({
				orderID: x?.ID,
				outletID: x?.outletID,
				orderItemIDs: x?.orderItem?.map(x => x?.ID),

				// orderItem: {
				//   ID: x?.orderItem?.map(x => x?.ID),
				//   name: x?.orderItem?.map(x => x?.name)
				// }
			});
		});
		return array;
	};

	const billInput = billInputArr();

	// const billInput = {
	// 	orderID: getOrderID[0]?.ID,
	// 	outletID: outletID,
	// };

	const getSelectedOrderItemIDs = () => {
		let temp = Object.keys(selectedOrderItems).filter(
			v => selectedOrderItems[v]?.showQuantity === true,
		);

		return temp?.map(x => {
			return {
				orderItemID: x,
				paymentQuantity: selectedOrderItems[x].paymentQuantity,
			};
		});
	};

	const handleCheckboxAll = (e: any, nArr?: any[], tempVariable?: any) => {
		//calculations
		let subtotalFooter = 0,
			serviceChargeFooter = 0,
			taxFooter = 0,
			totalFooter = 0;

		let tempSet = billItem;

		if (e?.target?.checked || e === true) {
			nArr?.map(v => {
				//Checkbox logic
				selectedOrderItems[v.ID] = {
					showQuantity: true,
					paymentQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
					maxQuantity:
						v?.quantity -
						(negOrderItem?.[v?.ID]?.quantity ??
							tempVariable[v?.ID]?.quantity ??
							0),
				};

				//Calculations
				//Subtotal
				subtotalFooter += v?.totalInfo?.totalAmount;
				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalAmount,

				//Service Footer
				serviceChargeFooter += v?.totalInfo?.totalServiceCharge;
				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalServiceCharge,
				//Tax footer

				taxFooter += v?.totalInfo?.totalTax;
				// * ((v?.quantity - (tempVariable[v?.ID] ?? 0)) / v?.quantity) ||
				// 	v?.totalInfo?.totalTax,
				tempSet.add(v.ID);
			});
			totalFooter = subtotalFooter + serviceChargeFooter + taxFooter;

			setBillItem(new Set(Array.from(tempSet)));

			dispatch({
				type: 'all',
				info: {
					subtotal: subtotalFooter,
					serviceCharge: serviceChargeFooter,
					tax: taxFooter,
					total: totalFooter,
				},
			});
		} else {
			setSelectedOrderItems({});
			setBillItem(new Set());

			dispatch({
				type: 'reset',
			});
		}
	};

	useEffect(() => {
		if (!orderLoading) {
			if (getOrder?.length !== 0) {
				setSelectedOrder(getOrder[0]);
				loadOrderItem({
					variables: {
						orderID: getOrder[0]?.ID,
					},
				});
			}
		}
	}, [orderLoading]);

	// console.log(selectedOrderItems, 'selectedOrderItems');

	///////////////////////////////////////////////////////////////////////////

	//SUBMENU
	//SUBMENU
	//SUBMENU

	const orderCount = homeTableSummaryData?.orderCount;
	const orderItemCount = homeTableSummaryData?.orderItemCount?.orderItem;
	const orderItemAmount = homeTableSummaryData?.orderItemCount?.totalAmount;
	const currentTable = homeTableSummaryData?.newestTable?.nowTable;
	const itemAvailableCount =
		homeTableSummaryData?.itemTransferCount?.itemAvailable;
	const discountAmount =
		amtNumStr(
			Number(
				homeTableSummaryData?.billItemDiscount?.discountAmount ?? 0,
			).toFixed(2),
		) ?? 0;
	const billSettlementCount = homeTableSummaryData?.billSettlement?.billNumber;
	const billSettlementAmount = homeTableSummaryData?.billSettlement?.billAmount;
	const closeAmount = homeTableSummaryData?.close?.closeAmount;

	const roundingAmount =
		getRoundingPolicy[0]?.inUse === true
			? generateRoundingAmount(
					homeTableSummaryData?.close?.closeAmount,
					getRoundingPolicy[0]?.smallestDenomination,
			  )
			: 0;

	let outletList;

	let confirmedOrderItem = getOrder[0]?.orderItem?.filter(x => {
		return (
			(x?.status === OrderItemStatus.Confirmed ||
				x?.status === OrderItemStatus.Voided ||
				x?.status === OrderItemStatus.TransferIn ||
				x?.status === OrderItemStatus.TransferOut) &&
			x?.billID === null
		);
	});

	outletList = [
		{
			icon: OpenNewBillIcon,
			title: `New Order`,
			label1: `Current Bill Opened:`,
			data1: orderCount,
			action: () => {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/table/grid/${
						selectedTable?.ID
					}/qr-code/${null}`,
					state: selectedTable,
				});
				// if (getOutlet[0]?.customerPolicy?.selfOrder) {
				// 	history.push({
				// 		pathname: `/menu/outlet-app/${outletID}/table/grid/${selectedTable?.ID}/qr-code`,
				// 	});
				// } else if (!getOutlet[0]?.customerPolicy?.selfOrder) {
				// 	history.push({
				// 		pathname: `/menu/outlet-app/${outletID}/table/grid/${
				// 			selectedTable?.ID
				// 		}/qr-code/${null}`,
				// 		state: selectedTable,
				// 	});
				// }
			},
			// history.push({
			// 	pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/qr-code`,
			// }),
			permission: PosPermission.HomeTableLayoutNewOrderCreate,
		},
		{
			icon: AddEditOrderIcon,
			title: `Add & Edit Order Item`,
			label1: `Total Number of Items:`,
			label2: '',
			data1: orderItemCount,
			data2: `${getOutlet[0]?.currencyCode ?? ''}${amtStr(orderItemAmount)}`,
			disabled: orderCount === 0,
			action: () => {
				if (
					confirmedOrderItem?.length === 0 ||
					confirmedOrderItem?.length === null
				) {
					history.push(
						`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${getOrder[0]?.ID}`,
					);
				} else {
					history.push(
						`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${getOrder[0]?.ID}/confirm-order`,
					);
				}
			},
			permission: PosPermission.HomeTableLayoutAddEditOrderItemView,
		},

		{
			icon: PersonIcon,
			title: `Edit Order Info`,
			// label: homeTableSummaryData?.editOrderInfo?.currentOrder?.map((mp) => {
			// 	return mp?.orderNo;
			// }),

			label1:
				homeTableSummaryData?.editOrderInfo?.currentOrder[0]?.orderNo + ':',
			label2:
				homeTableSummaryData?.editOrderInfo?.currentOrder[1]?.orderNo + ':',
			label3:
				homeTableSummaryData?.editOrderInfo?.currentOrder[2]?.orderNo + ':',
			label4:
				homeTableSummaryData?.editOrderInfo?.currentOrder[3]?.orderNo + ':',
			label5:
				homeTableSummaryData?.editOrderInfo?.currentOrder[4]?.orderNo + ':',
			label6:
				homeTableSummaryData?.editOrderInfo?.currentOrder[5]?.orderNo + ':',
			label7:
				homeTableSummaryData?.editOrderInfo?.currentOrder[6]?.orderNo + ':',
			data1: homeTableSummaryData?.editOrderInfo?.currentOrder[0]?.patronClass,
			data2: homeTableSummaryData?.editOrderInfo?.currentOrder[1]?.patronClass,
			data3: homeTableSummaryData?.editOrderInfo?.currentOrder[2]?.patronClass,
			data4: homeTableSummaryData?.editOrderInfo?.currentOrder[3]?.patronClass,
			data5: homeTableSummaryData?.editOrderInfo?.currentOrder[4]?.patronClass,
			data6: homeTableSummaryData?.editOrderInfo?.currentOrder[5]?.patronClass,
			data7: homeTableSummaryData?.editOrderInfo?.currentOrder[6]?.patronClass,

			disabled: orderCount === 0,
			action: () => setEditOrderInfo(true),
			permission: PosPermission.HomeTableLayoutEditOrderInfoView,
		},
		{
			icon: TransferTableIcon,
			title: `Change/Transfer Table`,
			label1: 'Current Table:',
			data1: currentTable,
			disabled: orderCount === 0,
			// action: () => setTransferTable(true),
			action: () => setTransferTable(true),
			permission: PosPermission.HomeTableLayoutChangeTransferTableView,
		},
		{
			icon: ItemTransferIcon,
			title: `Item Transfer`,
			label1: `No of Item Transferred : `,
			data1: itemAvailableCount,
			disabled: orderCount === 0 || closeAmount === 0,
			action: () => setItemTransfer(true),
			permission: PosPermission.HomeTableLayoutItemTransferView,
		},
		{
			icon: BillItemDiscountIcon,
			title: `Bill/Item Discount (New & Void)`,
			label1: `Discount given :`,
			// label2: 'Voided :',
			data1: discountAmount,
			disabled: orderCount === 0,
			// data2: '0',
			action: () =>
				history.push(
					`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${getOrder[0]?.ID}/item-discount`,
				),
			permission: PosPermission.HomeTableLayoutBillItemDiscountView,
		},
		{
			icon: BillSettlementIcon,
			title: `Bill Settlement`,
			label1: `No of Open Bill :`,
			label2: '',
			data1: orderCount,
			data2: `${getOutlet[0]?.currencyCode ?? ''}${amtStr(
				orderItemAmount + roundingAmount,
			)}`,
			disabled: orderCount === 0,
			action: () =>
				history.push(
					`/menu/outlet-app/${outletID}/table/grid/${tableID}/bill-settlement`,
				),
			permission: PosPermission.HomeTableLayoutBillSettlementView,
		},
	];

	let outletlist1;
	outletlist1 = [
		{
			icon: CloseTableIcon,
			title: `Close Table`,
			label1: `O/S Bill Amount:`,
			data1: `${getOutlet[0]?.currencyCode ?? ''}${amtStr(closeAmount)}`,
			disabled: closeAmount > 0,
			action: () =>
				closeAmount <= 0 || closeAmount === undefined
					? setOpenForClOSEDialog(true)
					: null,
			permission: PosPermission.HomeTableLayoutCloseTableView,
		},
	];

	// console.log(editData, 'editData');

	return (
		<>
			{orderLoading && <Loading />}
			{outletLoading && <Loading />}
			{getTableLoading && <Loading />}
			{getPatronLoading && <Loading />}
			{homeTableSummaryLoading && <Loading />}
			{updateOrderLoading && <Loading />}
			{transferItemLoading && <Loading />}
			{transferTableLoading && <Loading />}
			{closeTableLoading && <Loading />}
			{/* {!homeTableSummaryLoading ? (
				<> */}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid`,
					})
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedTable?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[
					{ name: 'Table Layout' },
					{
						name:
							'Table ' +
							(selectedTable
								? `${selectedTable?.prefix}${selectedTable?.tableNumber}`
								: ``),
						current: true,
					},
				]}
				rightRouteSegments={[
					{
						name: `${getOutlet[0]?.currencyCode ?? ''}${amtStr(
							homeTableSummaryData?.orderItemCount?.totalAmount,
						)}`,
						// style: {
						// 	f: '11px',
						// 	    fontFamily: ['Poppins', 'sans-serif'].join(',') + ' !important',

						// },
					},
				]}
			/>

			<ContentWrapper>
				<List className="core-list">
					{outletList.map((el, index) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el.permission,
							})
						)
							return (
								<>
									<ListItem
										key={index}
										onClick={() => {
											el?.action();
											// !el?.disabled && el?.action();
										}}
										disabled={el?.disabled}
									>
										<ListItemIcon>
											<img
												className="icon-svg"
												style={{ paddingRight: '10px' }}
												src={el.icon}
												alt="drawer-icon"
											/>
										</ListItemIcon>

										<ListItemText
											primary={
												<>
													<span className="">
														<span
															className=""
															style={{
																fontWeight: 700,
																fontSize: '12px',
																fontFamily:
																	['Poppins', 'sans-serif'].join(',') +
																	' !important',
															}}
														>
															{el.title}
														</span>
													</span>
												</>
											}
											secondary={
												<span
													className=""
													style={{
														fontSize: '11px',
														fontFamily:
															['Poppins', 'sans-serif'].join(',') +
															' !important',
													}}
												>
													{el.data2 ? (
														<>
															<span className="desc">{el.label1} </span>
															<span className="highlight-text">{el.data1}</span>
															<span className="desc"> | </span>
															<span className="desc">{el.label2} </span>
															<span className="highlight-text">{el.data2}</span>
															{el.data3 ? (
																<>
																	<span className="desc"> | </span>
																	<span className="desc">{el.label3} </span>
																	<span className="highlight-text">
																		{el.data3}
																	</span>
																</>
															) : null}
															{el.data4 ? (
																<>
																	<span className="desc"> | </span>
																	<span className="desc">{el.label4} </span>
																	<span className="highlight-text">
																		{el.data4}
																	</span>
																</>
															) : null}
															{el.data5 ? (
																<>
																	<span className="desc"> | </span>
																	<span className="desc">{el.label5} </span>
																	<span className="highlight-text">
																		{el.data5}
																	</span>
																</>
															) : null}
															{el.data6 ? (
																<>
																	<span className="desc"> | </span>
																	<span className="desc">{el.label6} </span>
																	<span className="highlight-text">
																		{el.data6}
																	</span>
																</>
															) : null}
															{el.data7 ? (
																<>
																	<span className="desc"> | </span>
																	<span className="desc">{el.label7} </span>
																	<span className="highlight-text">
																		{el.data7}
																	</span>
																</>
															) : null}
														</>
													) : (
														<>
															<span className="desc">{el.label1} </span>
															<span className="highlight-text">{el.data1}</span>
														</>
													)}
												</span>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													style={{
														color: el?.disabled ? 'darkgrey' : 'currentcolor',
													}}
													onClick={() => !el?.disabled && el?.action()}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
					})}

					{outletlist1.map((el, index) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el.permission,
							})
						)
							return (
								<>
									<ListItem
										key={index}
										disabled={el?.disabled}
										onClick={() => {
											el?.action();
											// !el?.disabled && el?.action();
										}}
									>
										<ListItemIcon>
											<img
												className="icon-svg"
												style={{ paddingRight: '10px' }}
												src={el.icon}
												alt="drawer-icon"
											/>
										</ListItemIcon>

										<ListItemText
											primary={
												<>
													<span className="desc">
														<span
															className="xsTitle"
															style={{
																fontWeight: 700,
																fontSize: '12px',
																fontFamily:
																	['Poppins', 'sans-serif'].join(',') +
																	' !important',
															}}
														>
															{el.title}
														</span>
													</span>
												</>
											}
											secondary={
												<span
													className="desc"
													style={{
														fontSize: '11px',
														fontFamily:
															['Poppins', 'sans-serif'].join(',') +
															' !important',
													}}
												>
													{el.data2 ? (
														<>
															<span className="desc">{el.label1} </span>
															<span className="highlight-text">{el.data1}</span>
															<span className="desc"> | </span>
															<span className="highlight-text">{el.data2}</span>
														</>
													) : (
														<>
															<span className="desc">{el.label1} </span>
															<span className="highlight-text">{el.data1}</span>
														</>
													)}
												</span>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													style={{
														color: el?.disabled ? 'darkgrey' : 'currentcolor',
													}}
													onClick={() => !el?.disabled && el?.action()}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
					})}
				</List>
			</ContentWrapper>
			{/* </>
			) : null} */}

			{editOrderInfo && (
				<EditOrderInfoDialog
					selectedTable={selectedTable}
					editData={editData}
					editOrderInfo={editOrderInfo}
					setEditOrderInfo={setEditOrderInfo}
					outletList={outletList}
					tableID={tableID}
					outletID={outletID}
					orderID={orderID}
					getOrder={getOrder}
					getOutlet={getOutlet}
					getTable={getTable}
					getPatron={getPatron}
					hotelID={getOutlet[0]?.outletChannel?.hotelID}
					updateOrder={updateOrder} // mutation
					updateOrderLoading={updateOrderLoading}
				/>
			)}

			{itemTransfer && (
				<ItemTransferDialog
					selectedTable={selectedTable}
					orderItemIDs={orderItemIDs}
					setOrderItemIDs={setOrderItemIDs}
					itemTransfer={itemTransfer}
					setItemTransfer={setItemTransfer}
					outletList={outletList}
					tableID={tableID}
					outletID={outletID}
					orderID={orderID}
					getOrder={getOrder}
					getTable={getTable}
					orderTransferItemByQuantity={orderTransferItemByQuantity} // mutation
				/>
			)}

			{transferTable && (
				<TransferTableDialog
					editData={editData}
					mode={mode}
					tableID={tableID}
					outletID={outletID}
					transferTable={transferTable}
					setTransferTable={setTransferTable}
					changeTableID={changeTableID}
					setTableID={setTableID}
					getTable={getTable}
					getTableLoading={getTableLoading}
					getTableCalled={getTableCalled}
					getOrder={getOrder}
					selectedTable={selectedTable}
					changeTable={changeTable} // mutation
					transferItemLoading={transferItemLoading}
				/>
			)}

			{closeTableDialog && (
				<CloseTableDialog
					outletID={outletID}
					tableID={tableID}
					history={history}
					closeTableDialog={closeTableDialog}
					setOpenForClOSEDialog={setOpenForClOSEDialog}
					selectedTable={selectedTable}
					closeTableStatus={closeTableStatus}
					billInput={billInput}
					getSelectedOrderItemIDs={getSelectedOrderItemIDs}
					closeTableLoading={closeTableLoading}
				/>
			)}
		</>
	);
};
