import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Grid } from '@material-ui/core';
import React from 'react';
import QRCode from 'qrcode.react';
import { amtStr } from 'helpers/numFormatter';
import BigNumber from 'bignumber.js';
import {
	OrderDiscountStatus,
	OrderItemDiscountStatus,
	PaymentClass,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { format } from 'date-fns';
import * as bxlCommon from '../BixolonJS/bxlcommon.js';
import * as bxlPOS from '../BixolonJS/bxlpos.js';
import { alignment } from './BillReceiptFunctions';

export const BillReceiptEInvoice = props => {
	const {
		outlet,
		data,
		orderItems,
		negOrderItem,
		footerToken,
		discount,
		latestPayment,
		chargeInfo1,
		chargeInfo2,
		totalOptItmPerOrderItem,
		taxByClass,
		hidden,
	} = props;

	const activeOrderDiscount = data?.order?.orderDiscount?.filter(
		y => y?.status === OrderDiscountStatus?.Active,
	)[0];

	const OCENT = discount?.filter(
		x => x?.ID === activeOrderDiscount?.discountID,
	)[0]?.discountType;

	const displayOCENT =
		OCENT === 'OFFICER_CHECK'
			? 'Officer Check'
			: OCENT === 'ENTERTAINMENT'
			? 'Entertainment'
			: OCENT === 'DISCOUNT'
			? 'Discount'
			: 'Promo';

	let roundAmount = 0;
	latestPayment?.map(x => {
		roundAmount += x?.roundingAmount;
	});

	const handleCardType = cardType => {
		switch (cardType) {
			case 'VISA_CARD':
				return 'VISA';
			case 'MASTERCARD':
				return 'MASTERCARD';
			case 'AMERICAN_EXPRESS_CARD':
				return 'AMERICAN EXPRESS';
			case 'DINERS_CARD':
				return 'DINERS';
			case 'JCB_CARD':
				return 'JCB';
			case 'UNIONPAY_CARD':
				return 'UNIONPAY';
			case 'DEBIT_CARD':
				return 'DEBIT';
			default:
				return 'Credit';
		}
	};

	let totalAmount = 0,
		totalSC = 0,
		totalTax = 0;

	taxByClass?.map(x => {
		totalAmount += parseFloat(x?.amount ?? 0);
		totalSC += parseFloat(x?.totalSC ?? 0);
		totalTax += parseFloat(x?.totalTax ?? 0);
	});

	return (
		<>
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '350px',
								fontFamily: ['Poppins', 'sans-serif'].join(',') + ' !important',
						  }
						: {
								marginTop: '0px',
								zIndex: 40,
								position: 'relative',
						  }
				}
				id={hidden ? 'share-receipt' : null}
			>
				<CardContents>
					<span
						className="xsTitle content-wrap full"
						style={{
							textAlign: 'center',
							marginBottom: 'auto',
							marginTop: '10px',
						}}
					>
						{`${outlet?.name} (${outlet?.registrationNo})`}
					</span>

					<span
						className="desc content-wrap full text-noflow"
						style={{ textAlign: 'center', marginBottom: 'auto' }}
					>
						{outlet?.address?.address +
							', ' +
							outlet?.address?.postCode +
							', ' +
							outlet?.address?.city +
							', ' +
							outlet?.address?.state +
							'.'}
						<br />
						Tel No: {outlet?.contactNo}
					</span>

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'center',
							marginBottom: 'auto',
							marginTop: '5px',
						}}
					>
						MSIC Code: {outlet?.companyMsicNo}
						<br />
						SST Registration No: {outlet?.taxRegistrationNo}
					</span>

					<span
						className="xsTitle content-wrap full"
						style={{
							textAlign: 'center',
							marginBottom: 'auto',
							marginTop: '10px',
						}}
					>
						E-INVOICE
					</span>

					<Grid container>
						<Grid item xs={6}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
									marginTop: '10px',
								}}
							>
								{data?.customerInfo?.billTo}
								<br />
								{data?.customerInfo?.address?.address}
								<br />
								{`${data?.customerInfo?.address?.postCode} ${data?.customerInfo?.address?.city}`}
								<br />
								{data?.customerInfo?.address?.state}
								<br />
								{data?.customerInfo?.address?.country}
							</div>
						</Grid>

						<Grid item xs={6}>
							<div
								style={{
									textAlign: 'right',
									marginBottom: 'auto',
									marginTop: '10px',
								}}
							>
								<QRCode
									value={`www.google.com`}
									size={50}
									bgColor={'#ffffff'}
									fgColor={'#000000'}
									level={'L'}
									includeMargin={false}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={3}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
									marginTop: '10px',
								}}
							>
								{`TIN`}
								<br />
								{`ID/ Reg No`}
								<br />
								{`Tel No`}
								<br />
								{`Email`}
							</div>
						</Grid>

						<Grid item xs={9}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
									marginTop: '10px',
								}}
							>
								{`: ${data?.customerInfo?.tin ?? '-'}`}
								<br />
								{`: ${data?.customerInfo?.registrationNo ?? '-'}`}
								<br />
								{`: ${data?.customerInfo?.nricNo ?? '-'}`}
								<br />
								{`: ${data?.customerInfo?.email ?? '-'}`}
							</div>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={3}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: '10px',
									marginTop: '10px',
								}}
							>
								{`UIN`}
								<br />
								{`Doc No`}
								<br />
								{`Ref No`}
								<br />
								{`Date & Time`}
								<br />
								{`Currency`}
							</div>
						</Grid>

						<Grid item xs={9}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
									marginTop: '10px',
								}}
							>
								{`: ${`123456789-2024-1234567` ?? '-'}`}
								<br />
								{`: ${`DR1234` ?? '-'}`}
								<br />
								{`: ${`Not Applicable` ?? '-'}`}
								<br />
								{`: ${format(new Date(), 'dd/MM/yyyy, hh:mm a') ?? '-'}`}
								<br />
								{`: ${outlet?.currencyCode ?? '-'}`}
							</div>
						</Grid>
					</Grid>

					{orderItems?.map(el => {
						let totalOITax = 0;
						el?.orderItemTax?.map(x => {
							totalOITax += x?.taxAmount;
						});

						let sumNegOptDisc = 0;

						if (negOrderItem?.[el?.ID]?.orderItemOptions) {
							for (const optionItem of negOrderItem?.[el?.ID]
								?.orderItemOptions) {
								sumNegOptDisc +=
									optionItem?.orderItemOptionItem?.discountBaseAmount +
									optionItem?.orderItemOptionItem?.itemDiscountTaxAmount +
									optionItem?.orderItemOptionItem?.discountItemTaxAmount;
							}
						}

						const activeOrderItemDiscount = el?.orderItemDiscount?.filter(
							y => y?.status === OrderItemDiscountStatus?.Active,
						)[0];

						let totalOIDTax = 0;
						el?.orderItemDiscountTax?.map(x => {
							totalOIDTax += x?.taxAmount;
						});

						return (
							<>
								<Grid container>
									<Grid item xs={2} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'center',
												marginBottom: 'auto',
											}}
										>
											{el?.menuItem?.major?.eInvClassification}
										</div>
									</Grid>

									<Grid item xs={7} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'left',
												marginBottom: 'auto',
											}}
										>
											{`${el?.openItemName ??
												el?.menuItem?.name} x${el?.quantity -
												(negOrderItem?.[el?.ID]?.quantity ?? 0)}`}
										</div>
									</Grid>

									<Grid item xs={3} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'right',
												marginBottom: 'auto',
											}}
										>
											{`${amtStr(
												new BigNumber(el?.amount)
													.plus(totalOITax)
													.minus(
														(negOrderItem?.[el?.ID]?.amount ?? 0) +
															(negOrderItem?.[el?.ID]?.taxAmount ?? 0),
													)
													.toNumber(),
											)}`}
										</div>
									</Grid>
								</Grid>

								{el?.orderItemOption?.length > 0
									? el?.orderItemOption?.map(oio => (
											<>
												{oio?.orderItemOptionItem?.map(oioi => {
													let totalTax = 0;
													oioi?.orderItemOptionItemTax?.map(x => {
														totalTax += x?.taxAmount;
													});

													return (
														<Grid container>
															<Grid
																item
																xs={2}
																style={{ paddingBottom: '0px' }}
															>
																<div
																	className="desc content-wrap full text-noflow"
																	style={{
																		textAlign: 'center',
																		marginBottom: 'auto',
																	}}
																></div>
															</Grid>
															<Grid
																item
																xs={7}
																style={{ paddingBottom: '0px' }}
															>
																<div
																	className="desc content-wrap full text-noflow"
																	style={{
																		textAlign: 'left',
																		marginBottom: 'auto',
																	}}
																>
																	{oioi?.optionItem?.name}
																</div>
															</Grid>
															<Grid
																item
																xs={3}
																style={{ paddingBottom: '0px' }}
															>
																<div
																	className="desc content-wrap full text-noflow"
																	style={{
																		textAlign: 'right',
																		marginBottom: 'auto',
																	}}
																>
																	{`${
																		oioi?.price === 0
																			? ''
																			: amtStr(
																					Math.abs(
																						(oioi?.basePrice + totalTax) *
																							((el?.quantity -
																								(negOrderItem?.[
																									oio?.orderItemID
																								]?.quantity ?? 0)) /
																								el?.quantity),
																					),
																			  )
																	}`}
																</div>
															</Grid>
														</Grid>
													);
												})}
											</>
									  ))
									: el?.orderItemSetMenuOption?.map(oismo => {
											oismo?.orderItemSetMenuOptionItem?.map(oismoi => {
												return (
													<Grid container>
														<Grid item xs={2} style={{ paddingBottom: '0px' }}>
															<div
																className="desc content-wrap full text-noflow"
																style={{
																	textAlign: 'center',
																	marginBottom: 'auto',
																}}
															></div>
														</Grid>
														<Grid item xs={7} style={{ paddingBottom: '0px' }}>
															<div
																className="desc content-wrap full text-noflow"
																style={{
																	textAlign: 'left',
																	marginBottom: 'auto',
																}}
															>
																{oismoi?.setMenuOptionItem?.menuItem?.name}
															</div>
														</Grid>
														<Grid item xs={3} style={{ paddingBottom: '0px' }}>
															<div
																className="desc content-wrap full text-noflow"
																style={{
																	textAlign: 'right',
																	marginBottom: 'auto',
																}}
															></div>
														</Grid>
													</Grid>
												);
											});
									  })}

								{el?.orderItemDiscount?.filter(
									x => x?.status === OrderItemDiscountStatus.Active,
								)?.length > 0 && (
									<Grid container>
										<Grid item xs={2} style={{ paddingBottom: '0px' }}>
											<div
												className="desc content-wrap full text-noflow"
												style={{
													textAlign: 'center',
													marginBottom: 'auto',
												}}
											></div>
										</Grid>

										<Grid item xs={7} style={{ paddingBottom: '0px' }}>
											<div
												className="desc content-wrap full text-noflow"
												style={{
													textAlign: 'left',
													marginBottom: 'auto',
												}}
											>
												{`Discount: ${
													discount?.filter(
														x => x?.ID === activeOrderItemDiscount?.discountID,
													)[0]?.code
												}`}
											</div>
										</Grid>

										<Grid item xs={3} style={{ paddingBottom: '0px' }}>
											<div
												className="desc content-wrap full text-noflow"
												style={{
													textAlign: 'right',
													marginBottom: 'auto',
												}}
											>
												{`(${amtStr(
													activeOrderItemDiscount?.baseAmount +
														totalOIDTax -
														(negOrderItem?.[el?.ID]?.orderItemDiscBaseAmount ??
															0) -
														(negOrderItem?.[el?.ID]?.orderItemDiscTaxAmount ??
															0) +
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === el?.ID,
														)[0]?.discountBaseAmount ?? 0) +
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === el?.ID,
														)[0]?.itemDiscountTaxAmount ?? 0) -
														(totalOptItmPerOrderItem?.filter(
															x => x?.orderItemID === el?.ID,
														)[0]?.discountItemTaxAmount ?? 0) -
														sumNegOptDisc,
												)})`}
											</div>
										</Grid>
									</Grid>
								)}
							</>
						);
					})}

					<div className="dialog-dynamic-content">
						<div className="session" style={{ margin: '0px', height: '19px' }}>
							<div className="flex session" style={{ marginTop: '10px' }}>
								<div className="desc flex-space">
									<span>{`Sub-Total`}</span>
								</div>
								<div className="desc">{`${amtStr(footerToken?.subtotal)}`}</div>
							</div>
						</div>
					</div>

					<div className="dialog-dynamic-content">
						<div className="session" style={{ margin: '0px', height: '11px' }}>
							<div className="flex session">
								<div className="desc flex-space">
									<span>
										{activeOrderDiscount ? `Discount: ` : `Discount `}
										{data?.order?.orderDiscount?.filter(
											y => y?.status === OrderDiscountStatus?.Active,
										)?.length > 0 &&
											`${
												discount?.filter(
													x => x?.ID === activeOrderDiscount?.discountID,
												)[0]?.code
											}`}
									</span>
								</div>
								<div className="desc">{`(${amtStr(
									footerToken?.discount,
								)})`}</div>
							</div>
						</div>
					</div>

					<div className="dialog-dynamic-content">
						<div className="session" style={{ margin: '0px', height: '11px' }}>
							<div className="flex session">
								<div className="desc flex-space">
									<span>{`Rounding`}</span>
								</div>
								<div className="desc">
									{roundAmount < 0
										? '(' + Math.abs(roundAmount).toFixed(2) + ')'
										: amtStr(roundAmount)}
								</div>
							</div>
						</div>
					</div>

					<div className="dialog-dynamic-content">
						<div className="session" style={{ margin: '0px', height: '11px' }}>
							<div className="flex session">
								<div className="desc flex-space">
									<span>{`Total`}</span>
								</div>
								<div className="desc">
									{`${amtStr(
										new BigNumber(Math.abs(footerToken?.total))
											.plus(roundAmount ?? 0)
											.toNumber(),
									)}`}
								</div>
							</div>
						</div>
					</div>

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'left',
							marginBottom: 'auto',
							marginTop: '15px',
						}}
					>
						Paid by
					</span>

					{latestPayment?.map(el => {
						return (
							<>
								<div className="dialog-dynamic-content">
									<div
										className="session"
										style={{ margin: '0px', height: '11px' }}
									>
										<div className="flex session">
											<div className="desc flex-space">
												<span>
													{el?.paymentClass === PaymentClass.UnsettledBill
														? 'Unsettled Bill'
														: el?.paymentClass === PaymentClass.Credit
														? handleCardType(el?.creditCardType)
														: el?.paymentClass === PaymentClass.Online
														? 'Online Payment'
														: el?.paymentClass === PaymentClass.NonGuest
														? 'Non Guest'
														: el?.paymentClass === PaymentClass.SuspendFolio
														? 'Suspend Folio'
														: el?.paymentClass === PaymentClass.BankTt
														? 'Bank TT'
														: toProperCase(el?.paymentClass)}
												</span>
											</div>
											<div className="desc">
												{el?.paymentClass === PaymentClass.UnsettledBill
													? amtStr(el?.amount)
													: amtStr(el?.receivedAmount)}
											</div>
										</div>
									</div>
								</div>

								{el?.paymentClass === PaymentClass.Debtor ||
								el?.paymentClass === PaymentClass.Room ||
								el?.paymentClass === PaymentClass.NonGuest ||
								el?.paymentClass === PaymentClass.SuspendFolio ||
								el?.paymentClass === PaymentClass.Officer ||
								el?.paymentClass === PaymentClass.Staff ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="desc"
													style={{
														paddingRight: '10px',
													}}
												>
													{chargeInfo1 && <span>{`${chargeInfo1} | `}</span>}
													{chargeInfo2 && chargeInfo2?.length <= 25 ? (
														<span>{`${chargeInfo2}`}</span>
													) : (
														<Grid item xs={12}>
															{`${chargeInfo2}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{el?.paymentClass === PaymentClass.BankTt ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Remark: `}</span>
												</div>
												<div
													className="desc"
													style={{
														paddingRight: '10px',
													}}
												>
													{el?.remark ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{el?.paymentClass === PaymentClass.Voucher ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Code: `}</span>
												</div>
												<div
													className="desc"
													style={{
														paddingRight: '10px',
													}}
												>
													{el?.referenceNo ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{el?.paymentClass === PaymentClass.Others ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Remark: `}</span>
												</div>
												<div
													className="desc"
													style={{
														paddingRight: '10px',
													}}
												>
													{el?.referenceNo ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{el?.paymentClass === PaymentClass.Credit ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Card ref: `}</span>
												</div>
												<div
													className="desc"
													style={{
														paddingRight: '10px',
													}}
												>
													{`****${el?.cardNo?.slice(-4)}` ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{el?.paymentClass === PaymentClass.Cash &&
								el?.changeAmount > 0 ? (
									<div
										className="dialog-dynamic-content"
										style={{ paddingLeft: '10px' }}
									>
										<div
											className="session"
											style={{ margin: '0px', height: '11px' }}
										>
											<div className="flex session">
												<div className="desc flex-space">
													<span>{`Change `}</span>
												</div>
												<div
													className="desc"
													style={{
														fontStyle: 'italic',
													}}
												>
													{amtStr(el?.changeAmount)}
												</div>
											</div>
										</div>
									</div>
								) : null}
							</>
						);
					})}

					{latestPayment?.length === 0 ? (
						<div className="dialog-dynamic-content">
							<div
								className="session"
								style={{ margin: '0px', height: '11px' }}
							>
								<div className="flex session">
									<div className="desc flex-space">
										<span>{displayOCENT}</span>
									</div>
									<div className="desc">{amtStr(0)}</div>
								</div>
							</div>
						</div>
					) : null}

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'left',
							marginTop: '15px',
							marginBottom: '0px',
						}}
					>
						{'Bill Remark:'}
					</span>

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'center',
							marginTop: '5px',
							marginBottom: '3px',
						}}
					>
						{'-------------------------------------------------------'}
					</span>

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'left',
						}}
					>
						{'Tax Summary:'}
					</span>

					<Grid container>
						<Grid item xs={2}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: '0px',
									padding: '3px',
									backgroundColor: '#D3D3D3',
									height: '30px',
								}}
							>
								{`Class`}
							</div>
						</Grid>

						<Grid item xs={2}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: '0px',
									padding: '3px',
									backgroundColor: '#D3D3D3',
									height: '30px',
								}}
							>
								{`Tax Rate`}
							</div>
						</Grid>

						<Grid item xs={3}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: '0px',
									padding: '3px',
									backgroundColor: '#D3D3D3',
								}}
							>
								{`Amount (Excl. Tax)`}
							</div>
						</Grid>

						<Grid item xs={3}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: '0px',
									padding: '3px',
									backgroundColor: '#D3D3D3',
								}}
							>
								{`Service Charge`}
							</div>
						</Grid>

						<Grid item xs={2}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: '0px',
									padding: '3px',
									backgroundColor: '#D3D3D3',
								}}
							>
								{`Tax Amount`}
							</div>
						</Grid>
					</Grid>

					{taxByClass?.map(el => {
						return (
							<>
								<Grid container>
									<Grid item xs={2} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'center',
												marginBottom: 'auto',
											}}
										>
											{el?.class}
										</div>
									</Grid>

									<Grid item xs={2} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'center',
												marginBottom: 'auto',
											}}
										>
											{`${parseInt(el?.rate)}%`}
										</div>
									</Grid>

									<Grid item xs={3} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'right',
												marginBottom: 'auto',
											}}
										>
											{`${amtStr(el?.amount)}`}
										</div>
									</Grid>

									<Grid item xs={3} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'right',
												marginBottom: 'auto',
											}}
										>
											{`${amtStr(el?.totalSC)}`}
										</div>
									</Grid>

									<Grid item xs={2} style={{ paddingBottom: '0px' }}>
										<div
											className="desc content-wrap full text-noflow"
											style={{
												textAlign: 'right',
												marginBottom: 'auto',
											}}
										>
											{`${amtStr(el?.totalTax)}`}
										</div>
									</Grid>
								</Grid>
							</>
						);
					})}

					<Grid container style={{ paddingTop: '5px' }}>
						<Grid item xs={2} style={{ paddingBottom: '0px' }}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: 'auto',
								}}
							></div>
						</Grid>

						<Grid item xs={2} style={{ paddingBottom: '0px' }}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'center',
									marginBottom: 'auto',
								}}
							></div>
						</Grid>

						<Grid
							item
							xs={3}
							style={{
								paddingBottom: '2px',
								borderTop: '1px solid black',
								borderBottom: '1px solid black',
								paddingTop: '2px',
							}}
						>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'right',
									marginBottom: 'auto',
									fontWeight: 'bold',
								}}
							>
								{`${
									amtStr(totalAmount) !== '0.00' ? amtStr(totalAmount) : '-'
								}`}
							</div>
						</Grid>

						<Grid
							item
							xs={3}
							style={{
								paddingBottom: '2px',
								borderTop: '1px solid black',
								borderBottom: '1px solid black',
								paddingTop: '2px',
							}}
						>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'right',
									marginBottom: 'auto',
									fontWeight: 'bold',
								}}
							>
								{`${amtStr(totalSC) !== '0.00' ? amtStr(totalSC) : '-'}`}
							</div>
						</Grid>

						<Grid
							item
							xs={2}
							style={{
								paddingBottom: '2px',
								borderTop: '1px solid black',
								borderBottom: '1px solid black',
								paddingTop: '2px',
							}}
						>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'right',
									marginBottom: 'auto',
									fontWeight: 'bold',
								}}
							>
								{`${amtStr(totalTax) !== '0.00' ? amtStr(totalTax) : '-'}`}
							</div>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={5}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
								}}
							>
								{`Date & Time of Validation`}
							</div>
						</Grid>

						<Grid item xs={7}>
							<div
								className="desc content-wrap full text-noflow"
								style={{
									textAlign: 'left',
									marginBottom: 'auto',
								}}
							>
								{`:${format(new Date(), 'dd/MM/yyyy, hh:mm a') ?? '-'}`}
							</div>
						</Grid>
					</Grid>

					<span
						className="desc content-wrap full"
						style={{
							textAlign: 'center',
							marginTop: '14px',
						}}
					>
						{'Thank you & we hope to see you again soon.'}
					</span>
				</CardContents>
			</div>
		</>
	);
};

export const BillReceiptPrintEInvoice = (
	outlet,
	data,
	orderItems,
	negOrderItem,
	discount,
	totalOptItmPerOrderItem,
	footerToken,
	latestPayment,
	chargeInfo1,
	chargeInfo2,
	taxByClass,
) => {
	let roundAmount = 0;
	latestPayment?.map(x => {
		roundAmount += x?.roundingAmount;
	});

	const handleCardType = cardType => {
		switch (cardType) {
			case 'VISA_CARD':
				return 'VISA';
			case 'MASTERCARD':
				return 'MASTERCARD';
			case 'AMERICAN_EXPRESS_CARD':
				return 'AMERICAN EXPRESS';
			case 'DINERS_CARD':
				return 'DINERS';
			case 'JCB_CARD':
				return 'JCB';
			case 'UNIONPAY_CARD':
				return 'UNIONPAY';
			case 'DEBIT_CARD':
				return 'DEBIT';
			default:
				return 'Credit';
		}
	};

	// outlet name & reg no
	bxlPOS.printText(
		`${outlet?.name} (${outlet?.registrationNo})\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);

	// address
	bxlPOS.printText(
		`${outlet?.address?.address}, ${outlet?.address?.postCode}, ${outlet?.address?.city}, ${outlet?.address?.state}.\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// phone
	bxlPOS.printText(
		`Tel No: ${outlet?.contactNo}\n\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// tin & msic code
	bxlPOS.printText(
		`MSIC Code: ${outlet?.msicCode}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// sst
	bxlPOS.printText(
		`SST Registration No: ${outlet?.taxRegistrationNo}\n\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	// einvoice
	bxlPOS.printText(`E-INVOICE\n\n`, 0, 0, true, false, false, 0, 1);

	// customer info
	bxlPOS.printText(
		`${data?.customerInfo?.billTo}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`${data?.customerInfo?.address?.address}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`${data?.customerInfo?.address?.postCode} ${data?.customerInfo?.address?.city}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`${data?.customerInfo?.address?.state}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`${data?.customerInfo?.address?.country}\n\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	// qr code

	// tin
	bxlPOS.printText(
		`TIN        : ${data?.customerInfo?.tin ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`ID/ Reg No : ${data?.customerInfo?.registrationNo ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Tel No     : ${data?.customerInfo?.nricNo ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Email      : ${data?.customerInfo?.tin ?? '-'}\n\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	// uin
	bxlPOS.printText(
		`UIN        : ${`123456789-2024-1234567` ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Doc No     : ${`DR1234` ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Ref No     : ${`Not Applicable` ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Date & Time: ${format(new Date(), 'dd/MM/yyyy, hh:mm a') ?? '-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	bxlPOS.printText(
		`Currency   : ${outlet?.currencyCode ?? '-'}\n\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	// order item (oi)
	{
		orderItems?.map(oi => {
			// oi class, name, quantity & amount

			let totalOITax = 0;
			oi?.orderItemTax?.map(x => {
				totalOITax += x?.taxAmount;
			});

			alignment(
				`${oi?.menuItem?.major?.eInvClassification} ${oi?.openItemName ??
					oi?.menuItem?.name} x${oi?.quantity -
					(negOrderItem?.[oi?.ID]?.quantity ?? 0)}`,
				`${amtStr(
					new BigNumber(oi?.amount)
						.plus(totalOITax)
						.minus(
							(negOrderItem?.[oi?.ID]?.amount ?? 0) +
								(negOrderItem?.[oi?.ID]?.taxAmount ?? 0),
						)
						.toNumber(),
				)}\n`,
				false,
			);

			if (oi?.orderItemOption?.length > 0) {
				// order item option (oio)
				oi?.orderItemOption?.map(oiOption => {
					oiOption?.orderItemOptionItem?.map(oiOptionItem => {
						// oio name and amount

						let totalTax = 0;
						oiOptionItem?.orderItemOptionItemTax?.map(x => {
							totalTax += x?.taxAmount;
						});

						alignment(
							`    ${oiOptionItem?.optionItem?.name}`,
							`${
								oiOptionItem?.price === 0
									? ''
									: amtStr(
											Math.abs(
												(oiOptionItem?.basePrice + totalTax) *
													((oi?.quantity -
														(negOrderItem?.[oiOption?.orderItemID]?.quantity ??
															0)) /
														oi?.quantity),
											),
									  )
							}\n`,
							false,
						);
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				// order item set menu option (oismo)
				oi?.orderItemSetMenuOption?.map(oiSetMenuOption => {
					oiSetMenuOption?.orderItemSetMenuOptionItem?.map(
						// oismo name
						async oiSetMenuOptionItem => {
							alignment(
								`    ${oiSetMenuOptionItem?.setMenuOptionItem?.menuItem?.name}`,
								`\n`,
								false,
							);
						},
					);
				});
			}

			// order item discount (oid)
			const activeOrderItemDiscount = oi?.orderItemDiscount?.filter(
				y => y?.status === OrderItemDiscountStatus?.Active,
			)[0];

			let totalOIDTax = 0;
			oi?.orderItemDiscountTax?.map(x => {
				totalOIDTax += x?.taxAmount;
			});

			let sumNegOptDisc = 0;

			if (activeOrderItemDiscount) {
				alignment(
					`    Discount: ${
						discount?.filter(
							x => x?.ID === activeOrderItemDiscount?.discountID,
						)[0]?.code
					}`,
					`(${amtStr(
						activeOrderItemDiscount?.baseAmount +
							totalOIDTax -
							(negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ?? 0) -
							(negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.discountBaseAmount ?? 0) +
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.itemDiscountTaxAmount ?? 0) -
							(totalOptItmPerOrderItem?.filter(
								x => x?.orderItemID === oi?.ID,
							)[0]?.discountItemTaxAmount ?? 0) -
							sumNegOptDisc,

						// calcDiscountAmount(orderItem),
					)})\n`,
					false,
				);
			}
		});
	}

	// subtotal
	alignment(` `, `\n`, false);
	alignment(`Sub-Total`, `${amtStr(footerToken?.subtotal)}\n`, false);

	// discount
	const activeOrderDiscount = data?.order?.orderDiscount?.filter(
		y => y?.status === OrderDiscountStatus?.Active,
	)[0];

	if (activeOrderDiscount) {
		alignment(
			`Discount: ${discount?.filter(
				x => x?.ID === activeOrderDiscount?.discountID,
			)[0]?.code ?? ' '}`,
			`(${amtStr(footerToken?.discount)})\n`,
			false,
		);
	} else {
		alignment(`Discount`, `(${amtStr(footerToken?.discount)})\n`, false);
	}

	// rounding
	if (roundAmount < 0) {
		alignment(`Rounding`, `(${Math.abs(roundAmount).toFixed(2)})\n`, false);
	} else {
		alignment(`Rounding`, `${amtStr(roundAmount)}\n`, false);
	}

	// total
	alignment(
		`Total`,
		`${amtStr(
			new BigNumber(Math.abs(footerToken?.total))
				.plus(roundAmount ?? 0)
				.toNumber(),
		)}\n`,
		true,
	);

	// payment
	alignment(` `, `\n`, false);
	alignment(`Paid by`, `\n`, true);

	latestPayment?.map(el => {
		if (el?.paymentClass === PaymentClass.UnsettledBill) {
			alignment(`Unsettled Bill`, `${amtStr(el?.amount)}\n`, false);
		} else if (el?.paymentClass === PaymentClass.Credit) {
			alignment(
				`${handleCardType(el?.creditCardType)}`,
				`${amtStr(el?.receivedAmount)}\n`,
				false,
			);
		} else if (el?.paymentClass === PaymentClass.Online) {
			alignment(`Online Payment`, `${amtStr(el?.receivedAmount)}\n`, false);
		} else if (el?.paymentClass === PaymentClass.NonGuest) {
			alignment(`Non Guest`, `${amtStr(el?.receivedAmount)}\n`, false);
		} else if (el?.paymentClass === PaymentClass.SuspendFolio) {
			alignment(`Suspend Folio`, `${amtStr(el?.receivedAmount)}\n`, false);
		} else if (el?.paymentClass === PaymentClass.BankTt) {
			alignment(`Bank TT`, `${amtStr(el?.receivedAmount)}\n`, false);
		} else {
			alignment(
				`${toProperCase(el?.paymentClass)}`,
				`${amtStr(el?.receivedAmount)}\n`,
				false,
			);
		}

		if (
			el?.paymentClass === PaymentClass.Debtor ||
			el?.paymentClass === PaymentClass.Room ||
			el?.paymentClass === PaymentClass.Officer ||
			el?.paymentClass === PaymentClass.Staff
		) {
			alignment(`  Charged to`, `${chargeInfo1} | ${chargeInfo2}  \n`, false);
		}

		if (
			el?.paymentClass === PaymentClass.NonGuest ||
			el?.paymentClass === PaymentClass.SuspendFolio
		) {
			alignment(`  Charged to`, `${chargeInfo2}  \n`, false);
		}

		if (el?.paymentClass === PaymentClass.BankTt) {
			alignment(`  Remark: `, `${el?.remark ?? '-'}  \n`, false);
		}

		if (el?.paymentClass === PaymentClass.Credit) {
			alignment(
				`  Card ref: `,
				`${`****${el?.cardNo?.slice(-4)}` ?? '-'}  \n`,
				false,
			);
		}

		if (el?.paymentClass === PaymentClass.Voucher) {
			alignment(`  Code: `, `${el?.referenceNo ?? '-'}  \n`, false);
		}

		if (el?.paymentClass === PaymentClass.Others) {
			alignment(`  Remarks: `, `${el?.referenceNo ?? '-'}  \n`, false);
		}

		if (el?.paymentClass === PaymentClass.Cash && el?.changeAmount > 0) {
			alignment(`  Change: `, `${amtStr(el?.changeAmount)}\n`, false);
		}
	});

	if (latestPayment?.length === 0) {
		const OCENT = discount?.filter(
			x => x?.ID === activeOrderDiscount?.discountID,
		)[0]?.discountType;

		const displayOCENT =
			OCENT === 'OFFICER_CHECK'
				? 'Officer Check'
				: OCENT === 'ENTERTAINMENT'
				? 'Entertainment'
				: OCENT === 'DISCOUNT'
				? 'Discount'
				: 'Promo';

		alignment(`${displayOCENT}`, `${amtStr(latestPayment?.amount)}\n`, false);
	}

	alignment(` `, `\n`, false);
	alignment(`Bill Remark:`, `\n`, true);

	bxlPOS.printText(
		`------------------------------------------\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		1,
	);

	alignment(`Tax Summary:`, `\n\n`, true);

	// tax
	const header = [
		'Class',
		'Tax Rate',
		'Amount (Excl Tax)',
		'Service Charge',
		'Tax Amount',
	];

	tableAlignment(header, false);

	const taxTable = taxByClass?.map(el => Object.values(el));

	let transformedData = taxTable.map(subArray => {
		return subArray.map((item, index) => {
			if (index === 1) {
				return item !== '0' ? parseFloat(item).toFixed(0) + ' %' : '-';
			} else {
				return item !== '0' ? item : '-';
			}
		});
	});

	transformedData?.map(x => {
		tableAlignment(x, false);
	});

	let totalAmount = 0,
		totalSC = 0,
		totalTax = 0;

	taxByClass?.map(x => {
		totalAmount += parseFloat(x?.amount ?? 0);
		totalSC += parseFloat(x?.totalSC ?? 0);
		totalTax += parseFloat(x?.totalTax ?? 0);
	});

	const totalArray = [
		'',
		'',
		totalAmount.toFixed(2).toString(),
		totalSC.toFixed(2).toString(),
		totalTax.toFixed(2).toString(),
	];

	const newArray = totalArray.map(item => {
		return item === '0.00' ? '-' : item;
	});

	tableAlignment(newArray, true);

	// signature
	// alignment(` `, `\n`, false);
	// bxlPOS.printText(
	// 	`Digital Signature     : ${outlet?.companyDigitalSign ?? '-'}\n`,
	// 	0,
	// 	0,
	// 	false,
	// 	false,
	// 	false,
	// 	0,
	// 	0,
	// );

	// date & time
	alignment(` `, `\n`, false);
	bxlPOS.printText(
		`Date & Time Validation: ${format(new Date(), 'dd/MM/yyyy, hh:mm a') ??
			'-'}\n`,
		0,
		0,
		false,
		false,
		false,
		0,
		0,
	);

	alignment(` `, `\n`, false);
	bxlPOS.printText(
		// `Thank you. Please come again!\n`,
		`Thank you & we hope to see you again soon.\n`,
		0,
		0,
		false,
		false,
		false,
		1,
		1,
	);
};

export const tableAlignment = (values: any, bold: any) => {
	let LINE_CHARS = 42;

	const columnWidth = LINE_CHARS / 5;

	for (let i = 0; i < values.length; i += 5) {
		const row = values.slice(i, i + 5);
		const wrappedRow = [];

		for (let item of row) {
			const lines = [];
			for (let j = 0; j < item.length; j += columnWidth) {
				lines.push(item.substring(j, j + columnWidth));
			}
			wrappedRow.push(lines);
		}

		const maxLines = Math.max(...wrappedRow.map(lines => lines.length));

		for (let lineNum = 0; lineNum < maxLines; lineNum++) {
			let line = '';
			for (let col = 0; col < 5; col++) {
				const cellLines = wrappedRow[col];
				if (lineNum < cellLines.length) {
					line += cellLines[lineNum].padEnd(columnWidth);
				} else {
					line += ' '.repeat(columnWidth);
				}
			}

			bxlPOS.printText(`${line}\n`, 0, 0, bold, false, false, 0, 1);
		}
	}
};
