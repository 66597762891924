import { yupResolver } from '@hookform/resolvers';
import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { AddCircle, Edit, MoreVert } from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { createFilterOptions } from '@material-ui/lab';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	PaymentClass,
	useCheckBanknameInUseQuery,
	useCreateBankAccountMutation,
	useDeleteBankAccountMutation,
	useGetBankAccountQuery,
	useGetOutletQuery,
	useUpdateBankAccountMutation,
} from 'generated/graphql';
import { useAddressAutoComplete } from 'helpers/hooks/useAddressAutoComplete';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

const useStyles = makeStyles({
	root: {
		['& *']: {
			boxSizing: 'border-box',
		},
	},
	form: {
		['& > *']: {
			marginBottom: '0.5rem',
		},
	},
	halfWidths: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',

		['& > *']: {
			flex: '0 0 auto',
			width: '50%',
			marginBottom: '0.5rem',
		},
	},
});

export interface BankAccountProps {
	bankName: string;
	bankCode: string;
	accountName: string;
	accountNo: number;
	SWIFTCode: string;
	branch: string;
	contactNo: string;
	address: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
	ContactData: string;
}

export const BankAccountForm = (props: any) => {
	useEffect(() => {
		console.log('BankAccountForm');
	}, []);
	const {
		anchorEl,
		handleClose,
		menu,
		handleClick,
		resetMenu,
	} = useMenuOption();
	const [openDialog, setOpenDialog] = useState(false);
	const [contactData, setContactData] = useState([]);
	const [checkContactError, setCheckContactError] = useState([]);
	const { [`bankAccountID`]: bankAccountID } = useParams<Record<string, any>>();
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const [country, setCountry] = useState('');
	const [newState, setState] = useState('');
	const [city, setCity] = useState('');
	const [newPostcode, setPostcode] = useState('');

	const location = useLocation();
	const editData = location?.state as any;
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { mode } = props;

	const {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		query,
		autoCompleteRef,
		state,
		setQuery,
		dispatch,
		initializeEditValue,
	} = useAddressAutoComplete();

	const [ogAddr, setOGAddr] = useState(null);
	const [addrValue, setAddress] = useState(null);

	useEffect(() => {
		if (mode === 'edit' && editData) {
			const ContactData = editData?.bankAccountContact?.map((el, index) => {
				return {
					name: el?.name,
					designation: el?.designation,
					mobileNo: el?.mobileNo,
					email: el?.email,
				};
			});

			contactData.push(...ContactData);

			let array_of_address = [
				editData?.address?.address,
				editData?.address?.city,
				editData?.address?.state,
				editData?.address?.country,
			];
			setQuery(array_of_address.join(', '));
			setOGAddr(array_of_address.join(', ')); //to compare if there is any changes
			setAddress(array_of_address.slice(0));
			initializeEditValue({
				address: array_of_address.join(', '),
				country: editData?.address?.country,
				state: editData?.address?.state,
				city: editData?.address?.city,
				postCode: editData?.address?.postCode,
			});
		}
	}, [mode, editData]);
	const [isEdit, setIsEdit] = useState(false);
	const {
		called: bankAccountCalled,
		loading: bankAccountLoading,
		error: bankAccountError,
		data: { getBankAccount } = { getBankAccount: [] },
	} = useGetBankAccountQuery({
		fetchPolicy: 'network-only',
	});
	console.log(getBankAccount, 'getBankAccount');

	const existingbankCode = getBankAccount?.map(m => m?.bankCode);

	const yupSchema = yup.object().shape({
		bankName: CommonYupValidation.requireField(SystemMsgs.bankName()).trim(),
		accountName: CommonYupValidation.requireField(
			SystemMsgs.accountHolder(),
		).trim(),
		accountNo: CommonYupValidation.requireField(SystemMsgs.accountNo()).trim(),
		SWIFTCode: CommonYupValidation.requireField(SystemMsgs.swiftCode()).trim(),
		branch: CommonYupValidation.requireField(SystemMsgs.branch()).trim(),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()).trim(),
		bankCode: yup
			.string()
			.required(SystemMsgs.bankCode())
			.trim()
			.test('bankCode', 'Bank Code already exist', value => {
				if (
					(watch('bankCode') !== editData?.bankCode && mode === 'edit') ||
					(watch('bankCode') !== editData?.bankCode && mode === 'add')
				) {
					return !existingbankCode?.includes(value);
				}
				return true;
			}),
	});

	const {
		handleSubmit,
		register,
		errors,
		control,
		watch,
		setValue,
		setError,
		formState: { isSubmitted },
	} = useForm<BankAccountProps>({
		defaultValues: {
			bankName: mode === 'add' ? '' : editData?.bankName,
			bankCode: mode === 'add' ? '' : editData?.bankCode,
			accountName: mode === 'add' ? '' : editData?.accountName,
			accountNo: mode === 'add' ? '' : editData?.accountNo,
			SWIFTCode: mode === 'add' ? '' : editData?.SWIFTCode,
			branch: mode === 'add' ? '' : editData?.branch,
			contactNo: mode === 'add' ? '' : editData?.contactNo,
			address: mode === 'add' ? '' : editData?.address,
			country: mode === 'add' ? '' : editData?.country,
			state: mode === 'add' ? '' : editData?.state,
			city: mode === 'add' ? '' : editData?.city,
			postcode: mode === 'add' ? '' : editData?.postCode,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const onCreateContact = data => {
		contactData.push({
			name: data.name,
			designation: data.designation,
			mobileNo: data.mobileNo,
			email: data.email,
		});
	};

	const onUpdateContact = (data, index) => {
		if (checkContact(watch('ContactData'))?.length === 0) {
			contactData[index].name = data.name;
			contactData[index].designation = data.designation;
			contactData[index].mobileNo = data.mobileNo;
			contactData[index].email = data.email;
		}
	};

	const [
		submitBankAccount,
		{ loading: createBankAccountLoading, called: createBankAccountCalled },
	] = useCreateBankAccountMutation({
		onCompleted: data => {
			history.push({
				pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
				state: { success: true, msgMode: 'create' },
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateBankAccount,
		{ loading: updateBankAccountLoading, called: updateBankAccountCalled },
	] = useUpdateBankAccountMutation({
		onCompleted: data => {
			history.push({
				pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
				state: { success: true, msgMode: 'update' },
			});

			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const outletID = localStorage.getItem('latestOutlet');

	const {
		data: { CheckBanknameInUse } = { CheckBanknameInUse: [] },
	} = useCheckBanknameInUseQuery({
		variables: {
			bankAccountID: editData?.ID,
		},
		fetchPolicy: 'no-cache',
	});

	console.log(CheckBanknameInUse, 'CheckBanknameInUse');
	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const history = useHistory();

	const onSubmit = (data, state, contact) => {
		if (mode === 'add') {
			submitBankAccount({
				variables: {
					bankAccountInput: {
						//...(mode === 'edit' ? { ID: editData?.ID } : null),
						bankName: data.bankName,
						bankCode: data.bankCode,
						accountName: data.accountName,
						accountNo: data.accountNo,
						SWIFTCode: data.SWIFTCode,
						branch: data.branch,
						contactNo: data.contactNo,
						address: {
							address:
								mode === 'edit' && query === ogAddr
									? addrValue[0]
									: state.address,
							state:
								mode === 'edit' && query === ogAddr
									? editData?.address?.state
									: state.state.name,
							city:
								mode === 'edit' && query === ogAddr
									? editData?.address?.city
									: state.city.name,
							country:
								mode === 'edit' && query === ogAddr
									? editData?.address?.country
									: state.country.name,
							postCode:
								mode === 'edit' && query === ogAddr
									? editData?.address?.postCode
									: state.postcode.postcode,
						},
						outletID: outletID,
						isDefault: false,
					},
					contactInput: contact?.map(data => {
						return {
							name: data?.name,
							designation: data?.designation,
							mobileNo: data?.mobileNo,
							email: data?.email,
						};
					}),
					outletID: outletID,
				},
			});
		} else {
			updateBankAccount({
				variables: {
					bankAccountInput: {
						...(mode === 'edit' ? { ID: editData?.ID } : null),
						bankName: data.bankName,
						bankCode: data.bankCode,
						accountName: data.accountName,
						accountNo: data.accountNo,
						SWIFTCode: data.SWIFTCode,
						branch: data.branch,
						contactNo: data.contactNo,
						address: {
							address:
								mode === 'edit' && query === ogAddr
									? addrValue[0]
									: state.address,
							state:
								mode === 'edit' && query === ogAddr
									? editData?.address?.state
									: state.state.name,
							city:
								mode === 'edit' && query === ogAddr
									? editData?.address?.city
									: state.city.name,
							country:
								mode === 'edit' && query === ogAddr
									? editData?.address?.country
									: state.country.name,
							postCode:
								mode === 'edit' && query === ogAddr
									? editData?.address?.postCode
									: state.postcode.postcode,
						},
						outletID: data.outletID,
					},
					contactInput: contact?.map(data => {
						return {
							...(mode == 'edit' ? { ID: editData?.BankContactID } : null),
							name: data.name,
							designation: data.designation,
							mobileNo: data.mobileNo,
							email: data.email,
						};
					}),
					outletID: outletID,
				},
			});
		}
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option: any) => option.name,
	});

	const checkContact = data => {
		let errorData = [];

		if (data.name === undefined || data.name === '') {
			errorData.push({
				error: 'name',
			});
		}
		if (data.mobileNo === undefined || data.mobileNo === '') {
			errorData.push({
				error: 'designation',
			});
		}
		if (data.email === undefined || data.email === '') {
			errorData.push({
				error: 'mobileNo',
			});
		}
		if (data.designation === undefined || data.designation === '') {
			errorData.push({
				error: 'email',
			});
		}
		if (validateEmail(data.email) === false) {
			errorData.push({
				error: 'invalidEmailAddress',
			});
		}
		setCheckContactError(errorData);
		return errorData;
	};

	const validateEmail = email => {
		if (
			email.match(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			)
		) {
			return true;
		} else {
			return false;
		}
	};

	const [deleteBank] = useDeleteBankAccountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const onDeleteContact = index => {
		contactData.splice(index, 1);
	};

	const bankCodeNull =
		getBankAccount?.find(x => x?.bankName === editData?.bankName)?.bankCode ===
		'';
	console.log(
		getBankAccount?.find(x => x?.bankName === editData?.bankName)?.bankCode ===
			'',
		'test',
	);

	console.log(
		getBankAccount?.some(x => x?.bankCode === editData?.bankCode),
		'test',
	);
	return (
		<>
			{bankAccountLoading && <Loading />}
			{createBankAccountLoading && <Loading />}
			{/* {updateBankAccountLoading && <Loading />} */}
			<MainHeader
				onClick={() => {
					setOpenExitDialog(true);
				}}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'Bank Account', current: true },
				]}
				rightRouteSegments={[{ name: mode === 'edit' ? 'Edit' : 'Add' }]}
			/>
			<ContentWrapper float footer>
				<CardContents devFullWidth={true}>
					<Controller
						as={TextField}
						name="bankName"
						label="Bank Name"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.bankName?.message}
						error={errors?.bankName ? true : false}
						disabled={mode === 'edit'}
					></Controller>

					<Controller
						as={TextField}
						name="bankCode"
						label="Bank Code"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						disabled={
							mode === 'edit' &&
							CheckBanknameInUse === true &&
							bankCodeNull === false
						}
						error={errors?.bankCode ? true : false}
						helperText={errors?.bankCode?.message || bankCodeNull === true}
					></Controller>

					<Controller
						as={TextField}
						name="accountName"
						label="Account Holder Name"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.accountName?.message}
						error={errors?.accountName ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="accountNo"
						label="Bank Account No."
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.accountNo?.message}
						error={errors?.accountNo ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="SWIFTCode"
						label="Bank Swift Code"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.SWIFTCode?.message}
						error={errors?.SWIFTCode ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="branch"
						label="Bank Branch"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.branch?.message}
						error={errors?.branch ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="contactNo"
						label="Bank Contact No."
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.contactNo?.message}
						error={errors?.contactNo ? true : false}
					></Controller>

					<AddressAutoCompleteComponent
						getCountry={getCountry}
						getStatebyCountry={getStatebyCountry}
						getCitybyState={getCitybyState}
						getPostcodebyCity={getPostcodebyCity}
						handleCountryOnChange={handleCountryOnChange}
						handleStateOnChange={handleStateOnChange}
						handleCityOnChange={handleCityOnChange}
						handlePostCodeOnChange={handlePostCodeOnChange}
						handleGoogleOnChange={handleGoogleOnChange}
						autoCompleteRef={autoCompleteRef}
						state={state}
						country={country}
						setCountry={setCountry}
						setState={setState}
						setCity={setCity}
						newState={newState}
						newPostcode={newPostcode}
						setPostcode={setPostcode}
						city={city}
						formType={mode}
						isSubmitted={isSubmitted}
						editData={editData}
					/>
				</CardContents>
				<div style={{ margin: '12px' }}></div>

				<CardContents
					contentStyle={{
						display: 'contents',
					}}
					section={{
						header: {
							title: 'Contact Details',
							icon: (
								<AddCircle
									style={{
										fontSize: '32px',
										paddingTop: '5px',
										color: '#ff9800',
									}}
									onClick={() => {
										resetMenu();
										setOpenDialog(true);
									}}
								/>
							),
						},
					}}
				>
					<List>
						{contactData.map((el, index) => {
							return (
								<>
									<ListItem
										key={index}
										onClick={() =>
											history.push({
												pathname: el.path,
											})
										}
										style={{ marginLeft: '-5px', marginTop: '-15px' }}
									>
										<ListItemText
											primary={
												<>
													<span className="desc">
														<span className="xsTitle">{el.name}</span>
														<span className="desc">({el.designation})</span>
													</span>
												</>
											}
											secondary={
												<span className="desc">
													<span className="desc">
														<PhoneIcon
															style={{
																fontSize: '10px',
																color: 'black',
																marginBottom: '-1px',
																marginRight: '-1px',
															}}
														/>
														<span
															className={`primary-click-text`}
															onClick={e => {
																window.open(`te;:${el.mobileNo}`);
															}}
														>
															+{el.mobileNo}
														</span>
														{`  |  `}
														<span>
															<EmailIcon
																style={{
																	fontSize: '12px',
																	color: 'black',
																	marginBottom: '-3px',
																	marginRight: '3px',
																}}
															/>
															<span
																className={`primary-click-text`}
																onClick={e => {
																	window.open(`mailto: ${el.email}`);
																}}
															>
																{el.email}
															</span>
														</span>
													</span>
												</span>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="more"
												aria-controls="menu-list"
												aria-hashpopup="true"
												onClick={e => handleClick(e, el.ID, index, el)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
						})}
					</List>
				</CardContents>
			</ContentWrapper>
			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem onClick={() => [setOpenDialog(true)]}>
					<span>Edit</span>
				</MenuItem>
				<MenuItem
					onClick={() => {
						onDeleteContact(menu?.index);
					}}
				>
					<span className="">Delete</span>
				</MenuItem>
			</Menu>
			A{/* </form> */}
			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							if (
								watch('email') === '' ||
								watch('email') === null ||
								watch('email') === undefined
							) {
								handleSubmit(data => onSubmit(data, state, contactData))();
							} else if (validateEmail(watch('email')) === true) {
								handleSubmit(data => onSubmit(data, state, contactData))();
							}
						},
						color: 'primary',
					},
				]}
			/>
			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
					setCheckContactError([]);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Add Contact
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<Controller
								as={TextField}
								name="ContactData.name"
								label="Name"
								required
								autoComplete="off"
								control={control}
								multiline={true}
								fullWidth
								helperText={
									(watch('ContactData.name') === '' ||
										watch('ContactData.name') === undefined) &&
									checkContactError?.find(x => x?.error === 'name')
										? 'Name is required'
										: null
								}
								error={
									(watch('ContactData.name') === '' ||
										watch('ContactData.name') === undefined) &&
									checkContactError?.find(x => x?.error === 'name')
										? true
										: false
								}
								ref={register}
								defaultValue={menu?.obj?.name || ''}
							/>

							<Controller
								as={TextField}
								name="ContactData.designation"
								label="Designation"
								required
								autoComplete="off"
								style={{ width: '100%' }}
								control={control}
								multiline={true}
								fullWidth
								helperText={
									(watch('ContactData.designation') === '' ||
										watch('ContactData.designation') === undefined) &&
									checkContactError?.find(x => x?.error === 'designation')
										? 'Designation is required'
										: null
								}
								error={
									(watch('ContactData.designation') === '' ||
										watch('ContactData.designation') === undefined) &&
									checkContactError?.find(x => x?.error === 'designation')
										? true
										: false
								}
								ref={register}
								defaultValue={menu?.obj?.designation || ''}
							/>
							<Controller
								as={TextField}
								name="ContactData.mobileNo"
								label="Mobile No."
								required
								style={{ width: '100%' }}
								control={control}
								helperText={
									(watch('ContactData.mobileNo') === '' ||
										watch('ContactData.mobileNo') === undefined) &&
									checkContactError?.find(x => x?.error === 'mobileNo')
										? 'Mobile No is required'
										: null
								}
								error={
									(watch('ContactData.mobileNo') === '' ||
										watch('ContactData.mobileNo') === undefined) &&
									checkContactError?.find(x => x?.error === 'mobileNo')
										? true
										: false
								}
								ref={register}
								defaultValue={menu?.obj?.mobileNo || ''}
							/>

							<Controller
								as={TextField}
								name="ContactData.email"
								label="Email address"
								required
								autoComplete="off"
								control={control}
								multiline={true}
								fullWidth
								helperText={
									(watch('ContactData.email') === '' ||
										watch('ContactData.email') === undefined) &&
									checkContactError?.find(x => x?.error === 'emailAddress')
										? 'Email address is required'
										: !!watch('ContactData.email') &&
										  validateEmail(watch('ContactData.email')) === false
										? 'Email must be a valid email'
										: null
								}
								error={
									(watch('ContactData.email') === '' ||
										watch('ContactData.email') === undefined) &&
									checkContactError?.find(x => x?.error === 'emailAddress')
										? true
										: !!watch('ContactData.email') &&
										  validateEmail(watch('ContactData.email')) === false
										? true
										: false
								}
								ref={register}
								defaultValue={menu?.obj?.email || ''}
							/>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setCheckContactError([]);
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										menu?.obj
											? onUpdateContact(watch('ContactData'), menu?.index)
											: onCreateContact(watch('ContactData'));
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/account-journals/account-mapping/bank-account`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										if (mode === 'edit') {
											history.push({
												pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
											});
										} else {
											history.push({
												pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
											});
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
