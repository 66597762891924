import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import {
	DraggableItem,
	DragNDropList,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Box,
	IconButton,
	List,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOptionItemDocument,
	useCreateOptionItemMutation,
	useDeleteOptionItemMutation,
	useDragOptionItemMutation,
	useGetAllOptionQuery,
	useGetOptionItemQuery,
	useGetOptionQuery,
	useGetOutletQuery,
	useUpdateOptionItemMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
interface OptionItemProps {
	name: string;
}

// const dialogFormFieldsInitialState: OptionItemProps = {
// 	name: '',
// };

export const StandardMenuOptionItem = (props: any) => {
	useEffect(() => {
		console.log('StandardMenuOptionItem');
	}, []);
	const history = useHistory();
	const location = useLocation();

	const { mode } = props;

	const { outletID }: any = useParams();
	const { optionID }: any = useParams();

	const { pathname } = useLocation();
	const { optionTypeID } = useParams<Record<string, string>>();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const {
		anchorEl: allAnchorEL,
		menu: allMenu,
		handleClick: allHandleClick,
		handleClose: allHandleClose,
	}: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [double, setDouble] = useState(false);

	const editData = location?.state as any;

	const [isEdit, setIsEdit] = useState(false);
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const {
		called: optionItemCalled,
		error: outletError,
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const {
		data: { getOption } = { getOption: [] },
		loading: optionLoading,
	} = useGetOptionQuery({
		fetchPolicy: 'network-only',
		variables: { ID: optionID },
	});

	const {
		data: { getOptionItem } = { getOptionItem: [] },
		loading: optionItemLoading,
	} = useGetOptionItemQuery({
		fetchPolicy: 'network-only',
		variables: { optionID: optionID, orderByAsc: 'priority' },
	});

	const [
		submitOptionItem,
		{ data: createdOptionItemData, loading: submitOptionItemLoading },
	] = useCreateOptionItemMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},

		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOpenDialog(false);
		},
	});

	let [initialData, setInitialData] = useState<any>();

	useEffect(() => {
		setInitialData(getOptionItem);
	}, [getOptionItem]);

	// useEffect(() => {}, [initialData]);

	const onDragEnd = ({ source, destination }) => {
		if (destination === undefined || destination === null) return null;

		if (
			source.droppableId === destination.droppableId &&
			destination.index === source.index
		)
			return null;

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			let testArray = initialData.filter((_, idx) => idx !== source.index);

			testArray.splice(destination.index, 0, initialData[source.index]);

			setInitialData([...testArray]);
			dragOptionItem({
				variables: {
					input: testArray?.map((x, i) => {
						return {
							ID: x?.ID,
							name: x?.name,
							priority: i + 1,
							optionID: optionID,
						};
					}),
					optionID: optionID,
				},
			});

			return null;
		}
	};
	/// <<< DND MUTATION >>> ///
	const [
		dragOptionItem,
		{ loading: dragOptionItemLoading, error: dragOptionItemError },
	] = useDragOptionItemMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	const [
		updateOptionItem,
		{ loading: subUpdateLoading, error: subUpdateError },
	] = useUpdateOptionItemMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconId}/vo`);

			// setTimeout(() => {
			// 	history.push({
			// 		pathname: `/menu/outlet-app/outlet-setting/${outletID}/major/${majorID}/family`,
			// 		state: { success: true, msgMode: 'update' },
			// 	});
			// }, 500);
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	/// <<< DELETE MUTATION >>> ///

	const [
		deleteOptionItem,
		{ loading: deleteOptionItemLoading },
	] = useDeleteOptionItemMutation({
		onError: error => {
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	//GET ALL OPTION MUTATION

	const { data: allOptionData } = useGetAllOptionQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
		onError: error => {
			console.log(error);
		},
	});

	/// <<< DELETE MUTATION >>> ///

	const existingOptionItemNames = getOptionItem?.map(o => o?.name);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('name', 'Option name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingOptionItemNames?.includes(value);
				}
				return true;
			}),
	});
	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		setError,
		clearErrors,
		formState,
	} = useForm<OptionItemProps>({
		defaultValues: {
			// name: mode === 'add' ? '' : editData?.name,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = (data: any) => {
		isEdit === false
			? submitOptionItem({
					variables: {
						input: {
							optionID: optionID,
							name: data?.name,
							outletID: outletID,
						},
					},
					refetchQueries: [
						{
							query: GetOptionItemDocument,
							variables: { optionID: optionID, orderByAsc: 'priority' },
						},
					],
			  })
			: updateOptionItem({
					variables: {
						input: {
							ID: menu?.ID,
							optionID: optionID,
							name: data?.name,
							outletID: outletID,
						},
					},
					refetchQueries: [
						{
							query: GetOptionItemDocument,
							variables: { optionID: optionID, orderByAsc: 'priority' },
						},
					],
			  });
		clearErrors();
	};

	// useEffect(() => {
	// 	if (
	// 		(!optionItemCalled && getOptionItem === undefined) ||
	// 		getOptionItem?.length === 0
	// 	) {
	// 		setOpenDialog(true);
	// 		setIsEdit(false);
	// 	} else {
	// 		setOpenDialog(false);
	// 	}
	// }, getOptionItem);

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	return (
		<>
			{outletLoading && <Loading />}
			{optionLoading && <Loading />}
			{optionItemLoading && <Loading />}
			{submitOptionItemLoading && <Loading />}
			{subUpdateLoading && <Loading />}
			{deleteOptionItemLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						//pathname: `/menu/outlet-app/outlet-setting/${outletID}`
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/standard-menu-option`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Standard Menu Option' },
					{ name: getOption[0]?.name, current: true },
				]}
			/>
			{/* <div style={{ marginBottom: '35px' }}></div> */}
			<DynamicSubHeader
				title={getOption[0]?.name} // title text
				// rightText="Price"
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				handleClick={e => allHandleClick(e)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={allAnchorEL}
						keepMounted
						open={Boolean(allAnchorEL)}
						onClose={allHandleClose}
						onClick={allHandleClose}
					>
						{allOptionData?.getOption?.map((x, i) => (
							<MenuItem
								key={i}
								value={x.ID}
								defaultValue={x.ID}
								onClick={e => {
									history.push({
										pathname: `/menu/outlet-app/outlet-setting/${outletID}/standard-menu-option/${x.ID}/standard-menu-option-item`,
									});
								}}
							>
								{x.name}
							</MenuItem>
						))}
					</StyledMenu>
				}
			/>

			<ContentWrapper float style={{ marginTop: isDesktop ? '93px' : '30px' }}>
				<MsgAlert
					//   icon={<InfoOutlined />}
					message={'Drag & drop to arrange sequence. '}
				/>
				<DragNDropList onDragEnd={onDragEnd}>
					<DropableItem droppableId={'option_list'}>
						<List className="core-list">
							{!outletLoading &&
							!optionLoading &&
							!optionItemLoading &&
							!submitOptionItemLoading &&
							!subUpdateLoading &&
							!deleteOptionItemLoading &&
							(getOptionItem === undefined || getOptionItem?.length === 0) ? (
								<EmptyList
									title="No Record found"
									subtitle="Please add option item to continue"
								/>
							) : (
								initialData?.map((el: any, index: number) => (
									<DraggableItem
										// style={{ padding: 0 }}
										id={el.ID}
										index={index}
										draggableId={el.ID}
									>
										<div style={{ paddingRight: '6px' }}></div>

										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{el?.name}</span>
													<span className="xxTitle highlight-text text-separator"></span>
												</>
											}
											secondary={
												<>
													<span className="desc">
														<span className="desc ">Last updated on</span>
														<span className="desc ">
															{' '}
															{formatDate(el?.createdTs)}
														</span>
													</span>
												</>
											}
										/>

										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="more"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => handleClick(e, el.ID, index, el)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</DraggableItem>
								))
							)}
						</List>
					</DropableItem>
				</DragNDropList>

				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openDialog}
						onClose={() => {
							handleCloseDialog();
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												{isEdit ? (
													<div className="title flex-space">
														Edit Standard Menu Option
													</div>
												) : (
													<div className="title flex-space">
														New Standard Menu Option
													</div>
												)}
												{/* <div className="flex session">
													<div className="title flex-space">
														{getOption[0]?.name}
													</div>
												</div> */}
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{getOption[0]?.name}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									{isEdit === false ? (
										<div className="content-container">
											<Controller
												as={TextField}
												name="name"
												label="Option Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	// errors?.name?.message ||
												// 	existingOptionItemNames?.includes(watch('name'))
												// 		? 'Option Item already exist'
												// 		: null
												// }
												// error={
												// 	// errors?.name ? true : false
												// 	existingOptionItemNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									) : (
										<div className="content-container">
											<Controller
												as={TextField}
												defaultValue={menu?.obj?.name}
												name="name"
												label="Option Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	errors?.name?.message ||
												// 	existingOptionItemNames?.includes(watch('name'))
												// 		? 'Option Item already exist'
												// 		: null
												// }
												// error={
												// 	// errors?.name ? true : false
												// 	existingOptionItemNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									)}
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											// onClick: () => setDeleteForm(false),
											variant: 'contained',
											// color: existingOptionItemNames?.includes(watch('name'))
											// 	? 'inherit'
											// 	: 'primary',
											// form: 'submit-form',
											// type: 'submit',
											// onClick: () => {
											// 	if (!existingOptionItemNames?.includes(watch('name'))) {
											// 		handleSubmit(onSubmit)();
											// 	}
											// },
											// onClick: () => {
											// 	handleSubmit(onSubmit)();
											// },
											color: 'primary',
											disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ optionID: optionID, orderByAsc: 'priority' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteOptionItem}
				refetchDocument={GetOptionItemDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
