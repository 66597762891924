import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	Observable,
	split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { ApolloLink, Observable, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './AccessToken';
import { useReducer, Reducer } from 'react';

import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();
let posNodeUrl;
switch (process.env.REACT_APP_ENV) {
	case 'swan':
		posNodeUrl = 'https://menuxswanapi.menux.asia/menux-graphql';
		break;
	case 'swanuat':
		posNodeUrl = 'https://menuxswanapi-uat.menux.asia/menux-graphql';
		break;
	case 'promenade-staging':
		posNodeUrl = 'https://menuxapi-promenade-uat.menux.asia/menux-graphql';
		break;
	case 'promenade':
		posNodeUrl = 'https://menuxapi-promenade.menux.asia/menux-graphql';
		break;
	case 'local':
		posNodeUrl = 'http://localhost:9000/menux-graphql';
		break;
	case 'dev':
		posNodeUrl = 'https://posx-dev-api.ifca.io/menux-graphql';
		break;
	case 'uat':
		posNodeUrl = 'https://menux-uat-api.menux.asia/menux-graphql';
		break;

	default:
		posNodeUrl = 'https://menuxapi.menux.asia/menux-graphql';
		break;
}
export let menuxSocketUrl;
switch (process.env.REACT_APP_API_URL) {
	case 'swan':
		menuxSocketUrl = 'https://menuxswanapi.menux.asia';
		break;
	case 'swanuat':
		menuxSocketUrl = 'https://menuxswanapi-uat.menux.asia';
		break;
	case 'promenade-staging':
		menuxSocketUrl = 'https://menuxapi-promenade-uat.menux.asia';
		break;
	case 'promenade':
		menuxSocketUrl = 'https://menuxapi-promenade.menux.asia';
		break;
	case 'local':
		menuxSocketUrl = 'http://localhost:9000';
		break;
	case 'dev':
		menuxSocketUrl = 'https://posx-dev-api.ifca.io';
		break;
	case 'uat':
		menuxSocketUrl = 'https://menux-uat-api.menux.asia';
		break;
	default:
		menuxSocketUrl = 'https://menuxapi.menux.asia';
		break;
}

let wsNodeUrl;
switch (process.env.REACT_APP_ENV) {
	case 'swan':
		wsNodeUrl = 'wss://menuxswanapi.menux.asia/menux-graphql';
		break;
	case 'swanuat':
		wsNodeUrl = 'wss://menuxswanapi-uat.menux.asia/menux-graphql';
		break;
	case 'promenade-staging':
		wsNodeUrl = 'wss://menuxapi-promenade-uat.menux.asia/menux-graphql';
		break;
	case 'promenade':
		wsNodeUrl = 'wss://menuxapi-promenade.menux.asia/menux-graphql';
		break;
	case 'local':
		wsNodeUrl = 'ws://localhost:9000/menux-graphql';
		break;
	case 'dev':
		wsNodeUrl = 'wss://posx-dev-api.ifca.io/menux-graphql';
		break;
	case 'uat':
		wsNodeUrl = 'wss://menux-uat-api.menux.asia/menux-graphql';
		break;

	default:
		wsNodeUrl = 'wss://menuxapi.menux.asia/menux-graphql';
		break;
}

export const posNodeRefreshUrl =
	process.env.REACT_APP_ENV === 'swan'
		? 'https://menuxswanapi.menux.asia/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'swanuat'
		? 'https://menuxswanapi-uat.menux.asia/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'promenade-staging'
		? 'https://menuxapi-promenade-uat.menux.asia/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'promenade'
		? 'https://menuxapi-promenade.menux.asia/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'local'
		? 'http://localhost:9000/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'dev'
		? 'https://posx-dev-api.ifca.io/refresh_token_menu'
		: process.env.REACT_APP_ENV === 'uat'
		? 'https://menux-uat-api.menux.asia/refresh_token_menu'
		: 'https://menuxapi.menux.asia/refresh_token_menu';
// 'http://localhost:9000/refresh_token_menu';

// export let paymentUrl = '';
// switch (process.env.REACT_APP_API_URL) {
// 	case 'local':
// 		paymentUrl = 'http://localhost:3250';
// 		break;
// 	case 'azure':
// 		paymentUrl = 'https://posx-dev-api.ifca.io';
// 		break;
// 	default:
// 		paymentUrl = 'https://posx-api.ifca.io';
// 		break;
// }

const cache = new InMemoryCache({});

//Getting access Token and passing it in request headers
const requestLink = new ApolloLink(
	(operation, forward) =>
		new Observable(observer => {
			let handle: any;
			Promise.resolve(operation)
				.then(operation => {
					const accessToken = getAccessToken();
					if (accessToken) {
						operation.setContext({
							headers: {
								authorization: `bearer ${accessToken}`,
							},
						});
					} //accessToken is defined
				}) //then operation ends here
				.then(() => {
					handle = forward(operation).subscribe({
						next: observer.next.bind(observer),
						error: observer.error.bind(observer),
						complete: observer.complete.bind(observer),
					}); //handle ends here
				})
				.catch(observer.error.bind(observer));

			return () => {
				if (handle) handle.unsubscribe();
			};
		}),
);

const uploadLink = createUploadLink({
	uri: posNodeUrl,
	credentials: 'include',
});

const httplink = new HttpLink({
	uri: posNodeUrl,
	credentials: 'include',
}); //new HttpLink ends here

const wsLink = new WebSocketLink({
	uri: wsNodeUrl,
	options: {
		reconnect: true,
		timeout: 20000,
		lazy: true,
	},
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	httplink,
);

export const WSclient = new ApolloClient({
	cache: cache,
	link: splitLink,
});

export const POSClient = new ApolloClient({
	link: ApolloLink.from([
		new TokenRefreshLink({
			accessTokenField: 'accessToken',
			isTokenValidOrUndefined: () => {
				const token = getAccessToken();

				if (!token) {
					return true;
				}

				try {
					const { exp } = jwtDecode(token);
					if (Date.now() >= exp * 1000) {
						return false;
					} else {
						return true;
					}
				} catch (err) {
					console.log(err);
					return false;
				}
			},
			fetchAccessToken: () => {
				return fetch(posNodeRefreshUrl, {
					method: 'POST',
					credentials: 'include',
				});
			},
			handleFetch: accessToken => {
				setAccessToken(accessToken);
			},
			handleResponse: () => {},
			handleError: err => {
				console.warn('Your refresh token is invalid. Try to relogin');
				console.error(err);
			},
		}),
		onError(({ graphQLErrors }) => {
			console.log(graphQLErrors, 'graphQLErrors');
			if (
				graphQLErrors[0]?.message
					.toLowerCase()
					.includes('Already login to another device.'.toLowerCase())
			) {
				if (browserHistory?.location?.pathname !== '/login') {
					window.location.href = '/login';
				}
			}
		}),
		requestLink,
		uploadLink as any, // property split as incompatible
		// splitLink,
	]),
	cache,
});
